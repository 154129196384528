<template>
    <div>
        <header-component v-if="width > 1050"></header-component>
        <hamburger-component v-else></hamburger-component>
        <main-component></main-component>
        <commitment-component></commitment-component>
        <human-resource-component></human-resource-component>
        <contact-component></contact-component>
        <footer-component></footer-component>
    </div>
</template>

<script>
import Vue from 'vue'
import HeaderComponent from './common/HeaderComponent'
import HamburgerComponent  from './common/HamburgerComponent'
import MainComponent from './philosophy/MainComponent'
import CommitmentComponent from './philosophy/CommitmentComponent'
import HumanResourceComponent from './philosophy/HumanResourceComponent'
import ContactComponent from './common/ContactComponent'
import FooterComponent from './common/FooterComponent'
Vue.component('header-component', HeaderComponent)
Vue.component('hamburger-component', HamburgerComponent)
Vue.component('main-component', MainComponent)
Vue.component('commitment-component', CommitmentComponent)
Vue.component('human-resource-component', HumanResourceComponent)
Vue.component('contact-component', ContactComponent)
Vue.component('footer-component', FooterComponent)

export default ({
    data() {
        return {
            width: ''
        }
    },
    mounted() {
        window.addEventListener('resize', this.resizeWindow)
        console.log(this.width)
    },
    methods: {
        resizeWindow() {
            this.width = window.outerWidth
        }
    },
    head: {
        title: {
            inner: "エミナルグループ総合サイト",
            separator: '|',
            complement: '企業理念',
        },
    },
    components: {
        HeaderComponent,
        HamburgerComponent,
        MainComponent,
        CommitmentComponent,
        HumanResourceComponent,
        ContactComponent,
        FooterComponent
    },
    created() {
        this.width = this.$store.state.width
    }
})
</script>
