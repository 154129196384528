<template>
    <div class="menu" id="menu">
        <div class="menu-box">
            <div class="menu-detail">
                <div class="removal">
                    <h5>{{ medical }}</h5>
                    <router-link to="/menu#removal-box">
                        <img class="sp-size" v-bind:src="isSp ? img.sp.removalImg : img.pc.removalImg" />
                    </router-link>
                    <p>{{ medicalDescription }}</p>
                </div>
                <div class="hifu">
                    <h5>{{ hifu }}</h5>
                    <router-link to="/menu#hifu">
                        <img class="sp-size" v-bind:src="isSp ? img.sp.hifuImg : img.pc.hifuImg" />
                    </router-link>
                    <p>{{ hifuDescription }}</p>
                </div>
                <div class="dermapen">
                    <h5>{{ dermapen }}</h5>
                    <router-link to="/menu#dermapen">
                        <img class="sp-size" v-bind:src="isSp ? img.sp.dermapenImg : img.pc.dermapenImg" />
                    </router-link>
                    <p>{{ dermapenDescription }}</p>
                </div>
                <div class="tenteki">
                    <h5>{{ tenteki }}</h5>
                    <router-link to="/menu#tenteki">
                        <img class="sp-size" v-bind:src="isSp ? img.sp.tentekiImg : img.pc.tentekiImg" />
                    </router-link>
                    <p>{{ tentekiDescription }}</p>
                </div>
                <div class="placenta">
                    <h5>{{ placenta }}</h5>
                    <router-link to="/menu#placenta">
                        <img class="sp-size" v-bind:src="isSp ? img.sp.placentaImg : img.pc.placentaImg" />
                    </router-link>
                    <p>{{ placentaDescription }}</p>
                </div>
                <div class="diet">
                    <h5>{{ diet }}</h5>
                    <router-link to="/menu#diet">
                        <img class="sp-size" v-bind:src="isSp ? img.sp.dietImg : img.pc.dietImg" />
                    </router-link>
                    <p>{{ dietDescription }}</p>
                </div>
                <div class="photoFacial">
                    <h5>{{ photoFacial }}</h5>
                    <router-link to="/menu#photofacial">
                        <img class="sp-size" v-bind:src="isSp ? img.sp.photoFacialImg : img.pc.photoFacialImg" />
                    </router-link>
                    <p>{{ photoFacialDescription }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default({
    data() {
        return {
            medical: "医療脱毛",
            medicalDescription: "最短1ヵ月に1回の施術ペースで脱毛できるエミナルの全身医療脱毛。",
            hifu: "医療ハイフ",
            hifuDescription: "ショット数無制限！切らずにリフトアップが目指せるエミナルの医療ハイフ。",
            dermapen: "ダーマペン",
            dermapenDescription: "お肌のお悩みの改善が期待できるエミナルのエイジングケア治療。",
            tenteki: "白玉点滴",
            tentekiDescription: "高い美白効果が期待できるエミナルの「高濃度グルタチオン」。体の内側から全身美白へと導きます。",
            placenta: "プラセンタ注射",
            placentaDescription: "美白効果・アンチエイジング・疲労回復などが期待できるエミナルのプラセンタ注射。",
            diet: "医療ダイエット",
            dietDescription: "内服薬・食生活改善サポート、ボディハイフ施術により体脂肪を減らすエミナルの医療ダイエット。",
            photoFacial: "フォト美肌治療",
            photoFacialDescription: "しわ・たるみの改善が期待できるエミナルのフォト美肌治療。",
            englishMenu: "LADIES’" + this.$englishMenu,
            japaneseMenu: "女性向け" + this.$japaneseMenu,
            img: {
                pc: {
                    removalImg: require("@/assets/images/pc/removal_img.png"),
                    hifuImg: require("@/assets/images/pc/hifu_img.png"),
                    dermapenImg: require("@/assets/images/pc/dermapen.png"),
                    tentekiImg: require("@/assets/images/pc/tenteki.png"),
                    placentaImg: require("@/assets/images/pc/placenta.png"),
                    dietImg: require("@/assets/images/pc/diet.png"),
                    photoFacialImg: require("@/assets/images/pc/photoFacial.png")
                },
                sp: {
                    removalImg: require("@/assets/images/sp/removal.png"),
                    hifuImg: require("@/assets/images/sp/hifu.png"),
                    dermapenImg: require("@/assets/images/sp/dermapen.png"),
                    tentekiImg: require("@/assets/images/sp/tenteki.png"),
                    placentaImg: require("@/assets/images/sp/placenta.png"),
                    dietImg: require("@/assets/images/sp/diet.png"),
                    photoFacialImg: require("@/assets/images/sp/photoFacial.png")
                }
            },
            backgroundImg: "",
            width: this.$store.state.width,
            refmedia: this.$store.state.refmedia,
            isSp: false,
        }
    },
    // created() {
    //     if (this.refmedia !== "") {
    //         this.link.home += "?refmedia=" + this.refmedia
    //         this.link.hifu += "?refmedia=" + this.refmedia
    //         this.link.dermapen += "?refmedia=" + this.refmedia
    //         this.link.tenteki += "?refmedia=" + this.refmedia
    //     }
    // },
    methods: {
        headlineResize() {
            this.width = window.outerWidth;
        }
    },
    mounted() {
        this.isSp = window.innerWidth < 767;
    }
})
</script>

<style scoped>
    .width75 {
        width: 75% !important;
    }
    .menu {
        position: relative;
    }

    .menu-box {
        width: min(75%, 900px);
        margin: 0 auto;
        background: #FAF4EC;
        border-radius: 10px;
        padding: 4% 1.9% 3%;
    }

    .menu-detail {
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        gap: 1.5%;
    }

    .menu div h5 {
        font-family: "source-han-sans-japanese", sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 16px;
        letter-spacing: 0.13em;
        text-align: center;
        color: #fff;
        z-index: 1;
    }

    .menu div p {
        width: 182px;
        font-family: "source-han-sans-japanese", sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 14px;
        letter-spacing: 0.05em;
        line-height: 21px;
        text-align: left;
        color: #707070;
        z-index: 1;
    }

    .removal,
    .hifu,
    .dermapen,
    .tenteki,
    .placenta,
    .diet,
    .photoFacial {
        position: relative;
        margin-bottom: 2%;
    }

    .removal p,
    .hifu p,
    .dermapen p,
    .tenteki p,
    .placenta p,
    .diet p,
    .photoFacial p {
        margin: 10px;
    }

    .removal h5,
    .hifu h5,
    .dermapen h5,
    .tenteki h5,
    .placenta h5,
    .diet h5,
    .photoFacial h5 {
        position: absolute;
        top: 103px;
    }

    .removal h5,
    .tenteki h5 {
        left: 33%;
    }

    .hifu h5,
    .dermapen h5 {
        left: 29%;
    }

    .placenta h5,
    .diet h5,
    .photoFacial h5 {
        left: 20%;
    }

</style>
<style scoped>
    @media screen and (max-width: 1189px) {
        .menu-detail {
            justify-content: center;
        }
    }
    @media screen and (max-width: 520px) {
        .removal,
        .hifu,
        .dermapen,
        .tenteki,
        .placenta,
        .diet,
        .photoFacial {
            width: 48%;
        }
        .spBackground {
            height: 780px;
            width: 350px;
        }

        .removal h5,
        .hifu h5,
        .dermapen h5,
        .tenteki h5,
        .placenta h5,
        .diet h5,
        .photoFacial h5 {
            position: absolute;
        }

        .menu div h5 {
            font-size: 3.4vw;
        }

        .removal h5,
        .hifu h5,
        .dermapen h5,
        .tenteki h5,
        .placenta h5,
        .diet h5,
        .photoFacial h5 {
            top: 19.5vw;
        }

        .removal h5,
        .tenteki h5 {
            left: 13vw;
        }

        .placenta h5,
        .photoFacial h5 {
            left: 7vw;
        }

        .sp-size {
            width: 34vw;
            height: 24vw;
        }

        .sp-aga-size {
            padding-top: 38%;
            width: 46%;
        }

        .menu-detail {
            justify-content: left;
        }

        .menu-box {
            width: 89.3%;
        }

        .menu div p {
            width: 34vw;
            font-size: 3.4vw;
            line-height: 5.2vw;
        }
    }

</style>
