<template>
    <tr class="position-relative" style="height: 110px;"  v-if="width > 767">
        <th>
            <div class="d-flex justify-content-between position-relative">
                <span class="mt-1">{{ columnName }}</span>
            </div>
        </th>
        <td>
            <input class="rounded border p-postal-code" size="10" v-model="postcode1" />
            -
            <input class="rounded border p-postal-code" size="10" v-model="postcode2" @input="searchAddress" />
            <button type="button" class="btn btn-link search-btn" @click="updateAddress">住所検索</button>
        </td>
    </tr>
    <div v-else>
        <div class="d-flex justify-content-between position-relative p-3 rounded" style="background: #FAF4EC; ">
            <span class="mt-1 sp-input-column">{{ columnName }}</span>
        </div>
        <input class="rounded border p-postal-code" size="10" v-model="postcode1" />
        -
        <input class="rounded border p-postal-code" size="10" v-model="postcode2" @input="searchAddress" />
        <button type="button" class="btn btn-link search-btn" @click="updateAddress">住所検索</button>
    </div>
</template>

<script>
import axios from "axios"
export default ({
    props: {
        columnName: String,
    },
    data() {
        return {
            postCodeAPIURL: this.$store.state.postcodeAPIURL,
            result: "",
            pref: "",
            municipality: "",
            city: "",
            width: this.$store.state.width,
        }
    },
    computed: {
        postcode1: {
            get() {
                return this.$store.state.postcode1
            },
            set(v) {
                this.$store.commit('updatePostcode1', v)
            }
        },
        postcode2: {
            get() {
                return this.$store.state.postcode2
            },
            set(v) {
                this.$store.commit('updatePostcode2', v)
            }
        }
    },
    mounted: function () {
        window.addEventListener('resize', this.headlineResize)
    },
    methods: {
        updateAddress() {
            const address = this.pref + this.municipality + this.city
            this.$store.commit('updateAddress', address)
        },
        async searchAddress() {
            const postcode = this.postcode1 + this.postcode2
            await axios
                .get('https://zip-cloud.appspot.com/api/search?zipcode=' + postcode)
                .then(response => {
                    const result = response.data.results
                    this.pref = result[0]['address1']
                    this.municipality = result[0]['address2']
                    this.city = result[0]['address3']
                })
                .catch(error => {
                    error
                })
        },
        headlineResize() {
            this.width = window.outerWidth;
        },
    }

})
</script>

<style scoped>
/* Bootstrapで規定されているイベントが発火されてもデザイン通りの色にする */
:not(.btn-check) + .btn:hover, .btn:first-child:hover {
    background-color: #c3a983;
    color: white;
}

.search-btn {
    font-family: "A1 Gothic R","Zen Kaku Gothic New","Yu Gothic","Hiragino Sans",Meiryo,"MS PGothic",sans-serif;
    -webkit-font-family: "A1 Gothic R","Zen Kaku Gothic New","Yu Gothic","Hiragino Sans",Meiryo,"MS PGothic",sans-serif;
    text-decoration: none;
    -webkit-text-decoration: none;
    border:1px solid #c3a983;
    background-color: #c3a983;
    color: white;
    margin-left: 2%;
}

.search-btn:hover {
    background-color: #c3a983; color: white;
}

@media screen and (max-width: 767px) {
    .search-btn {
        margin: 4% 0 5% 5%;
    }
}
</style>
