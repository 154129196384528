<template>
    <div>
        <div class="footer-menu cf">
            <dl class="cf">
                <dt class="text-start">{{ siteContents }}</dt>
                <dd class="clinic-list-area">
                    <!-- <ul class="ul-margin">
                        <li><a v-bind:href="links.recruitURL" target="_blank">{{ recruit }}</a></li>
                    </ul> -->
                    <ul class="ul-margin">
                        <li><a href="/">{{ top }}</a></li>
                        <li>
                            <router-link-component link="philosophy" v-bind:linkTxt="philosophy"></router-link-component>
                        </li>
                        <li>
                            <a v-bind:href="links.recruitURL" target="_blank">{{ recruit }}</a>
                        </li>
                    </ul>
                    <ul class="ul-margin">
                        <li>
                            <router-link-component link="info" v-bind:linkTxt="info"></router-link-component>
                        </li>
                        <li class="chubu-area">
                            <router-link-component link="faq" v-bind:linkTxt="faq"></router-link-component>
                        </li>
                    </ul>
                    <ul class="ul-margin">
                        <li>
                            <router-link-component link="clinics" v-bind:linkTxt="clinic"></router-link-component>
                        </li>
                        <li>
                            <router-link-component link="contact" v-bind:linkTxt="contact"></router-link-component>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <router-link-component link="menu" v-bind:linkTxt="menu"></router-link-component>
                        </li>
                        <li>
                            <a v-bind:href="links.eminalURL" target="_blank">
                                <span>{{ ladies }}</span>
                            </a>
                        </li>
                        <li>
                            <a v-bind:href="links.mensEminalURL" target="_blank">
                                <span>{{ mens }}</span>
                            </a>
                        </li>
                        <li>
                            <a v-bind:href="links.eminalHifuURL" target="_blank">
                                <span>{{ ladiesHaifu }}</span>
                            </a>
                        </li>
                        <li>
                            <a v-bind:href="links.mensHifuURL" target="_blank">
                                <span>{{ mensHaifu }}</span>
                            </a>
                        </li>
                        <li>
                            <a v-bind:href="links.eminalDermapenURL" target="_blank">
                                <span>{{ ladiesDermapen }}</span>
                            </a>
                        </li>
                        <li>
                            <a v-bind:href="links.mensDermapenURL" target="_blank">
                                <span>{{ mensDermapen }}</span>
                            </a>
                        </li>
                        <li>
                            <a v-bind:href="links.eminalTentekiURL" target="_blank">
                                <span>{{ ladiesTenteki }}</span>
                            </a>
                        </li>
                        <li>
                            <a v-bind:href="links.mensTentekiURL" target="_blank">
                                <span>{{ mensTenteki }}</span>
                            </a>
                        </li>
                    </ul>
                </dd>
            </dl>
            <dl class="cf">
                <dt class="text-start">{{ ladiesClinicList }}</dt>
                <dd class="clinic-list-area">
                    <ul class=" ul-margin-area">
                        <li>
                            <router-link-component v-bind:link="/clinic/ + areaURL.ladies.areaHokkaidoAndTohoku" v-bind:linkTxt="areaHokkaidoAndTohoku" v-bind:isPath=true></router-link-component>
                        </li>
                        <li>
                            <router-link-component v-bind:link="/clinic/ + areaURL.ladies.areaChugokuAndShikoku" v-bind:linkTxt="areaChugokuAndShikoku" v-bind:isPath=true></router-link-component>
                        </li>
                    </ul>
                    <ul class="ul-margin-area">
                        <li>
                            <router-link-component v-bind:link="/clinic/ + areaURL.ladies.areaTokyo" v-bind:linkTxt="areaTokyo" v-bind:isPath=true></router-link-component>
                        </li>
                        <li>
                            <router-link-component v-bind:link="/clinic/ + areaURL.ladies.areaKyushuAndOkinawa" v-bind:linkTxt="areaKyushuAndOkinawa" v-bind:isPath=true></router-link-component>
                        </li>
                    </ul>
                    <ul class="ul-margin-area">
                        <li>
                            <router-link-component v-bind:link="/clinic/ + areaURL.ladies.areaKanto" v-bind:linkTxt="areaKanto" v-bind:isPath=true></router-link-component>
                        </li>
                    </ul>
                    <ul class="ul-margin-area">
                        <li>
                            <router-link-component v-bind:link="/clinic/ + areaURL.ladies.areaKinki" v-bind:linkTxt="areaKinki" v-bind:isPath=true></router-link-component>
                        </li>
                    </ul>
                </dd>
            </dl>
            <dl class="cf">
                <dt class="text-start">{{ mensClinicList }}</dt>
                <dd class="clinic-list-area">
                    <ul class=" ul-margin-area">
                        <li>
                            <router-link-component v-bind:link="/clinic/ + areaURL.mens.areaHokkaidoAndTohoku" v-bind:linkTxt="areaHokkaidoAndTohoku" v-bind:isPath=true></router-link-component>
                        </li>
                        <li>
                            <router-link-component v-bind:link="/clinic/ + areaURL.mens.areaChugokuAndShikoku" v-bind:linkTxt="areaChugokuAndShikoku" v-bind:isPath=true></router-link-component>
                        </li>
                    </ul>
                    <ul class="ul-margin-area">
                        <li>
                            <router-link-component v-bind:link="/clinic/ + areaURL.mens.areaTokyo" v-bind:linkTxt="areaTokyo" v-bind:isPath=true></router-link-component>
                        </li>
                        <li>
                            <router-link-component v-bind:link="/clinic/ + areaURL.mens.areaKyushuAndOkinawa" v-bind:linkTxt="areaKyushuAndOkinawa" v-bind:isPath=true></router-link-component>
                        </li>
                    </ul>
                    <ul class="ul-margin-area">
                        <li>
                            <router-link-component v-bind:link="/clinic/ + areaURL.mens.areaKanto" v-bind:linkTxt="areaKanto" v-bind:isPath=true></router-link-component>
                        </li>
                    </ul>
                    <ul class="ul-margin-area">
                        <li>
                            <router-link-component v-bind:link="/clinic/ + areaURL.mens.areaKinki" v-bind:linkTxt="areaKinki" v-bind:isPath=true></router-link-component>
                        </li>
                    </ul>
                </dd>
            </dl>
        </div>

        <div class="footer-bottom cf">
            <div class="footer-bottom-content">
                <nav id="navi-footer" class="navi-footer view-pc">
                    <ul>
                        <li>
                            <router-link-component link="privacy" v-bind:linkTxt="companyAndPrivacy"></router-link-component>
                        </li>
                    </ul>
                </nav>
                <div class="source-org copyright">{{ copyRight }}</div>
            </div>
        </div>
        <div class="position-fixed" @click="returnTop()" v-show="width > 900">
            <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90">
                <g id="TOP遷移ボタン" transform="translate(-1062 -7045)">
                    <circle id="楕円形_43" data-name="楕円形 43" cx="45" cy="45" r="45" transform="translate(1062 7045)"
                        fill="#c3a983" />
                    <path id="多角形_34" data-name="多角形 34"
                        d="M15.385,4.648a3,3,0,0,1,5.229,0l12.87,22.881A3,3,0,0,1,30.87,32H5.13a3,3,0,0,1-2.615-4.471Z"
                        transform="translate(1089 7070)" fill="#fff" />
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import RouterLink from '@/components/common/RouterLink'
Vue.component('router-link-component', RouterLink)
let mens = "メンズ"
let ladies = "レディース"
let medical = "医療脱毛"
let hifu = "医療ハイフ"
let dermapen = "ダーマペン"
let tenteki = "白玉点滴"
let listHaihun = "- "
export default {
    data() {
        return {
            top: "トップ",
            siteContents: "SITE CONTENTS",
            philosophy: "企業理念",
            info: "お知らせ",
            menu: "診療メニュー",
            ladies: listHaihun + medical + ladies,
            mens: listHaihun + medical + mens,
            ladiesHaifu: listHaihun + hifu + ladies,
            mensHaifu: listHaihun + hifu + mens,
            ladiesDermapen: listHaihun + dermapen + ladies,
            mensDermapen: listHaihun + dermapen + mens,
            ladiesTenteki: listHaihun + tenteki + ladies,
            mensTenteki: listHaihun + tenteki + mens,
            clinic: "クリニック紹介",
            recruit: "採用情報",
            faq: "よくある質問",
            contact: "お問い合せ",
            ladiesClinicList: "CLINIC LIST/ レディース",
            mensClinicList: "CLINIC LIST/ メンズ",
            areaTokyo: "東京エリア",
            areaHokkaidoAndTohoku: "北海道・東北エリア",
            areaKyushuAndOkinawa: "九州・沖縄エリア",
            areaKanto: "関東エリア",
            areaChubu: "中部エリア",
            areaKinki: "近畿エリア",
            areaKoshinetsu: "甲信越エリア",
            areaChugokuAndShikoku: "中国・四国エリア",
            companyAndPrivacy: "法人概要・プライバシーポリシー",
            copyRight: "copyright © eminal clinic group all rights reserved.",
            links:{
                recruitURL : this.$store.state.recruitURL,
                eminalURL : this.$store.state.eminalURL,
                mensEminalURL : this.$store.state.mensEminalURL,
                eminalHifuURL : this.$store.state.eminalHifuURL,
                mensHifuURL : this.$store.state.mensHifuURL,
                mensEminal : this.$store.state.mensEminal,
                eminalDermapenURL : this.$store.state.eminalDermapenURL,
                mensDermapenURL : this.$store.state.mensDermapenURL,
                eminalTentekiURL : this.$store.state.eminalTentekiURL,
                mensTentekiURL : this.$store.state.mensTentekiURL,
            },
            width: this.$store.state.width,
            areaURL: {
                ladies: {
                    areaHokkaidoAndTohoku: "ladies/tohoku&hokkaido",
                    areaTokyo: 'ladies/tokyo',
                    areaKyushuAndOkinawa: 'ladies/kyusyu&okinawa',
                    areaKanto: 'ladies/kanto',
                    areaChubu: 'ladies/chubu',
                    areaKinki: 'ladies/kinki',
                    areaChugokuAndShikoku: 'ladies/chugoku&shikoku',
                },
                mens: {
                    areaHokkaidoAndTohoku: "mens/tohoku&hokkaido",
                    areaTokyo: 'mens/tokyo',
                    areaKyushuAndOkinawa: 'mens/kyusyu&okinawa',
                    areaKanto: 'mens/kanto',
                    areaChubu: 'mens/chubu',
                    areaKinki: 'mens/kinki',
                    areaChugokuAndShikoku: 'mens/chugoku&shikoku',
                }
            },
            refmedia: this.$store.state.refmedia
        }
    },
    created() {
        let refmedia = "?refmedia=" + this.refmedia
        if (this.refmedia != "") {

            for(let v of Object.keys(this.links)) {
                this.links[v] += refmedia
            }

            for(let v of Object.keys(this.areaURL)) {
                for(let url of Object.keys(this.areaURL[v])) {
                    this.areaURL[v][url] += refmedia
                }
            }
        }

    },
    mounted() {
        window.addEventListener('resize', this.resizeWindow)
    },
    methods: {
        returnTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },
    },
}
</script>

<style scoped>
    .cf::after {
        clear: both;
        content: "";
        display: block;
    }
    .footer-menu {
        max-width: 1100px;
        margin: 0 auto;
        padding: 0 50px;
    }
    .footer-menu a{
        color:#707070;
        text-decoration:none;
    }

    .footer-menu ul{
        margin-top: -0.5%;
    }

    .footer-menu dl {
        margin: 30px 0 20px 0;

    }

    .clinic-abou-list {
        display: inline;
    }

    .clinic-about-item{
        display: flex;
        gap: 5%;
        white-space: nowrap;
    }

    .footer-menu dt {
        font-family: "din-2014",sans-serif;
        font-style: normal;
        font-weight: 400;
        font-style: normal;
        font-size: 18px;
        color:#C3A983;
        float: left;
        width: 25%;
    }

    .footer-menu dd:first-of-type{
        border-left:2px solid #C3A983;
    }
    .footer-menu dd {
        display: flex;
        gap: 3%;
        width:18%;
        color:#707070;
    }

    .clinic-list-area {
        display: flex;
    }

    .clinic-list-area ul {
        width: 100%;
        white-space: nowrap;
        padding-left: 2rem;
    }

    .footer-menu li {
        list-style: none;
        display: inline;
        line-height: 2.2em;
        text-align: left;
        width: 150px;
    }

    .footer-menu ol {
        margin-left: 8%;
    }

    .footer-menu ol li {
        display: list-item;
        font-size: 12pt;
        line-height: 2.2em;
        list-style-type: '-'!important;
        margin-left: 16%;
    }

    .footer-menu ul.submenu li {
        position: relative;
        padding-left: 10px;
    }

    .footer-menu ul.submenu li:before {
        content: "";
        position: absolute;
        top: .8em;
        left: 0;
        width: 0;
        height: 0;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent transparent #4653AA;
    }

    .clinic-list-area ul li {
        display: block;
    }

    .ul-margin li:nth-child(n + 2){
        margin-top: 20%;
    }

    .ul-margin-area li:nth-child(n + 2) {
        margin-top: 40%;
    }

    @media screen and (max-width: 900px){
        .footer-menu {
            display: none;
        }
    }

    .footer-bottom  {
        background: #F0F0F0;
        text-align: center;
    }

    .navi-footer ul{
        padding-top: 50px;
        padding-bottom: 30px;
        list-style: none;
    }

    .navi-footer ul li,
    .copyright {
        font-family: "Hiragino Kaku Gothic ProN";
        font-weight: normal;
        color: #707070;
        padding-bottom: 40px;
    }

    .navi-footer ul li {
        display: inline;
        font-size: 17px;
        padding-right: 50px;
    }

    a {
        color: #707070;
    }

    a:hover {
        color:#707070;
        opacity: .5;
    }

    @media screen and (max-width: 767px){
        .view-pc {
            display: none;
        }

        .source-org {
            margin: 0 10% 0 10%;
            font-size: 11px;
        }
    }

    .position-fixed {
        top: 80%;
        right: 10%;
        cursor: pointer;
        opacity: .5;
    }

    .position-fixed:hover {
        opacity: 1;
    }

</style>
