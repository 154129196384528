<template>
    <div>
        <header-component v-if="width > 1050"></header-component>
        <hamburger-component v-else></hamburger-component>
        <div class="page-title">
            <h1>{{ japaneseInfo }}</h1>
            <img class="" v-bind:src="this.$imgTitleBar" />
            <h2>{{ englishInfo }}</h2>
        </div>
        <div class="w-75 mx-auto mt-5">
            <h3 class="py-3 text-white text-start title-text">{{ title }}</h3>
            <div class="margin-inline-3">
                <div class="mt-4">
                    <p class="date-p text-start">{{ date }}</p>
                </div>
                <div class="mt-3 content-p text-start lh-lg" v-html="content">
                </div>
            </div>

        </div>

        <div class="mt-5">
            <router-link to="/info" v-html="backToTopSvg"></router-link>
        </div>

        <contact-component></contact-component>
        <footer-component></footer-component>

    </div>

</template>

<script>
import Vue from 'vue'
import HeaderComponent from '../common/HeaderComponent'
import ContactComponent from '../common/ContactComponent'
import FooterComponent from '../common/FooterComponent'

Vue.component('header-component', HeaderComponent)
Vue.component('contact-component', ContactComponent)
Vue.component('footer-component', FooterComponent)
export default ({
    props: {
        id: Number,
        date: String,
        title: String,
        content: String,
    },
    data() {
        return {
            japaneseInfo: "お知らせ",
            englishInfo: "INFORMATION",
            backToTopSvg: '<svg xmlns="http://www.w3.org/2000/svg" width="350" height="50" viewBox="0 0 350 50"><g id="一覧へ戻る_btn" transform="translate(-425 -677)"><g id="長方形_4792" data-name="長方形 4792" transform="translate(425 677)" fill="#fff" stroke="#a27a40" stroke-width="1"><rect width="350" height="50" rx="25" stroke="none"/><rect x="0.5" y="0.5" width="349" height="49" rx="24.5" fill="none"/></g><text id="一覧へ戻る" transform="translate(600 709)" fill="#a27a40" font-size="15" font-family="SourceHanSansJP-Medium, Source Han Sans JP" text-decoration="none" font-weight="500"><tspan x="-37.5" y="0">一覧</tspan><tspan y="0">へ</tspan><tspan y="0">戻</tspan><tspan y="0">る</tspan></text><path id="パス_3377" data-name="パス 3377" d="M7.257,3.1a2,2,0,0,1,3.486,0l5.58,9.921A2,2,0,0,1,14.58,16H3.42a2,2,0,0,1-1.743-2.981Z" transform="translate(755 693) rotate(90)" fill="#a27a40"/></g></svg>',
            infoLink: "http://localhost:8080/#/info",
            width: this.$store.state.width
        }
    },
    mounted() {
        window.addEventListener('resize', this.resizeWindow)
    },
    methods: {
        resizeWindow() {
            this.width = window.outerWidth
        }
    },
    components: {
        HeaderComponent,
        ContactComponent,
        FooterComponent
    }
})
</script>

<style scoped>
    h3 {
        background-color: #C3A983;
        border-radius: 10px;
        padding-inline: 3%;
    }

    .title-text {
        font-size: 22px;
    }

    .margin-inline-3 {
        margin-inline: 3%;
    }

    .date-p {
        color: #C3A983;
        font-size: 17pt;
    }

    a {
        text-decoration: none;
        color: #C3A983;
    }

    h1 {
        margin-top: 10% !important;
    }

    @media screen and (max-width: 767px) {
        .page-title {
            margin-top: 30%;
        }

        h3,
        .date-p {
            font-size: 12pt;
        }

        .content-p {
            font-size: 10pt;
        }
    }
</style>
