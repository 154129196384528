<template>
    <tr style="height: 110px;" v-if="width > 767">
        <th>
            <div class="d-flex justify-content-between position-relative">
                <span class="mt-1">{{ columnName }}</span><span class="text-white required">必須</span>
            </div>
        </th>
        <td>
            <input class="rounded border" size="110" v-model="tel"/>
            <p class="text-start text-danger pt-1" v-if="!checkTel">{{ telError }}</p>
            <p class="text-start text-danger pt-1" v-if="!isShowTelCheck()">{{ formatError }}</p>
            <p class="text-start text-danger pt-1">{{ reccommentText }}</p>
        </td>
    </tr>
    <div v-else>
        <div class="d-flex justify-content-between position-relative p-3 rounded" style="background: #FAF4EC; ">
            <span class="mt-1 sp-input-column">{{ columnName }}</span>
        </div>
        <input class="rounded border sp-input" v-model="tel" />
        <p class="text-start text-danger pt-1" v-if="!checkTel">{{ telError }}</p>
            <p class="text-start text-danger pt-1" v-if="!isShowTelCheck()">{{ formatError }}</p>
            <p class="text-start text-danger pt-1">{{ reccommentText }}</p>
        </div>
</template>

<script>
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js'
export default ({
    props: {
        columnName: String,
    },
    data() {
        return {
            telError: '※ 電話番号は必須です',
            reccommentText: '※ 施術のご契約をされている方はご契約時の電話番号を入力いただくとスムーズです',
            formatError: '※ 電話番号の形式で入力してください',
            width: this.$store.state.width,
        }
    },
    computed: {
        tel: {
            get() {
                return this.$store.state.tel
            },
            set(v) {
                this.$store.commit('updateTel', v)
            }
        },
        checkTel() {
            let isTelPassCheck = true

            if (this.tel.length === 0 || !this.isShowTelCheck()) {
                isTelPassCheck = false
            }

            this.$emit('isTelPassCheck', isTelPassCheck)

            return isTelPassCheck
        },
    },
    mounted: function () {
        window.addEventListener('resize', this.headlineResize)
    },
    methods: {
        headlineResize() {
            this.width = window.outerWidth;
        },
        isShowTelCheck() {
            let isTelPassCheck = false
            const tellNoneProblem = function (v) {
                if (v) {
                    const parsedTelNumber = parsePhoneNumberFromString('+81' + v, 'JA')
                    return parsedTelNumber ? parsedTelNumber.isValid() : false;
                } else {
                    return isTelPassCheck
                }
            }

            const formatTel = function (v) {
                return new AsYouType('JP').input(v)
            }

            const isHaihun = RegExp('-')

            if (!tellNoneProblem(this.tel)) {
                return isTelPassCheck;
            }

            const formatedTel = formatTel(this.tel)

            if (!isHaihun.test(formatedTel)) {
                return isTelPassCheck;
            }

            this.tel = formatedTel
            isTelPassCheck = true

            return isTelPassCheck
        }
    }
})
</script>
