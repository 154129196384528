<template>
    <div class="content-top">
        <div class="page-title">
            <h1>{{ japaneseFaq }}</h1>
            <img class="" v-bind:src="this.$imgTitleBar" />
            <h2>{{ englishFaq }}</h2>
        </div>
        <div id="imgs" class="mx-auto">
            <img
                class="mx-md-5"
                @click="
                    changeImg('ladies', isLadiesOnClick),
                        moveClinicList(isLadiesOnClick)
                "
                v-bind:class="{
                    marginBottom: isMensOnClick === true,
                    spImgSize: width < 767,
                    spClickedImgSize: width < 767 && isLadiesOnClick,
                }"
                v-bind:src="img.ladies"
                alt=""
            />
            <img
                id="mens-img"
                @click="
                    changeImg('mens', isMensOnClick),
                        moveClinicList(isLadiesOnClick)
                "
                v-bind:class="{
                    marginBottom: isLadiesOnClick === true,
                    spImgSize: width < 767,
                    spClickedImgSize: width < 767 && isMensOnClick,
                }"
                v-bind:src="img.mens"
                alt=""
            />
        </div>
        <article
            id="faq"
            class="accordion-faq"
            v-show="isLadiesOnClick || isMensOnClick"
        >
            <div
                v-for="(type, index) in faqTypes"
                :key="type"
                class="accordion-header accordion-area w-100"
                v-bind:class="{ open: show[index] }"
            >
                <div class="state-name w-100" @click="openArea(index)">
                    <span>{{ type }}</span>
                    <div class="i-box">
                        <span
                            v-html="svg.pcTitleClose"
                            class="one-i"
                            v-if="!show[index] && width > 768"
                        ></span>
                        <span
                            v-html="svg.spTitleClose"
                            class="one-i"
                            v-else-if="!show[index] && width < 768"
                        ></span>
                        <span
                            v-html="svg.pcTitleOpen"
                            class="one-i2"
                            v-if="show[index] && width > 768"
                        ></span>
                        <span
                            v-html="svg.spTitleOpen"
                            class="one-i2"
                            v-else-if="show[index] && width < 768"
                        ></span>
                    </div>
                </div>
                {{ setfaqList(isLadiesOnClick, isMensOnClick) }}
                <div
                    v-for="(faq, faqIndex) in faqData"
                    :key="`id_${faq.id}_${faqIndex}`"
                >
                    <div v-if="type === faq.type">
                        <div @click="openfaq(faqIndex)">
                            <slide-up-down
                                :class="
                                    changeBackGroundColor(
                                        isLadiesOnClick,
                                        isMensOnClick
                                    )
                                "
                                :active="show[index]"
                                :duration="100"
                                class="faq-name accordion-header"
                            >
                                <div class="d-block pt-2">
                                    <span
                                        :class="
                                            changeColor(
                                                isLadiesOnClick,
                                                isMensOnClick
                                            )
                                        "
                                        class="position-absolute start-0 px-4 question"
                                        >Q</span
                                    >
                                    <span
                                        :class="
                                            changeColor(
                                                isLadiesOnClick,
                                                isMensOnClick
                                            )
                                        "
                                        class="position-absolute start-0 px-5 mx-4 question-content"
                                        >{{ faq.question }}</span
                                    >
                                </div>
                                <div class="i-box">
                                    <span
                                        v-html="svg.pcClose"
                                        class="one-i"
                                        v-if="!faqShow[faqIndex] && width > 768"
                                    ></span>
                                    <span
                                        v-html="svg.spClose"
                                        class="one-i"
                                        v-else-if="
                                            !faqShow[faqIndex] && width < 768
                                        "
                                    ></span>
                                    <span
                                        v-html="svg.pcOpen"
                                        class="one-i2"
                                        v-if="faqShow[faqIndex] && width > 768"
                                    ></span>
                                    <span
                                        v-html="svg.spOpen"
                                        class="one-i2"
                                        v-else-if="
                                            faqShow[faqIndex] && width < 768
                                        "
                                    ></span>
                                </div>
                            </slide-up-down>
                        </div>
                        <div
                            v-if="show[index] && faqShow[faqIndex]"
                            class="faq-name accordion-header"
                            style="padding: 0"
                        >
                            <transition name="open">
                                <div
                                    class="accordion-content"
                                    style="padding: 0"
                                >
                                    <div class="answer">A</div>
                                    <p
                                        class="mt-1 answer-content"
                                        style="
                                            font-family: 'Noto Sans JP',
                                                sans-serif;
                                        "
                                        v-html="faq.answer"
                                    ></p>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </div>
</template>

<script type="text/javascript">
export default {
    data() {
        return {
            japaneseFaq: "よくある質問",
            englishFaq: "FAQ",
            isMensOnClick: false,
            isLadiesOnClick: false,
            show: {},
            faqShow: {},
            faqDetailShow: {},
            img: {
                ladies: require("@/assets/images/pc/faq_ladies.jpg"),
                spLadies: require("@/assets/images/sp/faq_ladies.jpg"),
                mens: require("@/assets/images/pc/faq_mens.jpg"),
                map: require("@/assets/images/pc/map_img.png"),
                detailLink: require("@/assets/images/pc/detail_link_img.png"),
            },
            svg: {
                pcTitleOpen:
                    '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 18 16"><path id="多角形_95" data-name="多角形 95" d = "M7.257,3.1a2,2,0,0,1,3.486,0l5.58,9.921A2,2,0,0,1,14.58,16H3.42a2,2,0,0,1-1.743-2.981Z" transform="translate(18 16) rotate(180)" fill="#fff" /></svg>',
                pcTitleClose:
                    '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18"><path id="多角形_96" data-name="多角形 96" d = "M7.257,3.1a2,2,0,0,1,3.486,0l5.58,9.921A2,2,0,0,1,14.58,16H3.42a2,2,0,0,1-1.743-2.981Z" transform="translate(16) rotate(90)" fill="#fff" /></svg>',
                spTitleOpen:
                    '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 36 32"><path id="多角形_291" data-name="多角形 291" d = "M14.514,6.2a4,4,0,0,1,6.973,0L32.647,26.039A4,4,0,0,1,29.161,32H6.839a4,4,0,0,1-3.486-5.961Z" fill="#fff" /></svg>',
                spTitleClose:
                    '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 36 32"><path id="多角形_292" data-name="多角形 292" d = "M14.514,6.2a4,4,0,0,1,6.973,0L32.647,26.039A4,4,0,0,1,29.161,32H6.839a4,4,0,0,1-3.486-5.961Z" transform="translate(36 32) rotate(180)" fill="#fff" /></svg>',
                pcOpen: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16"><path id="多角形_85" data-name="多角形 85" d="M7.257,3.1a2,2,0,0,1,3.486,0l5.58,9.921A2,2,0,0,1,14.58,16H3.42a2,2,0,0,1-1.743-2.981Z" transform="translate(18 16) rotate(180)" fill="#e95a8e" /></svg>',
                pcClose:
                    '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18"><path id="多角形_84" data-name="多角形 84" d = "M7.257,3.1a2,2,0,0,1,3.486,0l5.58,9.921A2,2,0,0,1,14.58,16H3.42a2,2,0,0,1-1.743-2.981Z" transform="translate(16) rotate(90)" fill="#e95a8e" /></svg>',
                spOpen: '<svg xmlns="http://www.w3.org/2000/svg" width="31" height="20" viewBox="0 0 31 27"><path id="多角形_1" data-name="多角形 1" d = "M12.9,4.532a3,3,0,0,1,5.2,0L28.42,22.506A3,3,0,0,1,25.819,27H5.181a3,3,0,0,1-2.6-4.494Z" fill="#ea6394" /></svg>',
                spClose:
                    '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 31 27"><path id="多角形_252" data-name="多角形 252" d="M12.9,4.532a3,3,0,0,1,5.2,0L28.42,22.506A3,3,0,0,1,25.819,27H5.181a3,3,0,0,1-2.6-4.494Z" transform="translate(31 27) rotate(-180)" fill="#ea6394" /></svg>',
            },
            faqTypes: ["医療脱毛", "医療ハイフ", "ダーマペン", "美容点滴"],
            ladiesFaqData: this.$store.state.ladiesFaqData,
            mensFaqData: this.$store.state.mensFaqData,
            faqData: "",
            width: this.$store.state.width,
        };
    },
    mounted: function () {
        window.addEventListener("resize", this.headlineResize);
    },
    head: {
        title: {
            inner: "エミナルグループ総合サイト",
            separator: "|",
            complement: "FAQ",
        },
    },
    methods: {
        changeImg(gender, isClicked) {
            if (gender === "ladies") {
                this.img.ladies = require("@/assets/images/pc/faq_ladies_on_click.jpg");
                this.svg.pcOpen =
                    '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16"><path id="多角形_85" data-name="多角形 85" d="M7.257,3.1a2,2,0,0,1,3.486,0l5.58,9.921A2,2,0,0,1,14.58,16H3.42a2,2,0,0,1-1.743-2.981Z" transform="translate(18 16) rotate(180)" fill="#e95a8e" /></svg>';
                this.svg.spOpen =
                    '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 31 27"><path id="多角形_1" data-name="多角形 1" d = "M12.9,4.532a3,3,0,0,1,5.2,0L28.42,22.506A3,3,0,0,1,25.819,27H5.181a3,3,0,0,1-2.6-4.494Z" fill="#ea6394" /></svg>';
                this.svg.pcClose =
                    '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18"><path id="多角形_84" data-name="多角形 84" d = "M7.257,3.1a2,2,0,0,1,3.486,0l5.58,9.921A2,2,0,0,1,14.58,16H3.42a2,2,0,0,1-1.743-2.981Z" transform="translate(16) rotate(90)" fill="#e95a8e" /></svg>';
                this.svg.spClose =
                    '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 31 27"><path id="多角形_252" data-name="多角形 252" d="M12.9,4.532a3,3,0,0,1,5.2,0L28.42,22.506A3,3,0,0,1,25.819,27H5.181a3,3,0,0,1-2.6-4.494Z" transform="translate(31 27) rotate(-180)" fill="#ea6394" /></svg>';

                if (this.isMensOnClick === true) {
                    this.img.mens = require("@/assets/images/pc/faq_mens.jpg");
                    this.isMensOnClick = false;
                }

                if (isClicked === true) {
                    this.img.ladies = require("@/assets/images/pc/faq_ladies.jpg");
                    this.isLadiesOnClick = !isClicked;
                } else {
                    this.isLadiesOnClick = !isClicked;
                }
            } else {
                this.img.mens = require("@/assets/images/pc/faq_mens_on_click.jpg");
                this.svg.pcOpen =
                    '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16"><path id = "多角形_85" data-name="多角形 85" d = "M7.257,3.1a2,2,0,0,1,3.486,0l5.58,9.921A2,2,0,0,1,14.58,16H3.42a2,2,0,0,1-1.743-2.981Z" transform = "translate(18 16) rotate(180)" fill = "#333" /></svg>';
                (this.svg.spOpen =
                    '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 31 27"><path id="多角形_260" data-name="多角形 260" d="M12.9,4.532a3,3,0,0,1,5.2,0L28.42,22.506A3,3,0,0,1,25.819,27H5.181a3,3,0,0,1-2.6-4.494Z" fill="#333"/></svg>'),
                    (this.svg.pcClose =
                        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18"><path id="多角形_84" data-name="多角形 84" d="M7.257,3.1a2,2,0,0,1,3.486,0l5.58,9.921A2,2,0,0,1,14.58,16H3.42a2,2,0,0,1-1.743-2.981Z" transform="translate(16) rotate(90)" fill="#333"/></svg>'),
                    (this.svg.spClose =
                        '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 31 27"><path id="多角形_256" data-name="多角形 256" d="M12.9,4.532a3,3,0,0,1,5.2,0L28.42,22.506A3,3,0,0,1,25.819,27H5.181a3,3,0,0,1-2.6-4.494Z" transform="translate(31 27) rotate(-180)" fill="#333"/></svg>');

                if (this.isLadiesOnClick === true) {
                    this.img.ladies = require("@/assets/images/pc/faq_ladies.jpg");
                    this.isLadiesOnClick = false;
                }

                if (isClicked === true) {
                    this.img.mens = require("@/assets/images/pc/faq_mens.jpg");
                    this.isMensOnClick = !isClicked;
                } else {
                    this.isMensOnClick = !isClicked;
                }
            }
        },
        openArea(index) {
            this.$set(this.show, index, !this.show[index]);
        },
        openfaq(index) {
            this.$set(this.faqShow, index, !this.faqShow[index]);
        },
        openfaqDetail(index) {
            this.$set(this.faqDetailShow, index, !this.faqDetailShow[index]);
        },
        setfaqList(isLadies, isMens) {
            if (isLadies === true) {
                this.faqData = this.ladiesFaqData;
            } else if (isMens === true) {
                this.faqData = this.mensFaqData;
            }
        },
        changeBackGroundColor(isLadies, isMens) {
            if (isLadies === true) {
                return "ladies-question-background";
            } else if (isMens === true) {
                return "mens-question-background";
            }
        },
        changeColor(isLadies, isMens) {
            if (isLadies === true) {
                return "ladies-question-text";
            } else if (isMens === true) {
                return "mens-question-text";
            }
        },
        moveClinicList(isLadiesOnClick) {
            if (window.outerWidth > 900) {
                return;
            }

            const mensImg = document.getElementById("mens-img");
            const faqList = document.getElementById("faq");
            if (isLadiesOnClick) {
                mensImg.style.display = "none";
                faqList.appendChild(mensImg);
                mensImg.style.display = "block";
            } else {
                document.getElementById("imgs").appendChild(mensImg);
                mensImg.style.display = "block";
            }
        },
        headlineResize() {
            this.width = window.outerWidth;
        },
    },
    filters: {
        omittedText(text) {
            return text.length > 30 ? text.slice(0, 30) + "..." : text;
        },
    },
};
</script>

<style scoped>
/* 例外で10%指定 */
.page-title {
    margin-top: 11% !important;
}
.content-top {
    overflow-x: hidden !important;
}
.ladies-question-text {
    color: #e95a8e;
}

.ladies-question-background {
    background-color: #faefef;
}

.mens-question-text + span {
    color: #333333;
}

.mens-question-text + span {
    top: 30%;
}

.mens-question-background {
    background-color: #efefef;
}

.marginBottom {
    margin-bottom: 6%;
}
div.state-name {
    color: #ffffff;
    font-weight: 600;
    font-size: 2rem;
    padding: 1rem 1.5rem;
    display: block;
    margin: 2px 0;
    position: relative;
    font-family: "Source Han Sans JP";
    font-weight: bold;
    font-size: 20px;
    text-align: left;
    color: #fff;
    height: 70px;
    border-radius: 10px;
    background: #c3a983;
}

h4.faq-name {
    display: block;
    padding: 1rem 1.5rem;
    font-size: 2rem;
    background-color: #d7f6ff;
    color: #17c0e5;
    margin: 0 0 2px;
}

div.faq-name {
    display: block;
    font-family: "Noto Sans Japanese", sans-serif;
    /* font-weight: bold; */
    font-size: 17px;
    top: 25%;
    text-align: left;
    padding: 1.5rem 1.5rem;
    margin: 2px 0;
    position: relative;
    border-radius: 10px;
    cursor: pointer;
}

.answer-content {
    font-size: 17px;
}

div.faq-name .sub {
    font-size: 0.5rem;
}

.question {
    top: 5%;
    font-size: 40px;
    font-family: "din-2014, sans-serif";
    font-style: normal;
    font-weight: 200;
}

.accordion-faq th,
.accordion-faq td {
    border: 1px solid #cdcdcd;
    padding: 0.5rem;
    color: #666666;
}

.accordion-faq th {
    background: #ededed;
}

.accordion-faq {
    margin: 0 auto;
    clear: both;
    display: block;
    padding: 0;
    width: 1030px;
}

.accordion-area {
    cursor: pointer;
}

.accordion-faq .accordion-header {
    position: relative;
    z-index: +1;
    transition-duration: 0.2s;
    margin: 1% 0 1%;
}

.i-box {
    position: relative;
    border-top-left-radius: 30%;
}

.accordion-faq .accordion-header .i-box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 50%;
    left: 930px;
    width: 7%;
    height: 40px;
    margin-top: -20px;
}

.accordion-faq .accordion-header .i-box .one-i {
    display: block;
    width: 18px;
    height: 18px;
    transform-origin: center center;
    transition-duration: 0.2s;
    position: relative;
}
.accordion-faq .accordion-header.open .i-box {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
}

.accordion-faq .accordion-header .i-box {
    position: relative;
}

.accordion-faq .accordion-header .i-box .one-i2 {
    content: "";
    position: absolute;
    bottom: 0;
    left: 25px;
    cursor: pointer;
}

.one-i2.display {
    display: block !important;
}

.accordion-faq .accordion-header .i-box .one-i {
    display: block;
    width: 18px;
    height: 18px;
    transform-origin: center center;
    transition-duration: 0.2s;
    position: relative;
}

input:checked + .one-i {
    transform: "rotate( 180deg )";
}

.accordion-faq .accordion-header.open .i-box .one-i:before {
    content: none;
}

.one-i-faq-faq,
.one-i2-faq-faq {
    bottom: 80%;
}

.accordion-faq .accordion-inner {
    padding: 0;
    box-sizing: border-box;
}

.question-content {
    top: 30%;
}

@media screen and (max-width: 767px) {
    .spImgSize {
        width: 350px;
        height: 250px;
        margin: 0 auto;
    }

    .spClickedImgSize {
        height: 300px !important;
    }

    .content-top {
        width: 100%;
    }
    .content-top > div {
        margin-left: 7%;
    }
    .accordion-faq {
        width: 100%;
    }

    .question {
        top: 20%;
        font-size: 30px;
        font-weight: 200;
    }

    .answer-content {
        margin-right: 50px;
    }

    .question-content {
        top: 13%;
    }

    .accordion-faq .accordion-header .i-box {
        bottom: 45%;
        left: 90%;
    }

    .accordion-faq .accordion-header .i-box .one-i {
        /* transform: rotate(270deg); */
    }

    .accordion-faq .accordion-header .i-box .one-i2 {
        width: auto;
        left: 0;
        top: 10%;
    }

    div.state-name {
        font-family: "Noto Sans Japanese, sans-serif";
    }
}

/* アコーディオン */

.accordion-container .accordion-title {
    position: relative;
    margin: 0.25rem 0;
    padding: 0.6rem 1.3rem;
    background-color: #4a4d4f;
    font-size: 1.2em;
    font-weight: normal;
    color: white;
    cursor: pointer;
    border-radius: 1vw;
}

.accordion-container .accordion-title::before {
    content: "";
    position: absolute;
    top: 20%;
    right: 1rem;
    width: 30px;
    height: 30px;
    /*縦線に*/
    /*transform: rotate(90deg);
    background: #ccc;*/
    /* background-image: url('../img02/sec12/arrow_open.png'); */
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.3s ease-in-out;
}

.accordion-container .accordion-title::after {
    content: "";
    position: absolute;
    top: 20%;
    right: 1rem;
    /*横線*/
    width: 30px;
    height: 30px;
    background: #4a4d4f;
    /* background-image: url('../img02/sec12/arrow_close.png'); */
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.2s ease-in-out;
}

.accordion-container .accordion-title.open::before {
    transform: rotate(0deg);
}

.accordion-container .accordion-title.open::after {
    opacity: 0;
}

.accordion-content {
    margin-bottom: 1rem;
    background: white;
    padding: 1rem;
}

/* CSS for CodePen */
.accordion-container {
    width: 100%;
    margin: 0 auto;
}

.accordion-content {
    display: flex;
    align-items: center;
}

.answer {
    font-size: 2rem;
    margin: 18px 29px 29px;
    font-family: din-2014-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.open-enter-active {
    animation: open 1s;
}
.open-leave-active {
    animation: open 0.5s linear reverse;
}
</style>
