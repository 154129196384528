<template>
    <tr v-if="width > 767">
        <th>
            <div class="d-flex justify-content-between">
                <span class="mt-1">お問合せ詳細</span><span class="text-white required">必須</span>
            </div>
        </th>
        <td>
            <textarea class="rounded border" cols="100" rows="5" v-model="contactDetail"
                v-bind:placeholder="contactDetailPlace"></textarea>
            <p class="text-start text-danger" v-if="!checkDetail">{{ contactDetailError }}</p>
        </td>
    </tr>
    <div v-else>
        <div class="d-flex justify-content-between position-relative p-3 rounded" style="background: #FAF4EC; ">
            <span class="mt-1 sp-input-column">お問い合わせ詳細</span><span class="text-white required required-column">必須</span>
        </div>
        <textarea class="rounded border sp-input" cols="10" rows="2" v-model="contactDetail"
            v-bind:placeholder="contactDetailPlace"></textarea>
        <p class="text-start text-danger" v-if="!checkDetail">{{ contactDetailError }}</p>
    </div>
</template>

<script>
export default ({
    data() {
        return {
            contactDetailPlace: "",
            contactDetailError: '※ お問合せ詳細は必須です',
            width: this.$store.state.width,
        }
    },
    computed: {
        contactDetail: {
            get() {
                return this.$store.state.contactDetail
            },
            set(v) {
                this.$store.commit('updateDetailContent', v)
            }
        },
        checkDetail() {
            let isContactDetailPassCheck = true

            if (this.contactDetail.length === 0) {
                isContactDetailPassCheck = false
            }

            this.$emit('isContactDetailPassCheck', isContactDetailPassCheck)

            return isContactDetailPassCheck
        },
    },
    mounted: function () {
        window.addEventListener('resize', this.headlineResize)
    },
    methods: {
        headlineResize() {
            this.width = window.outerWidth;
        },
    }
})
</script>
