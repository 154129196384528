var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info mt-5"},[_c('div',{staticClass:"info",attrs:{"id":"menu"}},[_c('h1',[_vm._v(_vm._s(_vm.info))]),_c('img',{attrs:{"src":this.$imgTitleBar}}),_c('h2',[_vm._v(_vm._s(_vm.englishInfo))])]),_vm._l((_vm.infoData),function(info,index){return _c('div',{key:info.id,staticClass:"w-50 mx-auto info-block"},[(index < 5)?_c('router-link',{staticClass:"d-block ul-underline mt-5 info-detail",attrs:{"to":{
                name: 'detailInfo',
                params: {
                    id: info.id,
                    date: info.date,
                    title: info.title,
                    content: info.content,
                },
                query: { refmedia: _vm.query },
            }}},[_c('div',{staticClass:"info-list d-flex justify-content-between"},[_c('span',[_vm._v(_vm._s(info.created_at.split("T")[0].replace(/-/g, ".")))]),_c('div',{staticClass:"w-75"},[_c('p',{staticClass:"pb-3 ml-md-5 text-decoration-none info-text"},[_vm._v(" "+_vm._s(_vm._f("omittedText")(info.title))+" ")])]),(_vm.width < 900)?_c('div',{staticClass:"info-arrow"}):_vm._e()])]):_vm._e()],1)}),_c('div',{staticClass:"mt-5 svg"},[_c('router-link-component',{attrs:{"link":"info","linkTxt":_vm.detailSvg}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }