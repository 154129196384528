var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-top"},[_c('div',{staticClass:"page-title"},[_c('h1',[_vm._v(_vm._s(_vm.japaneseInfo))]),_c('img',{attrs:{"src":this.$imgTitleBar}}),_c('h2',[_vm._v(_vm._s(_vm.englishInfo))])]),_c('div',{staticClass:"w-75 mx-auto mt-5 info"},[_vm._l((_vm.getItems),function(info){return _c('router-link',{key:info.id,staticClass:"d-block ul-underline mt-4",attrs:{"to":{
                name: 'detailInfo',
                params: {
                    id: info.id,
                    date: info.date,
                    title: info.title,
                    content: info.content,
                },
            }}},[_c('div',{staticClass:"info-list d-flex justify-content-between"},[_c('span',{staticStyle:{"margin-left":"5%"}},[_vm._v(_vm._s(info.created_at.split("T")[0].replace(/-/g, ".")))]),_c('div',{staticClass:"w-75 title"},[_c('p',{staticClass:"pb-3 ml-md-5 text-decoration-none"},[_vm._v(" "+_vm._s(info.title)+" ")])])])])}),_c('paginate',{staticClass:"mt-3",attrs:{"page-count":_vm.getPageCount,"click-handler":_vm.clickCallback,"prev-text":'＜',"next-text":'＞',"next-link-class":'page-link',"prev-link-class":'page-link',"margin-pages":1,"page-range":3,"container-class":'pagination',"page-class":'page-item',"page-link-class":'page-link',"prev-class":'prev-item'}})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }