<template>
    <div>
        <nav class="gnavi my-5">
        <div class="gnavi-inner">
            <ul>
                <li>
                    <router-link-component link="philosophy" v-bind:linkTxt="japanesePhilosophy"></router-link-component>
                </li>
                <li>
                    <router-link-component link="menu" v-bind:linkTxt="japaneseMenu"></router-link-component>
                </li>
                <li>
                    <router-link-component link="clinics" v-bind:linkTxt="japanesehClinic"></router-link-component>
                </li>
                <li>
                    <router-link-component link="faq" v-bind:linkTxt="japanesehFaq"></router-link-component>
                </li>
                <li><a v-bind:href="recruitURL"><span>{{ japaneseRecruit }}</span></a></li>
            </ul>
        </div>
    </nav>
    </div>
</template>

<script>
import Vue from 'vue'
import RouterLink from '@/components/common/RouterLink'
Vue.component('router-link-component', RouterLink)
    export default({
        data() {
            return {
                japanesePhilosophy: "企業理念",
                japaneseMenu: "診療メニュー",
                japanesehClinic: "クリニック一覧",
                japanesehFaq: "よくある質問",
                japaneseRecruit: "採用情報",
                urlMenuId: '/menu',
                recruitURL: this.$store.state.recruitURL,
            }
        },
        created() {
            if (this.$route.query.refmedia != null) {
                this.recruitURL += "?refmedia=" + this.$route.query.refmedia
            }
        },

    })
</script>

<style scoped>
    .gnavi {
        margin: 0 0 0 auto;
        margin-right: 0;
        width: 50%;
    }
    .gnavi ul {
        display: flex;
    }

    .gnavi li {
        flex: 1 1;
        position: relative;
        display: flex;
    }

    .gnavi li:after {
        content: "";
        display: block;
        width: 2px;
        height: 20px;
        background-color: #C3A983;
        position: absolute;
        right: -1px;
        top: calc((100% - 20px) / 2);
    }

    .gnavi li:last-child::after {
        content: none;
    }

    .gnavi a {
        display: flex;
        flex: 1 1;
        justify-content: center;
        padding: 0 20px;
    }

    .gnavi span {
        display: flex;
        align-items: center;
        font-size: 13pt;
        white-space: nowrap;
    }


    .gnavi-inner a {
        text-decoration: none;
        font-family: 'Noto Sans Japanese', sans-serif;
        font-weight: 500;
        color: #707070 !important;
        font-size: 1rem;
    }

    .gnavi-inner a span:hover {
        color: #333333;
    }

    .gnavi-inner a::after {
        position: absolute;
        top: 32px;
        left: 35%;
        content: '';
        width: 30%;
        height: 2px;
        background: #C3A983;
        transform: scale(0, 1);
        transform-origin: left top;
        transition: transform .3s;
    }

    a:hover::after {
        transform: scale(1, 1);
    }
</style>
