<template>
    <div>
        <div class="info" id="inquiry">
            <h1>{{ inquiryJapanese }}</h1>
            <img class="" v-bind:src="this.$imgTitleBar" />
            <h2>{{ inquiryEnglish }}</h2>
        </div>

        <div class="menus">
            <div class="ladies-menu">
                <a v-bind:href="link.ladiesContact">
                    <img v-bind:src="img.pcLadiesInquiry" v-bind:class="{ spInquiryImg: width < 768}"/>
                </a>
            </div>
            <div class="mens-menu">
                <a v-bind:href="link.mensContact">
                    <img v-bind:src="img.pcMensInquiry" v-bind:class="{ spInquiryImg: width < 768}"/>
                </a>
            </div>
        </div>

        <p class="text-center mt-5">その他取材や広告、当医療法人へのお問い合わせは下記フォームよりお問い合せください。</p>
        <div class="mt-5 svg">
            <router-link :to="{path: 'contact',  query: { refmedia: refmedia }}" v-html="detailSvg"  v-if="refmedia != ''"></router-link>
            <router-link to="/contact" v-html="detailSvg"  v-else></router-link>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            inquiryJapanese: "お問い合わせ",
            inquiryEnglish: "INQUIRY",
            detailSvg: '<svg xmlns="http://www.w3.org/2000/svg" width="350" height="50" viewBox="0 0 350 50"><g id="お問い合わせフォームはこちら_btn" transform="translate(-425 -5671)"><g id="長方形_2992" data-name="長方形 2992" transform="translate(425 5671)" fill="#c3a983" stroke="#c3a983" stroke-width="1"><rect width="350" height="50" rx="25" stroke="none"/><rect x="0.5" y="0.5" width="349" height="49" rx="24.5" fill="none"/></g><text id="お問い合わせフォームはこちら" transform="translate(600 5703)" fill="#fff" font-size="15" font-family="Noto Sans Japanese, sans-serif" font-weight="500"><tspan x="-104.775" y="0">お</tspan><tspan y="0">問</tspan><tspan y="0">い</tspan><tspan y="0">合</tspan><tspan y="0">わせフォームはこちら</tspan></text><path id="多角形_67" data-name="多角形 67" d="M7.257,3.1a2,2,0,0,1,3.486,0l5.58,9.921A2,2,0,0,1,14.58,16H3.42a2,2,0,0,1-1.743-2.981Z" transform="translate(757 5688) rotate(90)" fill="#fff"/></g></svg>',
            img: {
                pcLadiesInquiry: require("@/assets/images/pc/ladies_inquiry.png"),
                spLadiesInquiry: require("@/assets/images/sp/ladies_inquiry.png"),
                pcMensInquiry: require("@/assets/images/pc/mens_inquiry.png"),
            },
            link: {
                ladiesContact: "https://eminal-clinic.jp/contact/",
                mensContact: "https://epi-reserve.com/yoyaku_menseminal/otoiawase.php?_gl=1*1jplku0*_ga*MTg0ODU1NjczNS4xNjQwNTc0MzUx*_ga_N7X82G7WWF*MTY0MDY4NDk3OS4zLjEuMTY0MDY4NTAzNi4w*_ga_7RHNWJNM2Q*MTY0MDY4NDk3OS4zLjEuMTY0MDY4NTAzNi4z&_ga=2.206734939.916931259.1640684980-1848556735.1640574351&_stp=a.2266814395+b.2305274374&_ebx=ac1c115ln8.1640574351.7jc7qs5"
            },
            width: this.$store.state.width,
            refmedia: this.$store.state.refmedia
        }
    },
    created() {
    if (this.refmedia !== "") {
        this.link.ladiesContact += "?refmedia=" + this.refmedia
        this.link.mensContact += "&refmedia=" + this.refmedia
    }
    }
}
</script>

<style scoped>
    .inquiry {
        margin-top: 100px;
        text-align: center;
    }

    .inquiry h1 {
        font-family: "source-han-sans-japanese", sans-serif;
        font-weight: 500;
        font-style: normal;

        font-size: 36px;
        color: #2c2c2c;
        margin-bottom: 20px;
    }

    /* .inquiry h2 {
        font-family: "din-2014",sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 32px;
        color: #2c2c2c;
        margin-top: -1%;
        margin-bottom: 50px;
    } */

    .ladies-menu:hover,
    .mens-menu:hover {
        opacity: .5;
    }

@media screen and (max-width: 767px) {

    .spInquiryImg {
        width: 350px;
        height: 350px;
    }

    .mens-menu {
        margin-top: 5%;
    }

    .inquiry h1 {
        font-size: 28px;
    }

    .inquiry h2 {
        font-size: 24px;
        margin-bottom: 50px;
    }

}
</style>
