<template>
    <div>
        <header-component></header-component>
        <header-nav-component v-if="width > 767"></header-nav-component>
        <hamburger-component v-else></hamburger-component>
        <div class="content-top">
            <div class="page-title">
                <h1>{{ japaneseContactConfirm }}</h1>
                <img class="" v-bind:src="this.$imgTitleBar" />
                <h2>{{ englishContactConfirm }}</h2>
            </div>
            <div class="mt-5 pt-5">
                <h3 class="mb-5">{{ formComplete }}</h3>
            </div>
            <div class="position-relative">
                <div class="position-relative" v-html="backgroundSVG" v-if="width > 767"></div>
                <div class="position-relative sp-margin" v-html="spBackgroundSVG" v-else></div>

                <p class="position-absolute fs-md-5 sp-font-size sp-font-margin" style="top: 25%; right: 0; left: 0;">{{ thanksMessage
                    }}</p>
                <p class="position-absolute fs-md-5 sp-font-size" style="top: 50%; right: 0; left: 0;"
                    v-html="contactMessage"></p>
            </div>

            <div class="mt-md-5 pt-5 sp-margin">
                <router-link-component link="/" :linkTxt="topSVG"></router-link-component>
            </div>


        </div>
        <contact-component></contact-component>
        <footer-component></footer-component>
    </div>
</template>

<script>
import Vue from "vue";
import RouterLink from '@/components/common/RouterLink'
Vue.component('router-link-component', RouterLink)
export default ({
    data() {
        return {
            japaneseContactConfirm: "お問い合せ",
            englishContactConfirm: "CONTACT",
            formComplete: "お問い合わせ完了",
            backgroundSVG: '<svg xmlns="http://www.w3.org/2000/svg" width="600" height="240" viewBox="0 0 600 240"><rect id="長方形_3272" data-name="長方形 3272" width="600" height="240" fill="#faf4ec"/></svg>',
            spBackgroundSVG: '<svg xmlns="http://www.w3.org/2000/svg" width="350" height="240" viewBox="0 0 600 240"><rect id="長方形_3272" data-name="長方形 3272" width="600" height="240" fill="#faf4ec"/></svg>',
            thanksMessage: "お問い合わせありがとうございました。",
            contactMessage: "送信が完了いたしました。<br>7営業日以内に担当者よりご連絡させていただきます。",
            topSVG: '<svg xmlns="http://www.w3.org/2000/svg" width="260" height="50" viewBox="0 0 260 50"><g id="TOPに戻る_btn" transform="translate(-470 -803)" ><g id="長方形_3218" data-name="長方形 3218" transform="translate(470 803)" fill="#fff" stroke="#a27a40" stroke-width="1"><rect width="260" height="50" rx="25" stroke="none"/><rect x="0.5" y="0.5" width="259" height="49" rx="24.5" fill="none"/></g><text id="TOPに戻る" transform="translate(600 835)" fill="#a27a40" font-size="15"  font-family="Noto Sans Japanese, sans-serif" font-weight="500"><tspan x="-37.372" y="0">TOP</tspan><tspan y="0">に</tspan><tspan y="0">戻</tspan><tspan y="0">る</tspan></text><path id="多角形_131" data-name="多角形 131" d="M7.257,3.1a2,2,0,0,1,3.486,0l5.58,9.921A2,2,0,0,1,14.58,16H3.42a2,2,0,0,1-1.743-2.981Z" transform="translate(714 819) rotate(90)" fill="#a27a40"/></g></svg >',
            width: this.$store.state.width,
        }
    },
    mounted: function () {
        this.$store.state.memberNumber = ''
        this.$store.state.name = ''
        this.$store.state.nameFurigana = ''
        this.$store.state.postcode1 = ''
        this.$store.state.postcode2 = ''
        this.$store.state.address = ''
        this.$store.state.mail = ''
        this.$store.state.tel = ''
        this.$store.state.contactContent = ''
        this.$store.state.contactGenderClinic = ''
        this.$store.state.contactPref = ''
        this.$store.state.contactClinic = ''
        this.$store.state.contactDetail = ''
        window.addEventListener('resize', this.headlineResize)
    },
    methods: {
        headlineResize() {
            this.width = window.outerWidth;
        },
    },
    head: {
        title: {
            inner: 'エミナルグループ総合サイト',
            separator: '|',
            complement: 'お問い合わせ完了'
        },
    },
})
</script>

<style scoped>
@media screen and (max-width: 767px) {

    .sp-margin {
        margin-top: -15%;
    }
    .sp-font-size {
        font-size: 13.5px;
    }
    .sp-font-margin {
        margin-top: 5%;
    }
}
</style>
