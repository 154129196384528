<template>
    <div>
        <div class="diagnosis-menu" id="menu">
            <h1>{{ this.$japaneseMenu }}</h1>
            <img class="" v-bind:src="this.$imgTitleBar"/>
            <h2>{{ menu }}</h2>
        </div>
        <div class="menus">
            <Menu></Menu>
        </div>
        <!-- <div class="mt-5 svg">
            <router-link :to="{path: 'menu'}" v-html="detailSvg"  v-if="refmedia != ''"></router-link>
            <router-link to="/menu" v-html="detailSvg"  v-else></router-link>
        </div> -->
    </div>
</template>

<script>
import Menu from './menu/MenuComponent.vue'

const menu = "MENU"
    export default ({
        data() {
            return {
                menu: menu,
                menuLink: "#",
                detailSvg: '<svg id="診療メニューを詳しく見る_btn" xmlns="http://www.w3.org/2000/svg" width="350" height="50" viewBox="0 0 350 50"><g id="長方形_3381" data-name="長方形 3381" fill="#fff" stroke="#a27a40" stroke-width="1"><rect width="350" height="50" rx="25" stroke="none"/><rect x="0.5" y="0.5" width="349" height="49" rx="24.5" fill="none"/></g><text id="診療メニューを詳しく見る" transform="translate(175 32)" fill="#a27a40" font-size="15" font-family="Noto Sans Japanese, sans-serif" font-weight="500"><tspan x="-90" y="0">診療</tspan><tspan y="0">メニューを</tspan><tspan y="0">詳</tspan><tspan y="0">しく</tspan><tspan y="0">見</tspan><tspan y="0">る</tspan></text><path id="多角形_150" data-name="多角形 150" d="M7.257,3.1a2,2,0,0,1,3.486,0l5.58,9.921A2,2,0,0,1,14.58,16H3.42a2,2,0,0,1-1.743-2.981Z" transform="translate(330 16) rotate(90)" fill="#a27a40"/></svg>',
                refmedia: this.$store.state.refmedia
            };
        },
        components: {
            Menu
        },
    })
</script>

<style>

    .diagnosis-menu {
        margin-top: 100px;
        text-align: center;
    }

    .diagnosis-menu h1 {
        font-family: "source-han-sans-japanese", sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 36px;
        color: #2c2c2c;
    }

    .menus {
        display: flex;
        justify-content: center;
        gap: 0 5%;
    }
    @media screen and (max-width: 1024px) {
        .menus {
            flex-direction: column;
            align-items: center;
        }

    }

    .detail-img-padding,
    .detail-aga-padding {
        padding-right: 7px;
    }

    .detail-aga-padding {
        padding-top: 30%;
    }

    @media screen and (max-width: 767px) {

        .diagnosis-menu {
            margin: 110px 0 10%;
        }

        .diagnosis-menu h1 {
            font-size: 28px;
            margin-bottom: 20px;
        }

        .diagnosis-menu h2 {
            font-size: 24px;
        }
    }
</style>
