<template>
    <div class="content-top">
        <div class="page-title">
            <h1>{{ japaneseContact }}</h1>
            <img class="" v-bind:src="this.$imgTitleBar" />
            <h2>{{ englishContact }}</h2>
        </div>
        <div class="mt-5 pt-5">
            <h3 class="mb-5">{{ formTitle }}</h3>
            <p class="text-start required-txt">※&nbsp;<span class="text-white fs-6 required">必須</span>&nbsp;がついた項目は必須です。
            </p>
            <!-- PC版 -->
            <table class="tbl01 gold" v-if="width > 767">
                <tbody v-for="columnName in contactColumnList" :key="columnName">
                    <member-number-component v-bind:columnName="columnName" v-if="columnName === '会員番号'"></member-number-component>
                    <name-component v-bind:columnName="columnName" v-if="columnName === 'お名前'" @isNamePassCheck='nameErrorDecision'>
                    </name-component>
                    <name-furigana-component v-bind:columnName="columnName" v-if="columnName === 'フリガナ'"
                        @isNameFuriganaPassCheck='nameFuriganaErrorDecision'></name-furigana-component>
                    <postcode-component v-bind:columnName="columnName" v-if="columnName === '郵便番号'"></postcode-component>
                    <address-component v-bind:columnName="columnName" v-if="columnName === 'ご住所'"></address-component>
                    <tel-component v-bind:columnName="columnName" v-if="columnName === '電話番号'" @isTelPassCheck="telErrorDecision">
                    </tel-component>
                    <email-component v-bind:columnName="columnName" v-if="columnName === 'Eメールアドレス'"
                        @isMailPassCheck="mailErrorDecision"></email-component>
                    <contact-content-component v-bind:columnName="columnName" v-if="columnName === 'お問い合わせ内容'"
                        @isContactContentPassCheck="contactContentErrorDecision"></contact-content-component>
                    <content-detail-component v-bind:columnName="columnName" v-if="columnName === 'お問い合わせ詳細'"
                        @isContactDetailPassCheck="contactDetailErrorDecision"></content-detail-component>
                </tbody>
            </table>
            <!-- SP版 -->
            <div class="mx-2" v-else>
                <div v-for="columnName in contactColumnList" :key="columnName">
                    <member-number-component v-bind:columnName="columnName" v-if="columnName === '会員番号'"></member-number-component>
                    <name-component v-bind:columnName="columnName" v-if="columnName === 'お名前'" @isNamePassCheck='nameErrorDecision'>
                    </name-component>
                    <name-furigana-component v-bind:columnName="columnName" v-if="columnName === 'フリガナ'"
                        @isNameFuriganaPassCheck='nameFuriganaErrorDecision'></name-furigana-component>
                    <postcode-component v-bind:columnName="columnName" v-if="columnName === '郵便番号'"></postcode-component>
                    <address-component v-bind:columnName="columnName" v-if="columnName === 'ご住所'"></address-component>
                    <tel-component v-bind:columnName="columnName" v-if="columnName === '電話番号'" @isTelPassCheck="telErrorDecision">
                    </tel-component>
                    <email-component v-bind:columnName="columnName" v-if="columnName === 'Eメールアドレス'"
                        @isMailPassCheck="mailErrorDecision">
                    </email-component>
                    <contact-content-component v-bind:columnName="columnName" v-if="columnName === 'お問い合わせ内容'"
                        @isContactContentPassCheck="contactContentErrorDecision"></contact-content-component>
                    <content-detail-component v-bind:columnName="columnName" v-if="columnName === 'お問い合わせ詳細'"
                        @isContactDetailPassCheck="contactDetailErrorDecision"></content-detail-component>
                </div>
            </div>
        </div>
        <div class="mt-5 pt-5" v-bind:class="{ opacity: isExistError() }">
                <router-link-component link="confirm" :linkTxt="confirmButtonSvg"></router-link-component>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import RouterLink from '@/components/common/RouterLink'
import MemberNumber from './parts/memberNumber'
import Name from './parts/name'
import NameFurigana from './parts/nameFurigana'
import Postcode from './parts/postcode'
import Address from './parts/address'
import Tel from './parts/tel'
import Email from './parts/email'
import ContactContent from './parts/contactContent'
import ContactDetail from './parts/contactDetail'

Vue.component('router-link-component', RouterLink)
Vue.component('member-number-component', MemberNumber)
Vue.component('name-component', Name)
Vue.component('name-furigana-component', NameFurigana)
Vue.component('postcode-component', Postcode)
Vue.component('address-component', Address)
Vue.component('tel-component', Tel)
Vue.component('email-component', Email)
Vue.component('contact-content-component', ContactContent)
Vue.component('content-detail-component', ContactDetail)

export default ({
    data() {
        return {
            japaneseContact: "お問い合せ",
            englishContact: "CONTACT",
            formTitle: "お問合せフォーム",
            name: "",
            confirmButtonSvg: '<svg xmlns="http://www.w3.org/2000/svg" width="350" height="50" viewBox="0 0 350 50"><g id="確認画面へ_btn" transform="translate(-425 -1405)"><g id="長方形_2991" data-name="長方形 2991" transform="translate(425 1405)" fill="#c3a983" stroke="#c3a983" stroke-width="1"><rect width="350" height="50" rx="25" stroke="none"/><rect x="0.5" y="0.5" width="349" height="49" rx="24.5" fill="none"/></g><text id="確認画面へ" transform="translate(600 1437)" fill="#fff" font-size="15"  font-family="Noto Sans Japanese, sans-serif" font-weight="500"><tspan x="-37.5" y="0">確認画面</tspan><tspan y="0">へ</tspan></text><path id="多角形_66" data-name="多角形 66" d="M7.257,3.1a2,2,0,0,1,3.486,0l5.58,9.921A2,2,0,0,1,14.58,16H3.42a2,2,0,0,1-1.743-2.981Z" transform="translate(755 1421) rotate(90)" fill="#fff"/></g></svg>',
            width: this.$store.state.width,
            contactColumnList: this.$store.state.contactColumnList,
            isNamePassCheck: false,
            isNameFuriganaPassCheck: false,
            isTelPassCheck: false,
            isMailPassCheck: false,
            isContactContentPassCheck: false,
            isContactDetailPassCheck: false,
        }
    },
    mounted: function () {
        window.addEventListener('resize', this.headlineResize)
    },
    methods: {
        headlineResize() {
            this.width = window.outerWidth;
        },
        nameErrorDecision(isNamePassCheck) {
            this.isNamePassCheck = isNamePassCheck

        },
        nameFuriganaErrorDecision(isNameFuriganaPassCheck) {
            this.isNameFuriganaPassCheck = isNameFuriganaPassCheck
        },
        telErrorDecision(isTelPassCheck) {
            this.isTelPassCheck = isTelPassCheck
        },
        mailErrorDecision(isMailPassCheck) {
            this.isMailPassCheck = isMailPassCheck
        },
        contactContentErrorDecision(isContactContentPassCheck) {
            this.isContactContentPassCheck = isContactContentPassCheck
        },
        contactDetailErrorDecision(isContactDetailPassCheck) {
            this.isContactDetailPassCheck = isContactDetailPassCheck
        },
        isExistError() {
            return !this.isNamePassCheck || !this.isNameFuriganaPassCheck || !this.isTelPassCheck || !this.isMailPassCheck || !this.isContactContentPassCheck || !this.isContactDetailPassCheck
        }
    },
    head: {
        title: {
            inner: 'エミナルグループ総合サイト',
            separator: '|',
            complement: 'お問い合わせ'
        },
    },
})
</script>

<!-- テーブルスタイル -->
<style>
tr {
    height: 110px;
}
.opacity {
    opacity: .5;
    pointer-events: none;
}
.required {
    font-size: 11px;
    font-weight: normal;
    background-color: #C3A983;
    padding: 6px 5px;
}
/* ▼TABLE▼ */
.tbl01 {
    width: 1200px;
}

.tbl01 th, .tbl01 td {
	padding: 20px;
	vertical-align: middle;
	line-height: 1.65;
}

.tbl01 th {
	font-weight: bold;
}

.tbl01.gold th, .tbl01.gold td {
    text-align: start;
	border: solid 1px #CCCCCC;
    font-size: 16px;
}

.tbl01.gold th {
	color: #A27A40;
	background: #FAF4EC;
	width: 17%;
    text-align: start;
}

ul,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}

.accodion-enter-active,
.accodion-leave-active {
    transition: .5s;
    overflow: hidden;
}

.accodion-enter,
.accodion-leave-to {
    transform: scaleY(0);
}

.accodion-leave,
.accodion-enter-to {
    transform: scaleY(1);
}
@media screen and (max-width: 767px) {

    .required-column {
        font-size: 15px;
        margin-right: 5%;
        padding: 5px 8px 0px 8px;
    }

    .required-txt {
        margin: 15% 0 5% 5%;
    }

    .sp-input-column {
        background: #FAF4EC;
        color: #A27A40;
        font-weight: 600;
    }

    .sp-input {
        line-height: 3;
        width: 100%;
        margin: 5% 0;
    }

    .selectBox,
    .selectBox__output {
        margin-top: 5%;
        width: 100%;
    }

    p.text-danger {
        margin-left: 2%;
    }
}
</style>
