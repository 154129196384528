import Vue from "vue";
import Router from "vue-router";
import Home from "./components/HomeComponent.vue"
import Menu from "./components/MenuComponent.vue"
import Philosophy from "./components/PhilosophyComponent.vue"
import Info from "./components/Info.vue"
import DetailInfo from "./components/info/DetailInfo.vue"
import Clinics from "./components/ClinicsComponent.vue"
import Faq from "./components/FaqComponent.vue"
import Privacy from "./components/PrivacyPolicyComponent.vue"
import Contact from "./components/ContactComponent.vue"
import Confirm from "./components/contact/ConfirmComponent.vue"
import Complete from "./components/contact/CompleteComponent.vue"
Vue.use(Router);

export default new Router({
    mode: 'hash',
    routes: [
        {
            path: '/',
            name: '/',
            component: Home,
        },
        {
            path: '/menu',
            name: 'menu',
            component: Menu,
        },
        {
            path: '/philosophy',
            name: 'philosophy',
            component: Philosophy,
        },
        {
            path: '/info',
            name: 'info',
            component: Info,
        },
        {
            path: '/info/:title',
            component: DetailInfo,
            name: 'detailInfo',
            props: routes => ({
                id: Number(routes.params.id),
                date: routes.params.date,
                title: routes.params.title,
                content: routes.params.content,
            })
        },
        {
            path: '/clinics',
            component: Clinics,
            name: 'clinics'
        },
        {
            path: '/clinic/:gender/:areaId',
            component: Clinics,
            name: 'clinic',
            props: routes => ({
                gender: routes.params.gender,
                areaId: routes.params.areaId
            })
        }
        ,
        {
            path: '/faq',
            name: 'faq',
            component: Faq,
        },
        {
            path: '/privacy',
            name: 'privacy',
            component: Privacy,
        },
        {
            path: '/contact',
            component: Contact,
            name: 'contact'
        },
        {
            path: '/confirm',
            name: 'confirm',
            component: Confirm,
        },
        {
            path: '/contactComplete',
            name: 'complete',
            component: Complete,
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth'
            };
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0, behavior: 'smooth' };
        }
    }
})
