<template>
    <div class="content-top">
        <div class="page-title">
            <h1>{{ japanesePhilosophy }}</h1>
            <img class="" v-bind:src="this.$imgTitleBar"/>
            <h2>{{ englishPhilosophy }}</h2>
        </div>
            <div class="mission-paragraph" id="philosophy">
            <p class="mission-txt">{{ headLine }}<br>{{ headLine2 }}</p>
            <p class="mission-txt">{{ paragraph }}<br>{{ paragraph2 }}<br>{{ paragraph3 }}</p>
            <p class="mission-txt">{{ paragraph4 }}<br>{{ paragraph5 }}<br>{{ paragraph6 }}</p>
        </div>
    </div>
</template>

<script>
import updateRefmedia from '../../util/updateRefmedia';
export default ({
    data() {
        return {
            japanesePhilosophy: "理念",
            englishPhilosophy: "PHILOSOPHY",
            headLine: "私たちエミナルクリニックグループのミッションは",
            headLine2: "『美容医療で患者様を笑顔に』する事です。",
            paragraph: "エミナルクリニックグループだからこそ提供できる美容医療を、",
            paragraph2: "より多くの患者様にお届けし、美容医療を通して",
            paragraph3: "すべての患者様を笑顔にするために私たちは努めます。",
            paragraph4: "正しい知識と技術で、患者様の抱える悩みに真摯に向き合い、",
            paragraph5: "常に患者様の気持ちに寄り添い、私たちは美容医療施術を提供いたします。",
            paragraph6: "私たちは美容医療施術を提供いたします。",
        }
    },
    beforeCreate() {
        updateRefmedia(this.$route.query.refmedia)
    }
});
</script>

<style scoped>

    /* .page-title {
        margin-top: 100px;
        text-align: center;
    } */

    .page-title h1 {
        font-family: "source-han-sans-japanese", sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 36px;
        color: #2c2c2c;
    }

    .mission {
        background: url("../../assets/images/pc/philosophy_back.png") no-repeat center center;
        background-size: 100% auto;
    }
    .mission-paragraph p,
    .mission-paragraph h2 {
        font-family: "source-han-sans-japanese", "sans-serif";
    }

    @media screen and (min-width: 320px){
        .mission {
            max-width: 1920px;
            width: 100%;
            height: 150vh;
            margin: 0 auto;
        }
        .mission-txt {
            font-family: 'Noto Sans Japanese', 'sans-serif';
            font-weight: 500;
            font-style: normal;
            letter-spacing: 50;
            color: #333333;
            padding: 75px 50px 0;
            line-height: 2;
        }
    }
    @media screen and (min-width: 600px){
        .mission-txt {
            font-size: 20px;
            padding: 75px 70px 0;

        }
    }

    @media screen and (min-width: 1025px){
        .mission-txt {
            max-width: 1200px;
            width: 100%;
            text-align: center;
            margin: 0 auto;
            /* padding-top: 90px; */
        }
        .mission-txt-header {
            font-size: 45px;
        }
    }

    .menus {
        display: flex;
        justify-content: center;
        gap: 0 5%;
    }
    @media screen and (max-width: 1024px) {
        .menus {
            flex-direction: column;
            align-items: center;
        }

    }

    .detail-img-padding,
    .detail-aga-padding {
        padding-right: 7px;
    }

    .detail-aga-padding {
        padding-top: 30%;
    }

    @media screen and (max-width: 767px) {

        .mission-txt {
            padding: 10px 20px 0;
            text-align: left;
        }

        .mission-paragraph p{
            font-family: 'Noto Sans Japanese', sans-serif;
        }

    }

    @media screen and (max-width: 599px) {
        .ladies-menu div h5  {
            line-height: 15px;
        }
        .mens-menu div h5 {
            line-height: 15px;
        }
        .ladies-removal h5,
        .ladies-hifu h5 {
            top: 51%;
        }
    }


</style>
