<template>
    <div>
        <header-component v-if="width > 1050"></header-component>
        <hamburger-component v-else></hamburger-component>
        <main-component></main-component>
        <contact-component></contact-component>
        <footer-component></footer-component>
    </div>
</template>

<script>
import Vue from 'vue'
import HeaderComponent from './common/HeaderComponent'
import HamburgerComponent from './common/HamburgerComponent'
import MainComponent from "./contact/MainComponent.vue"
import ContactComponent from './common/ContactComponent'
import FooterComponent from './common/FooterComponent'

Vue.component('header-component', HeaderComponent)
Vue.component('hamburger-component', HamburgerComponent)
Vue.component('main-component', MainComponent)
Vue.component('contact-component', ContactComponent)
Vue.component('footer-component', FooterComponent)
export default ({
    data() {
        return {
            width: this.$store.state.width
        }
    },
    mounted: function () {
        window.addEventListener('resize', this.headlineResize)
    },
    methods: {
        headlineResize() {
            this.width = window.outerWidth;
        }
    },
    components: {
        HeaderComponent,
        HamburgerComponent,
        MainComponent,
        ContactComponent,
        FooterComponent,
    }
})
</script>