<template>
    <tr v-if="width > 767">
        <th>
            <div class="d-flex justify-content-between">
                <span class="mt-1">{{ columnName }}</span><span class="text-white required">必須</span>
            </div>
        </th>
        <td>
            <input class="rounded border" size="110" v-model="mail"/>
            <p class="text-start text-danger pt-3" v-if="!checkMail">{{ mailError }}</p>
            <p class="text-start text-danger pt-3" v-if="!isInvalidEmail">{{ mailInvalidError }}</p>
        </td>
    </tr>
    <div v-else>
        <div class="d-flex justify-content-between position-relative p-3 rounded" style="background: #FAF4EC; ">
            <span class="mt-1 sp-input-column">{{ columnName }}</span><span class="text-white required required-column">必須</span>
        </div>
        <input class="rounded border sp-input" size="110" v-model="mail" />
        <p class="text-start text-danger pt-3" v-if="!checkMail">{{ mailError }}</p>
        <p class="text-start text-danger pt-3" v-if="!isInvalidEmail">{{ mailInvalidError }}</p>
    </div>
</template>

<script>
export default ({
    props: {
        columnName: String,
    },
    data() {
        return {
            mailError: '※ メールは必須です',
            mailInvalidError: '※ メールアドレスの形式で入力してください',
            width: this.$store.state.width,
        }
    },
    computed: {
        mail: {
            get() {
                return this.$store.state.mail
            },
            set(v) {
                this.$store.commit('updateMail', v)
            }
        },
        checkMail() {
            let isMailPassCheck = true

            if (this.mail.length === 0) {
                isMailPassCheck = false
            }

            return isMailPassCheck
        },
        isInvalidEmail() {

            if (!this.checkMail) {
                return
            }

            const reg = new RegExp(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/);
            let isMailPassCheck = true

            if (!reg.test(this.mail)) {
                isMailPassCheck = false
            }

            this.$emit('isMailPassCheck', isMailPassCheck)

            return isMailPassCheck
        },
    },
    mounted: function () {
        window.addEventListener('resize', this.headlineResize)
    },
    methods: {
        headlineResize() {
            this.width = window.outerWidth;
        },
    }
});
</script>
