<template>
    <div class="mission mt-5">
        <div class="mission-paragraph mx-auto" id="philosophy" v-if="width > 768">
            <p class="mission-txt">{{ headLine }}<br>{{ headLine2 }}</p>
            <p class="mission-txt">{{ paragraph }}<br>{{ paragraph2 }}<br></p>
            <p class="mission-txt">{{ paragraph3 }}<br>{{ paragraph4 }}<br>{{ paragraph5 }}</p>
        </div>
        <div class="mission-paragraph mx-auto" id="philosophy" v-else>
            <p class="mission-txt">{{ headLine }}{{ headLine2 }}</p>
            <p class="mission-txt">{{ paragraph }}<br>{{ paragraph2 }}<br></p>
            <p class="mission-txt">{{ paragraph3 }}<br>{{ paragraph4 }}<br>{{ paragraph5 }}</p>
        </div>
        <div class="mt-5 svg">
            <router-link-component link="philosophy" v-bind:linkTxt="detailSvg"></router-link-component>
        </div>
    </div>

</template>

<script>
    import Vue from 'vue'
    import RouterLink from '@/components/common/RouterLink'
    Vue.component('router-link-component', RouterLink)
    export default {
        data() {
            return {
                headLine: "私たちエミナルクリニックグループのミッションは",
                headLine2: "『美容医療で患者様を笑顔に』する事です。",
                paragraph: "エミナルクリニックグループだからこそ提供できる美容医療を、より多くの、患者様にお届けし、",
                paragraph2: "美容医療を通してすべての患者様を笑顔にするために私たちは努めます。",
                paragraph3: "",
                paragraph4: "正しい知識と技術で、患者様の抱える悩みに真摯に向き合い、 常に患者様の気持ちに寄り添い、",
                paragraph5: "私たちは美容医療施術を提供いたします。",
                detailSvg: '<svg xmlns="http://www.w3.org/2000/svg" width="350" height="50" viewBox="0 0 350 50"><g id="詳しくはこちら_btn" transform="translate(-425 -1203)"><g id="長方形_2969" data-name="長方形 2969" transform="translate(425 1203)" fill="#fff" stroke="#a27a40" stroke-width="1"><rect width="350" height="50" rx="25" stroke="none"/><rect x="0.5" y="0.5" width="349" height="49" rx="24.5" fill="none"/></g><text id="詳しくはこちら" transform="translate(600 1235)" fill="#a27a40" font-size="15" font-family="Noto Sans Japanese, sans-serif" font-weight="500"><tspan x="-52.5" y="0">詳</tspan><tspan y="0">しくはこちら</tspan></text><path id="多角形_56" data-name="多角形 56" d="M7.257,3.1a2,2,0,0,1,3.486,0l5.58,9.921A2,2,0,0,1,14.58,16H3.42a2,2,0,0,1-1.743-2.981Z" transform="translate(755 1219) rotate(90)" fill="#a27a40"/></g></svg>',
                sp: {
                    headLine: "私たちエミナルクリニックグループの",
                    headLine2: "ミッションは『美容医療で患者様を笑顔に』する事です。",
                    paragraph: "エミナルクリニックグループだからこそ提供できる",
                    paragraph2: "美容医療を、より多くの、患者様にお届けし",
                    paragraph3: "美容医療を通してすべての患者様を笑顔にするために私たちは努めます。",
                    paragraph4: "正しい知識と技術で、患者様の抱える悩みに真摯に向き",
                    paragraph5: "合い、 常に患者様の気持ちに寄り添い、",
                    paragraph6: "私たちは美容医療施術を提供いたします。"
                },
                width: window.outerWidth,
                refmedia: this.$store.state.refmedia
            }
        },
        mounted() {
            window.addEventListener('resize', this.resizeWindow)
        },
        methods: {
            resizeWindow() {
                this.width = window.outerWidth
            }
        },
    }
</script>

<style>
    .svg > a > svg{
        margin: 0 auto;
    }
</style>
<style scoped>
    .mission {
        background: url("../../assets/images/pc/philosophy_back.png") no-repeat center/cover;
        background-size: 100% auto;
        height: 1300px;
    }

    .mission-paragraph p,
    .mission-paragraph h2 {
        font-family: 'Noto Sans Japanese', sans-serif;
    }
    @media screen and (min-width: 320px){
        .mission {
            margin: 0 auto;;
            width: min(100%, 1920px);
        }

        .mission-txt {
            margin-bottom: 10%;
            font-family: 'source-han-sans-japanese', 'sans-serif';
            font-weight: 500;
            font-style: normal;
            color: #333333;
            /* padding: 75px 50px 0; */
            line-height: 2;
        }
    }
    @media screen and (min-width: 768px){
        .mission-paragraph {
            width: 75%;
        }
        .mission-txt {
            font-size: 20px;
            padding: 35px 70px 0;
        }
    }


    @media screen and (min-width: 1025px){
        .mission-txt {
            max-width: 1200px;
            width: 100%;
            text-align: center;
            margin: 0 auto;
            /* padding-top: 90px; */
        }
        .mission-txt-header {
            font-size: 45px;
        }
    }

        @media screen and (max-width: 520px) {
            .mission-paragraph p,
            .mission-paragraph h2 {
                font-family: source-han-sans-japanese,sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 15px;
            }
            .disp-sp-none {
                display: none;
            }
            .mission {
                /* bootstrapと競合してしまうため */
                margin-top: 10% !important;
                height: 135vh;
            }

            .mission-paragraph {
                width: 86%;
                text-align: left;
                margin: -75% 10px 0 10px;
                padding: 0 3%;
            }

            .mission-txt {
                padding: 0;
            }
        }

        @media screen and (max-width: 399px) {
                .mission {
                    margin-top: 15% !important;
                    height: 170vh;
                }
            }
</style>
