<template>
    <div class="container">
        <h3 class="text-center margin-top-10rem">{{ titleResource }}</h3>
        <img class="" v-bind:src="img.subtitle" />
        <div class="d-flex flex-md-wrap w-100 mx-auto">
            <div class="wrap" v-for="data in contentData" :key="data.headLineResource">
                <img class="img-fluid pt-md-5" v-bind:src="data.img" />
                <h4 class="mt-5 text-start fs-5 headline">{{
                    data.headLineResource }}</h4>
                <div class="mx-auto pr-5 image">
                    <img class="line-img" v-bind:src="img.line" alt="">
                    <p class="mt-2 fs-6 text-start paragraph">{{ data.paragraphResource }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default({
    data() {
        return {
            titleResource: "充実した人材育成制度",
            contentData: {
                data: {
                    headLineResource:  "接客業の基本的なマナー",
                    paragraphResource: "オリジナル脱毛器の特徴や効果的な施術方法、安全性を細かく丁寧に確認していき、より理解を深めていきます。現場で役立つノウハウも教えてもらえます。" ,
                    img:  require("@/assets/images/pc/philosophy_manner.jpg")
                },
                data2: {
                    headLineResource:  "皮膚・脱毛・美容に対する基礎知識",
                    paragraphResource: "患者様が安心して施術や治療を受けられるように、美容医療についての正しい知識、情報を学んでいただきます。未経験の方でも安心のカリキュラムです。" ,
                    img:  require("@/assets/images/pc/philosophy_knowledge.jpg")
                },
                data3: {
                    headLineResource:  "施術の仕方",
                    paragraphResource: "高い技術を安全にご提供できるように、経験豊富な先輩の指導のもと繰り返し技術を身につけていきます。周りの手厚いサポートを受けながらスキルアップできます。" ,
                    img:  require("@/assets/images/pc/philosophy_treatment.jpg")
                },
                data4: {
                    headLineResource:  "安心安全を提供するためのメーカー研修",
                    paragraphResource: "オリジナル脱毛器の特徴や効果的な施術方法、安全性を細かく丁寧に確認していき、より理解を深めていきます。現場で役立つノウハウも教えてもらえます。" ,
                    img:  require("@/assets/images/pc/philosophy_training.jpg")
                }
            },
            img: {
                line: require("@/assets/images/pc/line.jpg"),
                subtitle: require("@/assets/images/pc/philosophy_line.png")
            }
        }
    },
})
</script>


<style scoped>
    .wrap {
        width: 50%;
    }

    .headline {
        margin-left: 22%;
        margin-bottom: 0%;
        width: 90%;
    }

    .paragraph {
        margin-left: 8%;
    }

    .image {
        width: 70%
    }

    .margin-top-10rem {
        margin-top: 10rem;
    }

    .margin-top-5rem {
        margin-top: 5rem;
    }

    @media screen and (max-width: 767px) {

        .wrap {
            width: 100%;
        }

        .headline {
            margin-left: 7%;
        }

        .image {
            width: 90%;
        }

        .paragraph {
            margin-left: 3%;
        }

        .d-flex {
            flex-direction: column;
            align-items: center;
        }

        .line-img {
            width: 100%;
            height: 1px;
        }
    }
</style>
