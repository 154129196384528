<template>
    <div>
        <div class="info" id="menu">
            <h1>よくある質問</h1>
            <img class="" v-bind:src="this.$imgTitleBar" />
            <h2>FAQ</h2>
        </div>

        <div class="accordion w-50" v-for="(faq, index) in faqData" :key="faq.id">
            <div class="title position-relative container" @click="openFaq(index)" v-if="index < 3 ">
                <span class="position-absolute start-0 px-4 question">Q</span>
                <p>{{ faq.question }}</p>
                <i class="fas fa-chevron-up" v-bind:class="{rotate: show[index], rotateClose: !show[index]}"
                    v-html="svg">
                </i>
            </div>
            <transition name="open">
                <div class="accordion-content" v-if="show[index]">
                    <div class="answer">A</div>
                    <p v-html="faq.answer"></p>
                </div>
            </transition>
        </div>
        <div class="mt-5 svg">
            <router-link v-html="detailSvg" :to="{path: 'faq', query: {refmedia: refmedia}}"></router-link>
        </div>
    </div>
</template>

<script>
let count = 0;
export default {
    data() {
        return {
            show: {},
            dataLists: "",
            headingNum: [
                'One',
                'Two',
                'Three',
                'Four',
                'Five'
            ],
            arrowSvg: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18"><path id="多角形_59" data- name="多角形 59" d = "M7.257,3.1a2,2,0,0,1,3.486,0l5.58,9.921A2,2,0,0,1,14.58,16H3.42a2,2,0,0,1-1.743-2.981Z" transform = "translate(16) rotate(90)" fill = "#a27a40" /> ',
            arrowSvgSp: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="27" viewBox="0 0 31 27"><path id="多角形_1" data-name="多角形 1" d="M12.9,4.532a3,3,0,0,1,5.2,0L28.42,22.506A3,3,0,0,1,25.819,27H5.181a3,3,0,0,1-2.6-4.494Z" fill="#a27a40" /></svg>',
            svg: '',
            detailSvg: '<svg xmlns="http://www.w3.org/2000/svg" width="350" height="50" viewBox="0 0 350 50"><g id="その他のFAQはこちら_btn" transform="translate(-425 -4754)"><g id="長方形_2991" data-name="長方形 2991" transform="translate(425 4754)" fill="#fff" stroke="#af8b56" stroke-width="1"><rect width="350" height="50" rx="25" stroke="none"/><rect x="0.5" y="0.5" width="349" height="49" rx="24.5" fill="none"/></g><text id="その他のFAQはこちら" transform="translate(600 4786)" fill="#af8b56" font-size="15" font-family="SourceHanSansJP-Medium, Source Han Sans JP" font-weight="500"><tspan x="-74.16" y="0">その</tspan><tspan y="0">他</tspan><tspan y="0">の</tspan><tspan y="0">FAQ</tspan><tspan y="0">はこちら</tspan></text><path id="多角形_66" data-name="多角形 66" d="M7.257,3.1a2,2,0,0,1,3.486,0l5.58,9.921A2,2,0,0,1,14.58,16H3.42a2,2,0,0,1-1.743-2.981Z" transform="translate(755 4770) rotate(90)" fill="#af8b56"/></g></svg>',
            faqLink: "./faq",
            faqData: this.$store.state.ladiesFaqData,
            width: window.innerWidth,
            refmedia: this.$store.state.refmedia
        }
    },
    created() {
        if (this.width > 900) {
            this.svg = this.arrowSvg
        } else {
            this.svg = this.arrowSvgSp
        }
    },
    mounted() {
        this.dataLists = {
            id: [
                'Obne'
            ],

        }
    },
    computed: {
        increment() {
            return count += 1;
        },
        accordionHeading(index) {
            return "flush-heading" + index;
        }
    },
    methods: {
        openFaq(index) {
            this.$set(this.show, index, !this.show[index] )
        }
    }
}
</script>

<style scoped>
.rotate {
    transition-duration: .3s;
    transform: rotate(90deg);
}

.rotateClose {
    transition-duration: .3s;
    transform: rotate(0deg);
}
@keyframes open {
    0% {
        opacity: 0;
        transform: translateY(-5px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
.open-enter-active {
    animation: open 1s;
}
.open-leave-active {
    animation: open .5s linear reverse;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.accordion {
    max-width: 70%;
    margin: 10px auto;
}
.title:hover {
    opacity: .8;
    cursor: pointer;
}
.title {
    margin-bottom: 10px;
    background-color: #FAF4EC;
    padding: 22px;
    border-radius: 5px;
}

.title>p {
    display: inline-block;
    font-weight: bold;
    font-size: 17px;
    color: #A27A40;
    font-family: 'Noto Sans Japanese', sans-serif;
    margin-bottom: 0;
}
.title i {
    float: right;
    line-height: 1.3;
}
.content {
    padding: 0 15px;
    margin-bottom: 10px;
}

.accordion-content {
    display: flex;
}

.question {
    top: 5%;
    font-size: 40px;
    color: #A27A40;
    font-family: "DIN 2014"
}

.answer {
    font-size: 2rem;
    margin: 18px 29px 29px;
    font-family: din-2014-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.accordion-content p {
    margin: 0 auto;
    padding: 1rem 0 2.4rem 0;
    font-size: 1rem;
    text-align: left;
    line-height: 2;
}
</style>

<style scoped>
@media screen and (max-width: 520px) {
    .accordion {
        max-width: 100%;
        width: 100% !important;
    }

    .accordion-content p {
        padding-right: 2rem;
    }

    .rotate {
        transition-duration: .3s;
        transform: rotate(180deg);
    }

    .rotateClose {
        transition-duration: .3s;
        transform: rotate(0deg);
    }
    .title > p {
        margin-right: 0;
        width: 70%;
    }

    .title i {
        margin-top: 3%;
    }

    .question {
        top: 15%;
    }
}
</style>
