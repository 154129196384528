<template>
    <router-link :to="{name: link,  query: { refmedia: refmedia }}" v-if="refmedia != null && isPath === false">
        <span v-html="linkTxt"></span>
    </router-link>
    <router-link :to="{name: link}" v-else-if="isPath === false"><span v-html="linkTxt"></span></router-link>
    <router-link :to="{ path: link,  query: { refmedia: refmedia }}" v-else-if="refmedia != null && isPath === true"><span v-html="linkTxt"></span></router-link>
    <router-link :to="{ path: link }" v-else><span v-html="linkTxt"></span></router-link>

</template>

<script>

export default ({
    props: {
        link: String,
        linkTxt: String,
        isPath: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            refmedia: this.$route.query.refmedia
        }
    },
})
</script>
