<template>
    <div class="content-top">
        <div class="page-title">
            <h1>{{ japaneseClinic }}</h1>
            <img class="" v-bind:src="this.$imgTitleBar" />
            <h2>{{ englishClinic }}</h2>
        </div>
        <div id="imgs" class="mx-auto">
            <img id="ladies-img" class="mx-md-5"
                @click="changeImg('ladies', isLadiesOnClick), moveClinicList(isLadiesOnClick)"
                v-bind:class="{ marginBottom : isMensOnClick === true ,  spImgSize : width < 767 , spClickedImgSize : width < 767 && isLadiesOnClick }" v-bind:src="img.ladies" alt="">
            <img id="mens-img" @click="changeImg('mens', isMensOnClick), moveClinicList(isLadiesOnClick)"
                v-bind:class="{ marginBottom : isLadiesOnClick === true , spImgSize : width < 767 , spClickedImgSize : width < 767 && isMensOnClick }" v-bind:src="img.mens" alt="">
        </div>
        <article id="clinic" class="accordion-clinic" v-show="isLadiesOnClick || isMensOnClick">
            {{ setClinicList(isLadiesOnClick, isMensOnClick) }}
            <div v-for="(state, index) in stateData" :key="state.id" class="accordion-header accordion-area w-100"
                v-bind:class="{ open : show[index] }">
                <div v-bind:id=state.name_eng class="state-name w-100" @click="openArea(index)">
                    <span>{{ state.name }}</span>
                    <div class="i-box">
                        <span class="clinic-count" style="">{{ countClinic(isLadiesOnClick,
                            isMensOnClick, state.name_eng) }}</span>
                        <img v-bind:src="img.closeIcon" class="one-i" v-if="!show[index]" />
                        <img v-bind:src="img.openIcon" class="one-i2" v-if="show[index]" />
                    </div>
                </div>
                {{ setPrefList(isLadiesOnClick, isMensOnClick) }}
                <div v-for="(pref, prefIndex) in prefData" :key="pref.name">
                    <div v-if="state.name === pref.area_state && pref.active === 1">
                        <div @click="openPref(prefIndex)">
                            <slide-up-down :active="show[index]" :duration="100" class="clinic-name accordion-header"
                                style="background-color:#FAF4EC;">
                                {{ pref.name }}
                                <div class="i-box">
                                    <img v-bind:src="img.closePrefClinicIcon" class="one-i"
                                        v-if="!prefShow[prefIndex]" />
                                    <img v-bind:src="img.openPrefClinicIcon" class="one-i2"
                                        v-if="prefShow[prefIndex]" />
                                </div>
                            </slide-up-down>
                        </div>
                        <div v-if="show[index] && prefShow[prefIndex]">
                            <div v-for="(eminal, clinicIndex) in clinicList" :key="eminal.name">
                                <div v-if="eminal.pref_eng === pref.name_eng">
                                    <div @click="openClinic(clinicIndex)">
                                        <div @click="openClinicDetail(clinicIndex)"
                                            class="clinic-name accordion-header">
                                            <span v-if="isLadiesOnClick === true">レディース</span><span
                                                v-else-if="isMensOnClick === true">メンズ</span>
                                            {{ eminal.name }}
                                            <div class="i-box">
                                                <img v-bind:src="img.closePrefClinicIcon" class="one-i"
                                                    v-if="!clinicShow[clinicIndex]" />
                                                <img v-bind:src="img.openPrefClinicIcon" class="one-i2"
                                                    v-if="clinicShow[clinicIndex]" />
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="clinicShow[clinicIndex]">
                                        <div class="accordion-inner">
                                            <article class="info clearfix">
                                                <div class="clinic-prof">
                                                    <table class="tbl01 gray">
                                                        <tbody>
                                                            <tr>
                                                                <th>住所</th>
                                                                <td v-html="eminal.address"></td>
                                                            </tr>
                                                            <tr>
                                                                <th>最寄り駅</th>
                                                                <td v-if="isLadiesOnClick">{{ eminal.nearest }}</td>
                                                                <td v-else-if="isMensOnClick">{{ eminal.nearest_station
                                                                    }}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>診療時間</th>
                                                                <td>{{ eminal.time }}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>電話番号</th>
                                                                <td>{{ eminal.tel }}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>診療メニュー</th>
                                                                <td v-html="eminal.diagnosis_menu"></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="map">
                                                    <iframe v-bind:src="eminal.iframe" width="400" height="100"
                                                        style="border:0;" allowfullscreen="" loading="lazy"
                                                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                                                    <a v-bind:href="eminal.mapurl" class="bot-open" target="_blank"
                                                        rel="noopenper noreferrer"><img v-bind:src="img.map" /></a>
                                                    <a v-bind:href="setDetailURL(isLadiesOnClick, isMensOnClick, eminal.pref_eng, eminal.name_eng)"
                                                        class="bot-open" target="_blank" rel="noopenper noreferrer"><img
                                                            v-bind:src="img.detailLink" /></a>
                                                </div>
                                            </article>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </div>
</template>

<script type="text/javascript">
import axios from "axios"
export default ({
    props: {
        gender: String,
        areaId: String
    },
    data() {
        return {
                viewGender: this.gender,
                area: this.areaId,
                japaneseClinic: "クリニック一覧",
                englishClinic: "Clinic List",
                isMensOnClick: false,
                isLadiesOnClick: false,
                isOpen: false,
                show: {},
                prefShow: {},
                clinicShow: {},
                clinicDetailShow: {},
                img: {
                    ladies: require("@/assets/images/pc/clinics_ladies.jpg"),
                    mens: require("@/assets/images/pc/clinics_mens.jpg"),
                    closeIcon: require("@/assets/images/pc/area_close_icon.png"),
                    openIcon: require("@/assets/images/pc/area_open_icon.png"),
                    openPrefClinicIcon: require("@/assets/images/pc/pref_clinic_open_icon.png"),
                    closePrefClinicIcon: require("@/assets/images/pc/pref_clinic_close_icon.png"),
                    map: require("@/assets/images/pc/map_img.png"),
                    detailLink: require("@/assets/images/pc/detail_link_img.png")
                },
                apiBaseURL: this.$store.state.apiBaseURL,
                ladiesURL: this.$store.state.ladiesURL,
                ladiesClinicURL: "https://eminal-clinic.jp/clinic/",
                mensURL: this.$store.state.mensURL,
                mensClinicURL: "https://mens-eminal.jp/clinic/",
                authParam: this.$store.state.authParam,
                eminalClinicList: "",
                clinicCount: "",
                mensEminalClinicList: "",
                clinicList: "",
                stateData:  this.$store.state.stateData,
                ladiesPrefData:  this.$store.state.ladiesPrefData,
                mensPrefData: this.$store.state.mensPrefData,
                prefData: "",
                detailURL: "",
                width: this.$store.state.width
        }
    },
    created() {
        axios
            .get(this.apiBaseURL + this.ladiesURL + this.authParam)
            .then(response => (this.eminalClinicList = response.data))
        axios
            .get(this.apiBaseURL + this.mensURL + this.authParam)
            .then(response => (this.mensEminalClinicList = response.data))
    },
    mounted() {
        let elementId = document.getElementById(this.areaId)
            if (this.viewGender != undefined && this.viewGender === 'ladies') {
                this.img.ladies = require("@/assets/images/pc/clinic_ladies_on_click.jpg")

                if (this.isLadiesOnClick === true) {
                    this.img.ladies = require("@/assets/images/pc/clinics_ladies.jpg")
                    this.isLadiesOnClick = !this.isLadiesOnClick
                } else {
                    this.isLadiesOnClick = !this.isLadiesOnClick
                }

            } else if (this.viewGender != undefined && this.viewGender === 'mens'){
                this.img.mens = require("@/assets/images/pc/clinic_mens_on_click.jpg")

                if (this.isMensOnClick === true) {
                    this.img.mens = require("@/assets/images/pc/clinics_mens.jpg")
                    this.isMensOnClick = !this.isMensOnClick
                } else {
                    this.isMensOnClick = !this.isMensOnClick
                }
            }

        elementId.click();
    },
    updated() {
        let elementId = document.getElementById(this.areaId)
        window.scrollBy(0, elementId)

        if (this.areaId === 'chugoku&shikoku' || this.areaId === 'kyusyu&okinawa') {
            window.scrollBy(0, 400)
        }

    },
    methods: {
        changeImg (gender, isClicked) {
            if (gender === 'ladies') {
                this.img.ladies = require("@/assets/images/pc/clinic_ladies_on_click.jpg")

                if (this.isMensOnClick === true) {
                    this.img.mens = require("@/assets/images/pc/clinics_mens.jpg")
                    this.isMensOnClick = false
                }

                if (isClicked === true) {
                    this.img.ladies = require("@/assets/images/pc/clinics_ladies.jpg")
                    this.isLadiesOnClick = !isClicked
                } else {
                    this.isLadiesOnClick = !isClicked
                }

            } else {
                this.img.mens = require("@/assets/images/pc/clinic_mens_on_click.jpg")

                if (this.isLadiesOnClick === true) {
                    this.img.ladies = require("@/assets/images/pc/clinics_ladies.jpg")
                    this.isLadiesOnClick = false
                }

                if (isClicked === true) {
                    this.img.mens = require("@/assets/images/pc/clinics_mens.jpg")
                    this.isMensOnClick = !isClicked
                } else {
                    this.isMensOnClick = !isClicked
                }
            }
        },
        openArea(index) {
            this.$set(this.show, index, !this.show[index])
        },
        openPref(index) {
            this.$set(this.prefShow, index, !this.prefShow[index])
        },
        openClinic(index) {
            this.$set(this.clinicShow, index, !this.clinicShow[index])
        },
        openClinicDetail(index) {
            this.$set(this.clinicDetailShow, index, !this.clinicDetailShow[index])
        },
        setClinicList(isLadies, isMens) {
            if (isLadies === true) {
                this.clinicList = this.eminalClinicList
                this.detailURL = this.ladiesClinicURL
            } else if (isMens === true) {
                this.clinicList = this.mensEminalClinicList
                this.detailURL =  this.mensClinicURL
            }
        },
        setPrefList(isLadies, isMens) {
            if (isLadies === true) {
                this.prefData = this.ladiesPrefData
            } else if (isMens === true) {
                this.prefData = this.mensPrefData
            }
        },
        countClinic(isLadies, isMens, stateName) {
            let countClinic = ''
            if (isLadies === true) {
                countClinic = this.eminalClinicList.filter(
                    function (v) {
                        return v.state_eng === stateName
                    }
                )
            } else if(isMens === true) {
                countClinic = this.mensEminalClinicList.filter(
                    function (v) {
                        return v.state_eng === stateName
                    }
                )
            }

            return countClinic.length + '院';
        },
        moveClinicList(isLadiesOnClick) {

            if (window.outerWidth > 900) {
                return
            }

            const mensImg = document.getElementById("mens-img")
            const clinicList = document.getElementById("clinic")

            if (isLadiesOnClick) {
                mensImg.style.display = "none";
                clinicList.appendChild(mensImg)
                mensImg.style.display = "block";

            } else {
                document.getElementById("imgs").appendChild(mensImg)
                mensImg.style.display = "block";
            }
        },
        setDetailURL(isLadies, isMens, pref_eng, name_eng) {

            if (name_eng === 'ikebukuro_higashiguchi') {
                name_eng = 'ikebukuro-higashi'
                pref_eng = 'tokyoto'
            } else if (name_eng === 'shinjuku_nishiguchi') {
                name_eng = 'shinjuku-nishiguchi'
            } else if (name_eng === 'shibuyaekimae' || name_eng === 'ginza' || name_eng === 'tachikawa') {
                pref_eng = 'tokyoto'
            } else if (name_eng === 'shisaibashi2go') {
                name_eng = 'shinsai2go'
            } else if (name_eng === 'fukuoka_tenjin') {
                name_eng = 'fukuoka-tenjin'
            } else if (name_eng === 'kagoshima2') {
                name_eng = 'kagoshima-2'
            }

            let clinicURL
            if (isLadies === true) {
                clinicURL = this.ladiesClinicURL
            } else if (isMens === true) {
                clinicURL =  this.mensClinicURL
            }
            return clinicURL + pref_eng + '/' + name_eng + '/'
        },
    },
})
</script>

<style scoped>

img {
    cursor: pointer;
}

iframe {
    height: 23vh;
    border-radius: 10px;
    margin: -5% 0 0 auto;
}

.trans_slide-enter-active, .trans_slide-leave-active {
    transition: opacity .5s;

}

.trans_slide-enter, .trans_slide-leave-to {
    opacity: 0;
}

.rotate {
    transition-duration: .3s;
    transform: rotate(90deg);
}

.rotateClose {
    transition-duration: .3s;
    transform: rotate(0deg);
}

.marginBottom {
    margin-bottom: 6%;
}

.clinic-introduction {
    margin-top: 5%;
}

.clinic-introduction h1 {
    font-family: "Source Han Sans JP";
    font-weight: 500;
    font-size: 36px;
    text-align: center;
    color: #2c2c2c;
}
/*
.clinic-introduction h2 {
    font-family: "din-2014",sans-serif;
    font-weight: 300;
    font-style: normal;

    font-size: 32px;
    text-align: center;
    color: #2c2c2c;
    margin-top: -1%;
} */

.clinic-list > .ja-map {
    margin: 0 0 5% 23%;
    width: 55%;
}

div.state-name {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 2rem;
    padding: 1rem 1.5rem;
    display: block;
    margin: 2px 0;
    position: relative;
    font-family: "Source Han Sans JP";
    font-weight: bold;
    font-size: 20px;
    text-align: left;
    color: #fff;
    height: 70px;
    border-radius: 10px;
    background: #C3A983;
}

h4.pref-name {
    display: block;
    padding: 1rem 1.5rem;
    font-size: 2rem;
    background-color: #d7f6ff;
    color: #17c0e5;
    margin: 0 0 2px;
}

div.clinic-name {
    display: block;
    font-family: "Source Han Sans JP";
    font-weight: bold;
    font-size: 20px;
    text-align: left;
    color: #C3A983;
    padding: 1rem 1.5rem;
    background: #f0f0f0;
    margin: 2px 0;
    position: relative;
    height: 70px;
    border-radius: 10px;
    cursor: pointer;
}

div.clinic-name .sub {
    font-size: 0.5rem;
}

.accordion-clinic table {
    border-collapse: collapse;
    /* margin-top: 20px; */
}

.accordion-clinic th,
.accordion-clinic td {
    border: 1px solid #cdcdcd;
    padding: 0.5rem;
    color: #666666;
}

.accordion-clinic th {
    background: #ededed;
}

 p.img {
    padding-top: 20px;
    float: left;
}

 p.img img{
    width:350px;
    height:240px;
    object-fit: cover;
}

 .clinic-prof {
    float: right;
    padding-left: 30px;
    margin-right: 10px;
}

.inner-btn {
    margin: 2rem;
}
.inner-btn a {
    width: 95%;
    background-color: #EA64AE;
    color: #ffffff;
    display: block;
    padding: 0.7rem 1rem;
    border-radius: 8px;
    margin: 0 auto;
    text-align: center;
    text-decoration: none;
    font-size:normal;
    transition: 1.0s;
}


.clinic-list img {
    margin: 0 0 5% 25%;
}

.footer {
    display: flex;
    flex: 1;
    padding: 2rem 0;
    border-top: 1px solid #eaeaea;
    justify-content: center;
    align-items: center;
}

/* .clinic-list-area {
    width: 1200px;
}

.clinic-list-area ul li{
    padding-right: 12%;
    line-height: 70px;
} */

.accordion-clinic {
    margin: 0 auto;
    clear: both;
    display: block;
    padding: 0;
    width: 1030px;
}

.accordion-area {
	cursor: pointer;
}

.accordion-clinic .accordion-header {
	position: relative;
	z-index: +1;
	transition-duration: 0.2s;
    margin: 1% 0 1%;
}


.i-box {
    position: relative;
    border-top-left-radius: 30%;
}


.accordion-clinic .accordion-header .i-box {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: 20%;
	left: 930px;
	width: 7%;
	height: 40px;
	margin-top: -20px;
}

.accordion-clinic .accordion-header .i-box .one-i {
	display: block;
	width: 18px;
	height: 18px;
    transform-origin: center center;
    transition-duration: 0.2s;
	position: relative;
}
.accordion-clinic .accordion-header.open .i-box {
	-webkit-transform: rotate(-360deg);
	transform: rotate(-360deg);
}

.accordion-clinic .accordion-header .i-box {
    position: relative;
}

.accordion-clinic .accordion-header .i-box .one-i2{
    content: "";
    position: absolute;
    bottom: 15px;
    left: 25px;
    cursor: pointer;
}

.one-i2.display {
    display: block !important;
}


.accordion-clinic .accordion-header .i-box .one-i {
    display: block;
    width: 18px;
    height: 18px;
    transform-origin: center center;
    transition-duration: 0.2s;
    position: relative;
}

input:checked + .one-i {
    transform: "rotate( 180deg )"
}

.accordion-clinic .accordion-header.open .i-box .one-i:before {
content: none;
}

.clinic-count {
    position: absolute;
    right: 35px;
    width: 100%;
}

.one-i-pref-clinic,
.one-i2-pref-clinic {
    bottom: 80%;
}

.accordion-clinic .accordion-inner {
    padding: 0;
    box-sizing: border-box;
}

/*
PARTS
***************************************************************************/

/* ▼TABLE▼ */
.tbl01 {
    width: 500px;
}

.tbl01 th, .tbl01 td {
	padding: 20px;
	vertical-align: middle;
	line-height: 1.65;
}

.tbl01 th {
	font-weight: bold;
}

.tbl01.gray th,
.tbl01.gray td {
    text-align: left;
	border: solid 1px #ccc;
}

.tbl01.gray th {
	color: #666;
	background: #ededed;
	width: 30%;
}

.info {
    margin: 5% 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}
.map {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10%;
    padding-top: 2%;
}

.map img {
    margin: 10% 0 0 23%;
}

.map_iframe {
    width: 326px;
    height: 225px;
    border-radius: 10px;
}

.map-iframe {
    width: 100%;
    min-height: 100%;
    margin-top: -15%;
    margin-left: -10%;
}
@media screen and (max-width: 767px) {

    .spImgSize {
        width: 350px;
        height: 250px;
        margin: 0 auto;
    }

    .spClickedImgSize {
        height: 300px;
    }
    .sp-ladies-img {
        margin: 0 !important;
    }

    div.state-name {
        font-family: "Noto Sans Japanese, sans-serif"
    }

    .clinic-introduction {
        margin: 10% 0 10%;
    }

    .clinic-introduction h1 {
        font-size: 28px;
    }

    /* .clinic-introduction h2 {
        font-size: 24px;
    } */

    .clinic-list img{
        margin: 0 0 10% 0;
    }

    .accordion-clinic {
        width: 100%;
    }

    .accordion-clinic .accordion-header .i-box {
        /* width: 100%; */
        bottom: 45%;
        left: 130px;
    }

    .accordion-clinic .accordion-header .i-box .one-i {
        left: 170px;
    }

    .accordion-clinic .accordion-header .i-box .one-i2{
        width: auto;
        left: 170px;
        top: 25%;
    }

    .tbl01.gray th {
        width: 33%;
    }

    .info {
        flex-direction: column-reverse;
        margin: 5% 0 0 5%;
    }

    .accordion-clinic table {
        border-collapse: separate;
        border: 1px solid #707070;
        margin-top: 20px;
        border-spacing: 0;
        border-radius: 6px;
        overflow: hidden;
    }

    .clinic-count {
        width: 212%;
        left: 85px;
    }

    .tbl01 {
        width: 325px;
    }

    .clinic-prof {
        padding-left: 17px;
        margin-bottom: 10%;
    }

    iframe {
        width: 80vw;
        margin: 0 5%;
    }
    .map {
        margin-left: 0;
        flex-direction: column;
        align-items: normal;
        padding-top: 7%;
    }

    .map img {
        margin: 20px 0px 10px -10px;
    }

    .map-iframe {
        margin-top: 0%;
        margin-left: -2%;
    }

}

/* sec12 */
.sec12 {
    padding: 1rem;
    background-color: #7b7b7b;
    background-size: contain;
}


.sec12 .jpn_map{
    padding: 0 1rem 2rem;
}

.sec12 h3 span {
    float: right;
    margin-right: 2.5rem;
    font-size: 0.9rem;
    padding-top: 0.3rem;
    color: #e0fa01;
}

.sec12 .clinic_name {
    border: 1px solid #4b4b43;
    color: #221815;
    padding: 0.5rem 1rem;
}

.sec12 dt {
    color: #ad0003;
}

.sec12 dd {
    color: #4b4b43;
    margin-left: 0;
    margin-bottom: 1rem;
}

.access_info {
    margin-bottom: 1.5rem;
    font-size: 1.0rem;
    color: #4b4b43;
}

.map_info {
    padding-bottom: 1rem;
}

.doctor_info {
    border: 1px solid #e0f1f7;
    padding: 1.2rem;
}

.doctor_info h5 {
    background-color: #a8a8a9;
    padding: 0.5rem;
    margin-top: 0;
    color: white;
}

.doctor_name {
    display: flex;
    align-items: flex-end;
    margin-bottom: 1.5rem;
}

.doctor_name dt {
    color: #555555;
    flex-basis: 20%;
    margin-right: 0.5rem;
}

.sec12 .doctor_name dd {
    margin-bottom: 0;
}

.doctor_history {
    font-size: smaller;
    color: #555555;
}

/* アコーディオン */
.accordion-container .accordion-title {
    position: relative;
    margin: 0.25rem 0;
    padding: 0.6rem 1.3rem;
    background-color: #4a4d4f;
    font-size: 1.2em;
    font-weight: normal;
    color: white;
    cursor: pointer;
    border-radius: 1vw;
}

.accordion-container .accordion-title::before {
    content: "";
    position: absolute;
    top: 20%;
    right: 1rem;
    width: 30px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all .3s ease-in-out;
}

.accordion-container .accordion-title::after {
    content: "";
    position: absolute;
    top: 20%;
    right: 1rem;
    width: 30px;
    height: 30px;
    background: #4a4d4f;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all .2s ease-in-out;
}


.accordion-container .accordion-title.open::before {
    transform: rotate(0deg);
}

.accordion-container .accordion-title.open::after {
    opacity: 0;
}

.accordion-content {
    margin-bottom: 1rem;
    background: white;
    padding: 1rem;
}

/* CSS for CodePen */
.accordion-container {
    width: 100%;
    margin: 0 auto;
}

</style>
