<template>
    <div class="header">
        <a class="header-logo" href="/">
            <img class="header_img01" v-bind:src="imageEminalLogo" alt="エミナルクリニック" />
            <img class="header_img01" v-bind:src="imageMensEminalLogo" alt="エミナルクリニックメンズ" />
        </a>
        <div>
            <nav class="gnavi my-5">
                <div class="gnavi-inner">
                    <ul>
                        <li>
                            <router-link-component link="philosophy" v-bind:linkTxt="japanesePhilosophy"></router-link-component>
                        </li>
                        <li>
                            <router-link-component link="menu" v-bind:linkTxt="japaneseMenu"></router-link-component>
                        </li>
                        <li>
                            <router-link-component link="clinics" v-bind:linkTxt="japanesehClinic"></router-link-component>
                        </li>
                        <li>
                            <router-link-component link="faq" v-bind:linkTxt="japanesehFaq"></router-link-component>
                        </li>
                        <li><a v-bind:href="recruitURL" target="_blank"><span>{{ japaneseRecruit }}</span></a></li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import RouterLink from '@/components/common/RouterLink'
    Vue.component('router-link-component', RouterLink)

    export default{
        data: function() {
            return {
                imageEminalLogo: require('@/assets/images/pc/eminal_logo.svg'),
                // imageEminalLogoSp: require('@/assets/images/sp/eminal_logo.svg'),
                imageMensEminalLogo: require('@/assets/images/pc/mens_logo.svg'),
                imageMensEminalLogoSp: './images/pc/mens_logo.svg',
                japanesePhilosophy: "企業理念",
                japaneseMenu: "診療メニュー",
                japanesehClinic: "クリニック一覧",
                japanesehFaq: "よくある質問",
                japaneseRecruit: "採用情報",
                urlMenuId: '/menu',
                recruitURL: this.$store.state.recruitURL,
            }
        },
        created() {
            if (this.$route.query.refmedia != null) {
                this.recruitURL += "?refmedia=" + this.$route.query.refmedia
            }
        },
    };



</script>

<style scoped>
/* メインビジュアル */
.header {
    display: flex;
    justify-content: space-between;
    margin-inline: 3em;
}

.header-logo {
    display: flex;
    z-index: 2;
}
.header_img01 {
    margin-right: 25px;
    width:100%;
}

@media screen and (max-width: 520px) {

    .header-logo {
        display: block;
        width: 100%;
        right: 0;
        left: -10%;
    }
    .header_img01 {
        width: 30%;
    }
}

.gnavi {
    margin: 0 0 0 auto;
    margin-right: 0;
    width: 100%;
}
.gnavi ul {
    display: flex;
}

.gnavi li {
    flex: 1 1;
    position: relative;
    display: flex;
}

.gnavi li:after {
    content: "";
    display: block;
    width: 2px;
    height: 20px;
    background-color: #C3A983;
    position: absolute;
    right: -1px;
    top: calc((100% - 20px) / 2);
}

.gnavi li:last-child::after {
    content: none;
}

.gnavi a {
    display: flex;
    flex: 1 1;
    justify-content: center;
    padding: 0 20px;
}

.gnavi span {
    display: flex;
    align-items: center;
    font-size: clamp(14px,1.08vw,28px);
    white-space: nowrap;
}


.gnavi-inner a {
    text-decoration: none;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-weight: 500;
    color: #707070 !important;
    font-size: 1rem;
}

.gnavi-inner a span:hover {
    color: #333333;
}

.gnavi-inner a::after {
    position: absolute;
    top: 32px;
    left: 35%;
    content: '';
    width: 30%;
    height: 2px;
    background: #C3A983;
    transform: scale(0, 1);
    transform-origin: left top;
    transition: transform .3s;
}

a:hover::after {
    transform: scale(1, 1);
}
</style>
