<template>

    <div id="removal-box" class="removal-box">
        <div class="menu-btn">
            <div class="ladies-removal position-relative">
                <div class="menu-headline">
                    <h4>{{ removalMenu }}</h4>
                </div>
                <a v-bind:href="links.ladiesLink" target="_blank" class="ladies-removal-box">
                    <img v-bind:src="removalMenuList.ladiesRemovalImg" alt="" v-if="width > 900" />
                    <img class="ladies-removal-img" v-bind:src="removalMenuList.spLadiesRemovalImg" alt="" v-else />

                    <div class="position-absolute text-white ladies-removal-title" style="">
                        <p>{{ removalMenuList.ladiesRemovalMenu }}</p>
                    </div>
                </a>

                <div class="text-start top-100 mt-md-3">
                    <p class="description">{{ removalMenuList.ladiesRemovalDescription }}</p>
                </div>
            </div>
            <div class="mens-removal position-relative">
                <a v-bind:href="links.mensLink" target="_blank" class="mens-removal-box">
                    <img v-bind:src="removalMenuList.mensRemovalImg" alt="" v-if="width > 900" />
                    <img class="mens-removal-img" v-bind:src="removalMenuList.spMensRemovalImg" alt="" v-else />
                    <div class="position-absolute text-white mens-removal-title">
                        <p>{{ removalMenuList.mensRemovalMenu }}</p>
                    </div>
                </a>
                <div class="text-start top-100 mt-3">
                    <p class="description">{{ removalMenuList.mensRemovalDescription }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default({
    props: {
        'refmedia' : {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            removalMenu: "医療脱毛メニュー",
                removalMenuList: {
                    ladiesRemovalMenu: "医療脱毛 (レディース)",
                    ladiesRemovalDescription: "最短1ヶ月に1回の施術ペースで脱毛できるエミナルの全身医療脱毛",
                    ladiesRemovalImg: require("@/assets/images/pc/menu_ladies_removal.png"),
                    spLadiesRemovalImg: require("@/assets/images/sp/menu_ladies_removal.png"),
                    mensRemovalDescription: "最短1ヶ月に1回の施術ペースで脱毛できるメンズエミナルの全身医療脱毛",
                    mensRemovalMenu: "医療脱毛 （メンズ）",
                    mensRemovalImg: require("@/assets/images/pc/menu_mens_removal.png"),
                    spMensRemovalImg: require("@/assets/images/sp/menu_mens_removal.png"),
            },
            svg: {
                spLadiesSvg: ''
            },
            links: {
                ladiesLink: "https://eminal-clinic.jp/",
                mensLink: "https://mens-eminal.jp/",
            },
            frameImg: require("@/assets/images/pc/menu_frame.jpg"),
            spFrameImg: require("@/assets/images/sp/menu_frame.jpg"),
            width: this.$store.state.width,
        }
    },
    created() {
        if (this.refmedia !== "") {
            this.links.ladiesLink += "?refmedia=" + this.refmedia
            this.links.mensLink += "?refmedia=" + this.refmedia
        }
    },
    mounted() {
        window.addEventListener('resize', this.resizeWindow)
    },
    methods: {
        resizeWindow() {
            this.width = window.outerWidth
        }
    },
})
</script>

<style scoped>

    .ladies-removal {
        width: 450px;
        border-top: 5px solid #E95A8E;
        padding: 3em 2em;
        background: linear-gradient(135deg, rgba(251,231,252,0.2) 0%, #FFF4FA 50%, rgba(255,229,244,0.1) 100%);
    }
        
    .mens-removal {
        width: 450px;
        border-top: 5px solid #707070;
        padding: 6.2em 2em 3em;
        background: linear-gradient(135deg, rgba(229,229,229,0.2) 0%, rgba(239,239,239,0.95) 50%, rgba(255,255,255,0.1) 100%);
    }

    h4 {
        text-align: left;
        margin-bottom: 1em;
    }

    .ladies-removal-box,
    .mens-removal-box {
        position: relative;
    }

    .ladies-removal-title {
        top: -18%;
        left: 0;
        right: 45%;
        font-size: 14px;
    }

    .ladies-removal,
    .mens-removal {
        width: 50%;
    }

    .menu-btn {
        display: flex;
        justify-content: center;
        max-width:900px;
        margin:0 auto;
    }

    .mens-removal-title {
        top: -18%;
        left: 10%;
        font-size: 14px;
    }

    .description {
        margin-inline: 3%;
    }

    .description:nth-child(2) {
        margin-left: 7%;
    }

    @media screen and (max-width: 900px) {
        .mens-removal {
            padding: 3em 2em;
        }
        
        .menu-btn {
            display: block;
        }

        .frame-img {
            width: 100vw;
            height: 55vh;
        }

        .removal-box {
            width: 100%;
            flex-direction: column;
            top: 17%;
            left: 0;
            right: 0;
            /* transform: translate(-18%, 0); */
        }

        .ladies-removal,
        .mens-removal {
            width:100%;
        }

        .ladies-removal-title {
            top: 6%;
            right: 49%;
            font-size: clamp(12px,2.6vw,20px);
        }

        .mens-removal-title {
            top: 6%;
            left: 0;
            width: 50%;
            font-size: clamp(12px,2.6vw,20px);
        }

        h4 {
            text-align: center;
        }

        .description {
            margin: 2% auto 5%;
            width: 80%;
            font-size: clamp(12px,2.1vw,16px);
        }

        .ladies-removal-img,
        .mens-removal-img {
            width: 80vw;
            height: 23vw;
        }
    }

    @media screen and (max-width: 399px) {
        .frame-img {
            height: 70vh;
        }

    }
</style>
