<template>
<!-- test -->
  <div id="app">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap" rel="stylesheet">
    <router-view></router-view>
  </div>
</template>
<script>
window.$docsify = {
  requestHeaders: {
    'cache-control': 'no-store',
  }
}

export default {
  name: 'App',
  data() {
    return {
      width: this.$store.state.width
    }
  },
  created() {
    window.addEventListener('resize', this.setViewport)
    this.setViewport();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setViewport)
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    setViewport() {
      let changeWindowSize = 375
      let viewportContent = "width=device-width,initial-scale=1.0"
      if (window.innerWidth < changeWindowSize) {
        viewportContent = "width=375,user-scalable=no,viewport-fit=cover"
      }
      document.querySelector("meta[name='viewport']").setAttribute("content", viewportContent)
    },
    headlineResize() {
      this.width = window.outerWidth;
    }
  }
}
</script>

<style>
body {
  overflow-x: hidden;
}
img {
  aspect-ratio: 16 / 9;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}

.font-DIN {
  font-family: "DIN 2014";
  font-weight: 300;
  /* font-size: 32; */
  line-height: 2;
}

.page-title {
  font-size: 36px !important;
  margin-top: 15% !important;
}

h2 {
  font-family: "din-2014", sans-serif;
  font-weight: 300 !important;
  font-style: normal;
  font-size: 32px !important;
  color: #2c2c2c;
}

@media screen and (max-width: 767px) {
  .page-title h1 {
    font-size: 22px !important;
  }

  .page-title h2 {
    font-size: 18px !important;
  }

}
</style>