<template>
    <div>
        <header-component v-if="width > 1050"></header-component>
        <hamburger-component v-else></hamburger-component>
        <main-component></main-component>
        <contact-component></contact-component>
        <footer-component></footer-component>
    </div>
</template>

<script>
import Vue from 'vue'
import HeaderComponent from './common/HeaderComponent'
import HamburgerComponent from './common/HamburgerComponent'
import MainComponent from './privacypolicy/MainComponent.vue'
import ContactComponent from './common/ContactComponent'
import FooterComponent from './common/FooterComponent'
Vue.component('header-component', HeaderComponent)
Vue.component('hamburger-component', HamburgerComponent)
Vue.component('main-component', MainComponent)
Vue.component('contact-component', ContactComponent)
Vue.component('footer-component', FooterComponent)

export default ({
    data: function () {
        return {
            width: window.outerWidth
        }
    },
    methods: {
        headlineResize: function () {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
        }
    },
    mounted: function () {
        window.addEventListener('resize', this.handleResize)
    },
    components: {
        HeaderComponent,
        HamburgerComponent,
        MainComponent,
        ContactComponent,
        FooterComponent
    }
})
</script>
