<template>
    <div class="home">
        <iframe src="https://eminal-group.jp/layouts/mv.html" style=".header {
            display: none;
            }"></iframe>
        <global-menu-component v-if="width > 900"></global-menu-component>
        <hamburger-component v-else></hamburger-component>
        <philosophy-component></philosophy-component>
        <info-component></info-component>
        <menu-component></menu-component>
        <clinics-component></clinics-component>
        <faq-component></faq-component>
        <inquiry-component></inquiry-component>
        <contact-component></contact-component>
        <footer-component></footer-component>
    </div>
</template>

<script>
import Vue from 'vue'
import GlobalMenuComponent from "./home/GlobalMenuComponent";
import HamburgerComponent from './common/HamburgerComponent';
import PhilosophyComponent from "./home/PhilosophyComponent";
import InfoComponent from "./home/InfoComponent";
import MenuComponent from "./home/MenuComponent";
import ClinicsComponent from "./clinics/MainComponent";
import FaqComponent from './home/FaqComponent';
import InquiryComponent from './home/InquiryComponent';
import ContactComponent from "./common/ContactComponent";
import FooterComponent from "./common/FooterComponent";
import SlideUpDown from "vue-slide-up-down"
import SlideUpDown2 from "vue-slide-up-down"
import updateRefmedia from '@/util/updateRefmedia.js'

Vue.component('example-component', require('./ExampleComponent.vue').default)
Vue.component('global-menu-component', GlobalMenuComponent)
Vue.component('hamburger-component', HamburgerComponent)
Vue.component('philosophy-component', PhilosophyComponent)
Vue.component('info-component', InfoComponent)
Vue.component('menu-component', MenuComponent)
Vue.component('clinics-component', ClinicsComponent)
Vue.component('faq-component', FaqComponent)
Vue.component('footer-component', FooterComponent)
Vue.component('inquiry-component', InquiryComponent)
Vue.component('contact-component', ContactComponent)
Vue.component('global-menu-component', GlobalMenuComponent)
Vue.component('slide-up-down', SlideUpDown)
Vue.component('slide-up-down2', SlideUpDown2)

Vue.prototype.$japaneseMenu = "診療メニュー"
Vue.prototype.$englishMenu = "MENU"
Vue.prototype.$imgDir = "./images/"
Vue.prototype.$imgDirPC = "@/assets/images/pc/";
Vue.prototype.imgDirSP = "./images/sp/"
Vue.prototype.$imgTitleBar = require("@/assets/images/pc/title_bar.png")

export default ({
    data: function () {
        return {
            width: window.innerWidth,
        }
    },
    head: {
        title: {
            inner: "エミナルグループ総合サイト",
            separator: '|',
            complement: 'TOP'
        },
    },
    methods: {
        headlineResize: function () {
            this.width = window.innerWidth;
        },
    },
    beforeCreate() {
        updateRefmedia(this.$route.query.refmedia)
    },
    mounted: function () {
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy: function() {
        window.removeEventListener('resize', this.handleResize)
    },
    components: {
        // HeaderComponent,
        // MainVisualComponent,
        GlobalMenuComponent,
        HamburgerComponent,
        PhilosophyComponent,
        InfoComponent,
        MenuComponent,
        ClinicsComponent,
        FaqComponent,
        InquiryComponent,
        ContactComponent,
        FooterComponent,
    }
})


</script>

<style scoped>
iframe {
    border:none;
    width:min(100%, 1920px);
    height:100vh;
}
</style>

<style>

.content-top {
    margin: 0 auto;
    max-width:1050px;
}

.header {
    display: block;
}

h2 {
    margin-bottom: 5% !important;
}
</style>

<style>
@media screen and (max-width: 520px){
    iframe {
        width: 100%;
    }

    a {
        text-decoration: none !important;
    }

    .home {
        width: 100%;
        overflow-x: hidden;
    }

    .content-top {
        width: 100%;
        margin-top: 30%;
    }
}
</style>
