<template>
    <tr style="height: 110px;" v-if="width > 767">
        <th>
            <div class="d-flex justify-content-between position-relative">
                <span class="mt-1">{{ columnName }}</span><span class="text-white required">必須</span>
            </div>
        </th>
        <td>
            <input class="rounded border" size="110" v-model="nameFurigana" />
            <p class="text-start text-danger pt-3" v-if="!checkNameFurigana">{{ nameFuriganaError }}</p>
        </td>
    </tr>
    <div v-else>
        <div class="d-flex justify-content-between position-relative p-3 rounded" style="background: #FAF4EC; ">
            <span class="mt-1 sp-input-column">{{ columnName }}</span>
            <span class="text-white required required-column">必須</span>
        </div>
        <input class="rounded border sp-input" v-model="nameFurigana" />
        <p class="text-start text-danger" v-if="!checkNameFurigana">{{ nameFuriganaError }}</p>
    </div>
</template>

<script>
export default ({
    props: {
        columnName: String,
    },
    data() {
        return {
            nameFuriganaError: '※ フリガナは必須です',
            width: this.$store.state.width,
        }
    },
    computed: {
        nameFurigana: {
            get() {
                return this.$store.state.nameFurigana
            },
            set(v) {
                this.$store.commit('updateNameFurigana', v)
            }
        },
        checkNameFurigana() {
            let isNameFuriganaPassCheck = true

            if (this.nameFurigana.length === 0) {
                isNameFuriganaPassCheck = false
            }

            this.$emit('isNameFuriganaPassCheck', isNameFuriganaPassCheck)

            return isNameFuriganaPassCheck
        },
    },
    mounted: function () {
        window.addEventListener('resize', this.headlineResize)
    },
    methods: {
        headlineResize() {
            this.width = window.outerWidth;
        },
    },
})
</script>
