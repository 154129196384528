<template>
    <div class="info mt-5">
        <div class="info" id="menu">
            <h1>{{ info }}</h1>
            <img class="" v-bind:src="this.$imgTitleBar" />
            <h2>{{ englishInfo }}</h2>
        </div>

        <div
            class="w-50 mx-auto info-block"
            v-for="(info, index) in infoData"
            :key="info.id"
        >
            <router-link
                :to="{
                    name: 'detailInfo',
                    params: {
                        id: info.id,
                        date: info.date,
                        title: info.title,
                        content: info.content,
                    },
                    query: { refmedia: query },
                }"
                class="d-block ul-underline mt-5 info-detail"
                v-if="index < 5"
            >
                <div class="info-list d-flex justify-content-between">
                    <span>{{
                        info.created_at.split("T")[0].replace(/-/g, ".")
                    }}</span>
                    <div class="w-75">
                        <p class="pb-3 ml-md-5 text-decoration-none info-text">
                            {{ info.title | omittedText }}
                        </p>
                    </div>
                    <div v-if="width < 900" class="info-arrow"></div>
                </div>
            </router-link>
        </div>
        <div class="mt-5 svg">
            <router-link-component
                link="info"
                v-bind:linkTxt="detailSvg"
            ></router-link-component>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            info: "お知らせ",
            englishInfo: "INFORMATION",
            infoData: "",
            detailSvg:
                '<svg xmlns="http://www.w3.org/2000/svg" width="350" height="50" viewBox="0 0 350 50"><g id="お知らせを詳しく見る_btn" transform="translate(-425 -2473)"><g id="長方形_3282" data-name="長方形 3282" transform="translate(425 2473)" fill="#fff" stroke="#a27a40" stroke-width="1"><rect width="350" height="50" rx="25" stroke="none"/><rect x="0.5" y="0.5" width="349" height="49" rx="24.5" fill="none"/></g><text id="お知らせを詳しく見る" transform="translate(600 2505)" fill="#a27a40" font-size="15" font-family="Noto Sans Japanese, sans-serif" font-weight="500"><tspan x="-74.85" y="0">お</tspan><tspan y="0">知</tspan><tspan y="0">らせを</tspan><tspan y="0">詳</tspan><tspan y="0">しく</tspan><tspan y="0">見</tspan><tspan y="0">る</tspan></text><path id="多角形_133" data-name="多角形 133" d="M7.257,3.1a2,2,0,0,1,3.486,0l5.58,9.921A2,2,0,0,1,14.58,16H3.42a2,2,0,0,1-1.743-2.981Z" transform="translate(755 2489) rotate(90)" fill="#a27a40"/></g></svg>',
            infoLink: "#",
            width: window.innerWidth,
            height: window.innerHeight,
            query: "",
        };
    },
    created() {
        const refmedia = this.$route.query.refmedia;
        if (refmedia != "") {
            this.query = refmedia;
        }

        if (!this.infoData) {
            axios
                .get(
                    "https://orizo-test.heteml.net/eminal-group-backend/api/fetchInfo/" +
                        this.$store.state.authParam
                )
                .then((response) => (this.infoData = response.data))
                .catch((err) => alert(err));

            this.$store.state.infoData = this.infoData;
        }
    },
    methods: {
        headlineResize: function () {
            this.width = window.outerWidth;
        },
    },
    mounted: function () {
        window.addEventListener("resize", this.handleResize);
    },
    beforeDestroy: function () {
        window.removeEventListener("resize", this.handleResize);
    },
    filters: {
        omittedText(text) {
            return text.length > 30 ? text.slice(0, 30) + "..." : text;
        },
    },
};
</script>

<style>
.info {
    margin-top: 100px;
    text-align: center;
}
</style>

<style scoped>
.ul-underline {
    border-bottom: 1px solid #c3a983;
}
.info-list {
    font-size: 16pt;
}

.info-list::after {
    display: inline-block;
    content: "";
    width: 15px;
    height: 15px;
    margin: 5px 0 0 0;
    border-top: solid 1px #c4a986;
    border-right: solid 1px #c4a986;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.info-list span {
    color: #c3a983;
    font-size: 17px;
    font-family: "Noto Sans Japanese", sans-serif;
}

.info-list p {
    /* margin-right: 30%; */
    color: #333333;
}

.info-text {
    font-size: 16px;
}

span {
    margin-left: 5%;
}

p {
    text-align: start;
}
a {
    text-decoration: none;
}

a:hover {
    opacity: 0.5;
}

@media screen and (max-width: 520px) {
    .info-block {
        margin: 0 !important;
        width: 100% !important;
    }
    .info {
        margin-top: -40vh;
    }

    .info-list {
        flex-direction: column;
        padding-left: 5%;
    }

    .info-list::after {
        display: none;
    }

    .info-list span {
        font-family: "Noto Sans Japanese", sans-serif;
    }
    .info-list span,
    .info-list p {
        font-size: 14px;
    }

    .info-detail {
        position: relative;
    }

    .info-arrow {
        position: absolute;
        top: 45%;
        right: 8%;
        display: inline-block;
        content: "";
        width: 15px;
        height: 15px;
        margin: -4px 0 0 0;
        border-top: solid 1px #c4a986;
        border-right: solid 1px #c4a986;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    span {
        text-align: left;
        margin-left: 0;
    }
}

@media screen and (min-width: 768px) {
    .info-text {
        margin-left: 20px;
    }
}
</style>
