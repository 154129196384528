<template>
    <div>
        <div class="mt-1 mb-2 radio-element">
            <input type="radio" class="radio" id="eminal" name="gender" value="エミナルクリニック" v-model="gender" @cheked="gender"
                @change="viewClinic" @click="updateContactGenderClinic" />
            <label for="eminal" class="label">エミナルクリニック</label>
        </div>
        <div class="mt-1 mb-2 radio-element">
            <input type="radio" class="radio" id="mens" name="gender" value="メンズエミナル" v-model="gender" @cheked="gender"
                @change="viewClinic" @click="updateContactGenderClinic" />
            <label for="mens" class="label">メンズエミナル</label>
        </div>

        <select id="select" class="mb-3" v-model="pref" v-if="gender" @change="filteringClinic" @input="updateContactPref">
                <option value="">都道府県選択</option>
                <option class="" v-for="selector in setPrefData" v-show="selector.active === 1" v-bind:key="selector.name_eng" v-on:click="selected = selector.name, prefshow = !prefshow" >
                    <span v-show="selector.active === 1">{{ selector.name }}</span>
                </option>
        </select>

        <div id="select" v-if="gender && pref">
            <div class="selectBox">
                <div class="selectBox__output" @click="selectorshow = !selectorshow"
                    v-bind:class="[selectorshow ? 'open' : '']">
                    <span class="pt-1">{{ selected }}</span>
                    <div class="position-absolute" style="right: 0;" >
                        <img v-bind:src=arrowImg alt="">
                    </div>
                </div>
                <transition name="accodion">
                    <div class="selectBox__selector" v-show="selectorshow">
                        <div class="selectBox__selectorItem text-md-start" v-for="selector in samePrefClinic"
                            v-on:click="selected = selector.name, selectorshow = !selectorshow, updateContactClinic(selected)"
                            v-bind:key="selector.name">{{ selector.name }}
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default ({
    data() {
        return {
            arrowImg: require("@/assets/images/pc/contact_select_arrow.png"),
            selectorshow: false,
            gender: "",
            selected: "ご利用院をお選びください",
            contactContentError: '※ お問合せ内容は必須です',
            apiBaseURL: this.$store.state.apiBaseURL,
            ladiesURL: this.$store.state.ladiesURL,
            nameAndPrefURL: this.$store.state.nameAndPrefURL,
            mensURL: this.$store.state.mensURL,
            authParam: this.$store.state.authParam,
            clinicList: "",
            prefshow: false,
            ladiesPrefData: this.$store.state.ladiesPrefData,
            mensPrefData: this.$store.state.mensPrefData,
            prefData: this.gender === 'エミナルクリニック' ? this.ladiesPrefData : this.mensPrefData,
            pref: "",
            prefEng: "",
            samePrefClinic: "",

        }
    },
    computed: {
        setPrefData() {
            return this.gender === 'エミナルクリニック' ? this.ladiesPrefData : this.mensPrefData
        }
    },
    methods: {
        async viewClinic() {
            if (this.gender === 'エミナルクリニック') {
                await axios
                    .get(this.apiBaseURL + this.ladiesURL + this.nameAndPrefURL + this.authParam)
                    .then(response => (this.clinicList = response.data))
            } else if (this.gender === 'メンズエミナル') {
                await axios
                    .get(this.apiBaseURL + this.mensURL + this.nameAndPrefURL + this.authParam)
                    .then(response => (this.clinicList = response.data))
            }
        },
        filteringClinic() {
            const searchTargetPref = this.pref

            let targetPref
            if (this.gender === 'エミナルクリニック') {
                targetPref = this.ladiesPrefData.filter(function (pref) {
                return pref.name === searchTargetPref
            })[0].name_eng
            } else if (this.gender === 'メンズエミナル') {
                targetPref = this.mensPrefData.filter(function (pref) {
                return pref.name === searchTargetPref
            })[0].name_eng
            }

            this.samePrefClinic = this.clinicList.filter(function (clinic) {
                return clinic.pref_eng === targetPref
            })

        },
        updateContactGenderClinic(e) {
            this.$store.commit('updateContactGenderClinic', e.target.value)
        },
        updateContactPref(e) {
            this.$store.commit('updateContactPref', e.target.value)
        },
        updateContactClinic(selected) {
            this.$store.commit('updateContactClinic', selected)
        },
    }
})
</script>

<style scoped>
select {
    word-wrap: normal;
    width: 50.5%;
    padding-left: 2%;
    border-radius: 5px;
    height: 40px;
    border-color: #ccc;
    color: #464646;
    background-image: url('../../../../assets/images/pc/contact_select_arrow.png');
    background-position: right 1px center;
    background-repeat: no-repeat;
    background-size: 9%;
    appearance: none;/* デフォルトの矢印を無効 */
}

label {
    position: relative;
    cursor: pointer;
    padding-left: 40px;;
}

label::before,
label::after {
    content: "";
    display: block;
    border-radius: 50%;
    position: absolute;
    transform: translateY(-50%);
    top: 50%
}

label::before {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    left: 5px;
}

label::after {
    background-color: white;
    border-radius: 50%;
    opacity: 0%;
    width: 14px;
    height: 14px;
    left: 10.6px;
}

input:checked + label::after {
    background-color: white;
    opacity: 1;
}

input:checked+label::before {
    background-color: #c3a983;
}

.radio {
    position: absolute;
    white-space: nowrap;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
}

.selectBox {
    position: relative;
    width: 10em;
    height: 60px;
}

.selectBox select {
    position: absolute;
    left: 100%;
    top: 100%;
    width: 100%;
    height: 100%;
}

.selectBox__output {
    display: flex;
    align-items: center;
    position: relative;
    width: 300%;
    height: 70%;
    padding: 1em;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 5px;
    z-index: 2;
}

.selectBox__output::after {
    display: block;
    position: absolute;
    right: 3%;
    top: 50%;
}

.selectBox__output.open::after {
    transform: translateY(-50%) rotate(90deg);
}

.selectBox__selector {
    position: absolute;
    left: 0;
    /* top: calc(100% - 1px); */
    width: 300%;
    border: 1px solid #ccc;
    background-color: #fff;
    transform-origin: left top;
    z-index: 10;
}

.selectBox__selectorItem {
    width: 100%;
    padding: .75em;
    text-align: left;
}

.selectBox__selectorItem+.selectBox__selectorItem {
    border-top: 1px solid #ccc;
}

.selectBox__selectorItem:hover {
    background-color: #FAF4EC;
}

@media screen and (max-width: 766px) {
    .radio-element {
        text-align: left;
        margin-left: 5%;
    }
    select {
        width: 100%;
        padding-left: 5%;
        background-size: 12%;
        background-color: white;
    }
    .selectBox {
        width: 33.5%;
    }
}
</style>
