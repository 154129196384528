import store from "@/store"

const updateRefmedia = (refmedia) => {

    if (refmedia != undefined) {
        store.commit('updateRefmedia', refmedia)
    } else {
        store.commit('updateRefmedia', '')
    }
}

export default updateRefmedia
