<template>
    <div class="content-top">
        <div class="page-title">
            <h1>{{ japaneseMenu }}</h1>
                <img class="" v-bind:src="this.$imgTitleBar"/>
            <h2>{{ englishMenu }}</h2>
        </div>
        <removal-menu class="mt-5" v-bind:refmedia="refmedia"></removal-menu>
        <face-menu v-bind:refmedia="refmedia"></face-menu>
        <other-menu v-bind:refmedia="refmedia"></other-menu>
    </div>
</template>

<script>
import RemovalMenu from './RemovalMenuComponent.vue'
import FaceMenu from './FaceMenuComponent.vue'
import OtherMenu from './OtherMenuComponent.vue'
    export default ({
        data() {
            return {
                japaneseMenu: "診療メニュー",
                englishMenu: "MENU",
                refmedia: this.$store.state.refmedia
            }
        },
        created() {
            const refmedia = this.$route.query.refmedia
            if (refmedia != null) {
                this.$store.commit('updateRefmedia', refmedia)
            }
        },
        components: {
            RemovalMenu,
            FaceMenu,
            OtherMenu,
        }
    });
</script>

<style scoped>

    .page-title {
        /* margin-top: 100px; */
        text-align: center;
    }

    .page-title h1 {
        font-family: "source-han-sans-japanese", sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 36px;
        color: #2c2c2c;
    }
/*
    .page-title h2 {
        font-family: "din-2014",sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 32px;
        color: #2c2c2c;
    } */

    .menus {
        display: flex;
        justify-content: center;
        gap: 0 5%;
    }
    @media screen and (max-width: 1024px) {
        .menus {
            flex-direction: column;
            align-items: center;
        }

    }

    .detail-img-padding,
    .detail-aga-padding {
        padding-right: 7px;
    }

    .detail-aga-padding {
        padding-top: 30%;
    }

    @media screen and (max-width: 767px) {

        .diagnosis-menu {
            margin: 110px 0 10%;
        }

        .diagnosis-menu h1 {
            font-size: 28px;
            margin-bottom: 20px;
        }

        .diagnosis-menu h2 {
            font-size: 24px;
        }

    }
    @media screen and (max-width: 599px) {
        .ladies-menu div h5  {
            line-height: 15px;
        }
        .mens-menu div h5 {
            line-height: 15px;
        }
        .ladies-removal h5,
        .ladies-hifu h5 {
            top: 51%;
        }

    }

    </style>
