<template>
    <div class="content-top">
        <div class="page-title">
            <h1>{{ japaneseInfo }}</h1>
            <img class="" v-bind:src="this.$imgTitleBar" />
            <h2>{{ englishInfo }}</h2>
        </div>
        <div class="w-75 mx-auto mt-5 info">
            <router-link
                :to="{
                    name: 'detailInfo',
                    params: {
                        id: info.id,
                        date: info.date,
                        title: info.title,
                        content: info.content,
                    },
                }"
                v-for="info of getItems"
                :key="info.id"
                class="d-block ul-underline mt-4"
            >
                <div class="info-list d-flex justify-content-between">
                    <span style="margin-left: 5%">{{
                        info.created_at.split("T")[0].replace(/-/g, ".")
                    }}</span>
                    <div class="w-75 title">
                        <p class="pb-3 ml-md-5 text-decoration-none">
                            {{ info.title }}
                        </p>
                    </div>
                </div>
            </router-link>
            <paginate
                class="mt-3"
                :page-count="getPageCount"
                :click-handler="clickCallback"
                :prev-text="'＜'"
                :next-text="'＞'"
                :next-link-class="'page-link'"
                :prev-link-class="'page-link'"
                :margin-pages="1"
                :page-range="3"
                :container-class="'pagination'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'prev-item'"
            >
            </paginate>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import Paginate from "vuejs-paginate";
import axios from "axios";
Vue.component("paginate", Paginate);
export default {
    data() {
        return {
            japaneseInfo: "お知らせ",
            englishInfo: "INFORMATION",
            infoData: "",
            perPage: 5,
            currentPage: 1,
        };
    },
    created() {
        if (!this.infoData) {
            axios
                .get(
                    "https://orizo-test.heteml.net/eminal-group-backend/api/fetchInfo/" +
                        this.$store.state.authParam
                )
                .then((response) => (this.infoData = response.data))
                .catch((err) => alert(err));

            this.$store.state.infoData = this.infoData;
        }
    },
    computed: {
        reverseInfos() {
            return this.infoData.slice().reverse();
        },
        getItems() {
            let current = this.currentPage * this.perPage; // 1ページ目 * １ページあたり5件 = 6
            let start = current - this.perPage; // 6 - １ページあたり5件 = 1
            return this.infoData.slice(start, current); // お知らせ配列のインデックス１〜６を取り出す
        },
        getPageCount() {
            return Math.ceil(this.infoData.length / this.perPage);
        },
    },

    methods: {
        clickCallback(pageNum) {
            this.currentPage = Number(pageNum);
        },
    },
    filters: {
        // 3点リーダー入れたい時用
        omittedText(text) {
            return text.length > 36 ? text.slice(0, 36) + "..." : text;
        },
    },
};
</script>

<style scoped>
.ul-underline {
    border-bottom: 1px solid #c3a983;
}
.info-list {
    font-size: 16pt;
}

.info-list::after {
    display: inline-block;
    content: "";
    width: 15px;
    height: 15px;
    margin: -4px 0 0 0;
    border-top: solid 1px #c4a986;
    border-right: solid 1px #c4a986;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-top: 15px;
}

.info-list span {
    color: #c3a983;
    font-size: 17px;
    font-family: "Noto Sans Japanese", sans-serif;
}

.info-list p {
    /* margin-right: 30%; */
    color: #333333;
}

p {
    text-align: start;
}
a {
    text-decoration: none;
}

a:hover {
    opacity: 0.5;
}

@media screen and (max-width: 767px) {
    .info {
        width: 100% !important;
        position: relative;
    }
    .info-list {
        width: 100%;
        flex-direction: column !important;
        margin-left: 5%;
        text-align: left;
    }

    .info-list::after {
        position: absolute;
        right: 30px;
        margin: 15% 0;
    }

    .title {
        margin: 5% 20% 0 auto;
        width: 70% !important;
        font-size: 12pt;
        margin-left: 20px;
    }
}
</style>

<style>
/* bootstrapで設定されている値を上書きたい */
.page-link,
.page-link:hover {
    color: #c7af8d !important;
}

.page-item.active > a,
.page-link:hover {
    color: white !important;
}
.page-link.active,
.active > .page-link {
    background-color: #c7af8d !important;
    border-color: #c7af8d !important;
}
</style>
