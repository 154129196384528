<template>
    <div>
        <header-component></header-component>
        <header-nav-component v-if="width > 767"></header-nav-component>
        <hamburger-component v-else></hamburger-component>
        <div class="content-top">
            <div class="page-title">
                <h1>{{ japaneseContactConfirm }}</h1>
                <img class="" v-bind:src="this.$imgTitleBar" />
                <h2>{{ englishContactConfirm }}</h2>
            </div>
            <div class="mt-5 pt-5" v-if="width > 767">
                <h3 class="mb-5">{{ formConfirm }}</h3>
                <table class="tbl01 gold">
                    <tbody v-for="columnName in contactColumnList" :key="columnName">
                        <tr style="height: 110px;">
                            <th>
                                <div class="d-flex justify-content-between position-relative">
                                    <span class="mt-1">{{ columnName }}</span>
                                </div>
                            </th>
                            <td v-if="columnName === '会員番号'">{{ memberNumber }}</td>
                            <td v-if="columnName === 'お名前'">{{ name }}</td>
                            <td v-if="columnName === 'フリガナ'">{{ nameFurigana }}</td>
                            <td v-if="columnName === '郵便番号'">{{ postcode1 }}-{{ postcode2 }}</td>
                            <td v-if="columnName === 'ご住所'">{{ address }}</td>
                            <td v-if="columnName === '電話番号'">{{ tel }}</td>
                            <td v-if="columnName === 'Eメールアドレス'">{{ mail }}</td>
                            <!-- お問い合わせ内容をそれぞれ同じ枠で表示させる必要があるため、値は繰り返し分できず -->
                            <td v-if="columnName === 'お問い合わせ内容'">{{ contactContent }}<br>{{ contactGenderClinic }}<br>{{ contactPref }}<br>{{ contactClinic }}</td>
                            <td v-if="columnName === 'お問い合わせ詳細'">{{ contactDetail }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="mx-2" v-else>
                <h3 class="mb-5 mt-5">{{ formConfirm }}</h3>
                <div v-for="columnName in contactColumnList" :key="columnName">
                    <div class="d-flex justify-content-between position-relative p-3 rounded" style="background: #FAF4EC;">
                        <span class="mt-1 sp-input-column">{{ columnName }}</span>
                    </div>
                    <p class="text-start p-3" >
                        <span v-if="columnName === '会員番号'">{{ memberNumber }}</span>
                        <span v-if="columnName === 'お名前'">{{ name }}</span>
                        <span v-if="columnName === 'フリガナ'">{{ nameFurigana }}</span>
                        <span v-if="columnName === '郵便番号'">{{ postcode1 }}-{{ postcode2 }}</span>
                        <span v-if="columnName === 'ご住所'">{{ address }}</span>
                        <span v-if="columnName === '電話番号'">{{ tel }}</span>
                        <span v-if="columnName === 'Eメールアドレス'">{{ mail }}</span>
                        <span v-if="columnName === 'お問い合わせ内容'">{{ contactContent }}<br>{{ contactGenderClinic }}<br>{{ contactPref }}<br>{{ contactClinic }}</span>
                        <span v-if="columnName === 'お問い合わせ詳細'">{{ contactDetail }}</span>
                    </p>
                </div>
            </div>
            <div class="d-flex justify-content-center mt-5 pt-md-5 privacy">
            <input type="checkbox" id="privacy-check" style="color: '#A27A40'" @change="check()" />
                <label for="privacy-check" class="privacy" style="">
                    <router-link to="/privacy">プライバシーポリシー</router-link>に同意する
                </label>
            </div>
            <div class="mt-md-5 button">
                <p v-if="this.errorAPI" class="text-danger">問い合わせに失敗しました。内容を修正し、再度お試しください</p>
                <router-link-component  class="me-5" link="contact" :linkTxt="modifyButtonSvg" v-if="width > 767"></router-link-component>
                <router-link-component link="contact" :linkTxt="spModifyButtonSvg" v-else></router-link-component>
                <a class="send-button" v-html="sendButtonSvg" v-bind:class=" { opacity: !isCheck }"
                    @click="registContact" v-if="width > 767"></a>
                <a class="send-button" v-html="spSendButtonSvg" v-bind:class=" { opacity: !isCheck }"
                    @touchstart="registContact" v-else></a>
            </div>
        </div>
        <contact-component></contact-component>
        <footer-component></footer-component>
    </div>
</template>

<script>
import Vue from 'vue'
import HeaderComponent from '@/components/common/HeaderComponent'
import HeaderNavComponent from '@/components/common/HeaderNavComponent'
import HamburgerComponent from '@/components/common/HamburgerComponent'
import ContactComponent from '@/components/common/ContactComponent'
import FooterComponent from '@/components/common/FooterComponent'
import axios from 'axios'
import router from '../../router'
import RouterLink from '@/components/common/RouterLink'
import { mapState } from 'vuex'
Vue.component('header-component', HeaderComponent)
Vue.component('header-nav-component', HeaderNavComponent)
Vue.component('hamburger-component', HamburgerComponent)
Vue.component('contact-component', ContactComponent)
Vue.component('footer-component', FooterComponent)
Vue.component('router-link-component', RouterLink)
export default {
    data() {
        return {
            japaneseContactConfirm: "お問い合せ",
            englishContactConfirm: "CONTACT",
            formConfirm: "お問い合せ確認",
            modifyButtonSvg: '<svg xmlns="http://www.w3.org/2000/svg" width="260" height="50" viewBox="0 0 260 50"><g id = "修正する_btn" transform = "translate(-330 -1274)"><g id = "長方形_3218" data-name="長方形 3218" transform = "translate(330 1274)" fill = "#fff" stroke = "#a27a40" stroke-width="1" ><rect width="260" height="50" rx="25" stroke="none"/><rect x="0.5" y="0.5" width="259" height="49" rx="24.5" fill="none"/></g><text id="修正する" transform="translate(460 1306)" fill="#a27a40" font-size="15" font-family="SourceHanSansJP-Medium, Source Han Sans JP" font-weight="500"><tspan x="-30" y="0">修正</tspan><tspan y="0">する</tspan></text><path id="多角形_131" data-name="多角形 131" d="M7.257,3.1a2,2,0,0,1,3.486,0l5.58,9.921A2,2,0,0,1,14.58,16H3.42a2,2,0,0,1-1.743-2.981Z" transform="translate(574 1290) rotate(90)" fill="#a27a40"/></g></svg>',
            spModifyButtonSvg: '<svg xmlns="http://www.w3.org/2000/svg" width="330" height="80" viewBox="0 0 553 80"><g id="修正する_btn" transform="translate(-98.25 -2544)" ><g id="長方形_4921" data-name="長方形 4921" transform="translate(98.25 2544)" fill="#fff" stroke="#c3a983" stroke-width="2"><rect width="553" height="80" rx="38" stroke="none"/><rect x="1" y="1" width="551" height="78" rx="37" fill="none"/></g><text id="修正する" transform="translate(319.25 2596)" fill="#c3a983" font-size="28"  font-family="Noto Sans Japanese, sans-serif" font-weight="700"><tspan x="0" y="0">修正</tspan><tspan y="0">する</tspan></text><path id="多角形_335" data-name="多角形 335" d="M11.382,4.674a3,3,0,0,1,5.235,0L25.5,20.534A3,3,0,0,1,22.882,25H5.118A3,3,0,0,1,2.5,20.534Z" transform="translate(603.75 2569) rotate(90)" fill="#c3a983"/></g></svg>',
            sendButtonSvg: '<svg class="send-button" xmlns="http://www.w3.org/2000/svg" width="260" height="50" viewBox="0 0 260 50"><g id="送信する_btn" transform="translate(-610 -1274)" ><g id="長方形_3219" data-name="長方形 3219" transform="translate(610 1274)" fill="#c3a983"stroke="#c3a983" stroke-width="1"><rect width="260" height="50" rx="25" stroke="none" /><rect x="0.5" y="0.5" width="259" height="49" rx="24.5" fill="none" /></g><text id="送信する" transform="translate(740 1306)" fill="#fff" font-size="15"font-family="SourceHanSansJP-Medium, Source Han Sans JP" font-weight="500"><tspan x="-30" y="0">送信</tspan><tspan y="0">する</tspan></text><path id="多角形_132" data-name="多角形 132"d="M7.257,3.1a2,2,0,0,1,3.486,0l5.58,9.921A2,2,0,0,1,14.58,16H3.42a2,2,0,0,1-1.743-2.981Z"transform="translate(854 1290) rotate(90)" fill="#fff" /></g></svg>',
            spSendButtonSvg: '<svg xmlns="http://www.w3.org/2000/svg" width="330" height="80" viewBox="0 0 553 80"><g id="送信する_btn" transform="translate(-98.25 -2644)" ><rect id="長方形_4920" data-name="長方形 4920" width="553" height="80" rx="38" transform="translate(98.25 2644)" fill="#c3a983"/><text id="送信する" transform="translate(319.25 2696)" fill="#fff" font-size="28"  font-family="Noto Sans Japanese, sans-serif" font-weight="700"><tspan x="0" y="0">送信</tspan><tspan y="0">する</tspan></text><path id="多角形_334" data-name="多角形 334" d="M11.382,4.674a3,3,0,0,1,5.235,0L25.5,20.534A3,3,0,0,1,22.882,25H5.118A3,3,0,0,1,2.5,20.534Z" transform="translate(603.75 2669) rotate(90)" fill="#fff"/></g></svg>',
            sendButtonImg: require("@/assets/images/sp/contact_send_btn.png"),

            apiBaseURL: this.$store.state.apiBaseURL,
            contactRegistURL: this.$store.state.contactRegistURL,
            authParam: this.$store.state.authParam,
            isCheck: false,
            resultAPI: "",
            errorAPI: "",
            width: this.$store.state.width,
            contactColumnList: this.$store.state.contactColumnList,
            columnValueList: []
        }
    },
    computed: {
        ...mapState({
            memberNumber: "memberNumber",
            name: "name",
            nameFurigana:"nameFurigana",
            mail: "mail",
            tel: "tel",
            postcode1: "postcode1",
            postcode2: "postcode2",
            address: "address",
            contactContent: "contactContent",
            contactGenderClinic: "contactGenderClinic",
            contactPref: "contactPref",
            contactClinic: "contactClinic",
            contactDetail: "contactDetail",
        })
    },
    mounted() {
        this.columnValueList.push([
            this.memberNumber,
            this.name,
            this.nameFurigana,
            this.mail,
            this.tel,
            this.postcode1,
            this.postcode2,
            this.address,
            this.contactContent,
            this.contactGenderClinic,
            this.contactPref,
            this.contactClinic,
            this.contactDetail
        ])

    },
    methods: {
        async registContact() {
            await axios
                .get("https://orizo-test.heteml.net/eminal-group-backend/api/" + this.contactRegistURL + this.authParam + "&name=" + this.name + "&nameFurigana=" + this.nameFurigana + "&company=" + this.company + "&mail=" + this.mail + "&tel=" + this.tel + "&postcode1=" + this.postcode1 + "&postcode2=" + this.postcode2 + "&address=" + this.address + "&contactContent=" + this.contactContent + "&contactGenderClinic=" + this.contactGenderClinic + "&contactPref=" + this.contactPref + "&contactClinic=" + this.contactClinic + "&contactDetail=" + this.contactDetail)
                .then(response => (this.$store.state.resultAPI = response))
                .catch(err => (alert(err)))

                this.resultAPI = this.$store.state.resultAPI.data

            if (this.resultAPI === 'success') {

                if (this.$route.query.refmedia != null) {
                    router.push({ name:'complete',  query: { refmedia: this.$route.query.refmedia }})
                } else {
                    router.push({ name:'complete'})
                }
            }
            else {
                this.errorAPI = this.resultAPI
            }
        },
        check() {
            return this.isCheck = !this.isCheck
        }
    },
    head: {
        title: {
            inner: 'エミナルグループ総合サイト',
            separator: '|',
            complement: 'お問い合わせ確認'
        },
    },
    components: {
        HeaderComponent,
        HeaderNavComponent,
        HamburgerComponent,
        ContactComponent,
        FooterComponent,
    }
}
</script>

<!-- テーブルスタイル -->
<style scoped>

input[type="checkbox"] {
    display: none;
}

input[type="checkbox"]+label {
    display: none;
    cursor: pointer;
    display: inline-block;
    position:relative;
    padding: 0 25px 0 10px;
}

input[type="checkbox"]+label::before {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    right: 100%;
    top: 50%;
    border: 1px solid #A27A40;
    border-radius: 5px;
    background-color: #FFF; /* 背景の色変更 お好きな色を */
}

input[type="checkbox"]:checked+label::after {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 18px;
    height: 9px;
    margin-top: -9px;
    top: 50%;
    right: 99%;
    transform: rotate(-45deg);
    border-bottom: 3px solid;
    border-left: 3px solid;
    border-color:  #585753; /* チェックの色変更 お好きな色を */
}

.privacy,
a  {
    cursor: pointer;
    font-size: 15pt;
    color: #A27A40;
    font-weight: 500;
}

tr {
    height: 110px;
}
.opacity {
    opacity: .5;
    pointer-events: none;
}
.required {
    font-size: 11px;
    font-weight: normal;
    background-color: #C3A983;
    padding: 6px 5px;
}
/* ▼TABLE▼ */
.tbl01 {
    width: 1200px;
}

.tbl01 th, .tbl01 td {
	padding: 20px;
	vertical-align: middle;
	line-height: 1.65;
}

.tbl01 th {
	font-weight: bold;
}

.tbl01.gold th, .tbl01.gold td {
    text-align: start;
	border: solid 1px #CCCCCC;
    font-size: 16px;
}

.tbl01.gold th {
	color: #A27A40;
	background: #FAF4EC;
	width: 17%;
    text-align: start;
}

.button a {
    text-decoration: none;
}

@media screen and (max-width: 767px) {
    .privacy {
        margin-left: 5%;
    }

    input[type="checkbox"]+label {
        transform: scale(0.8);
    }

    .send-button {
        display: block;
    }
}
</style>

