<template>
    <div class="container mt-5 pt-5">
        <h3 class="text-center mt-5">{{ titleCommitment }}</h3>
        <img class="" v-bind:src="img.line" />
        <div class="w-100">
            <div class="container mt-md-5 pt-md-4">
                <div class="row mt-5">
                    <div class="col-md-7 px-md-5 mt-4 point-padding">
                        <p class="text-start mb-5 headline_text"><strong> {{ point1 }}<br><span>{{ headlineCommitment1 }}</span></strong></p>
                        <p class="text-start commitment-text">
                            {{ paragraphCommitment1_1 }}
                        </p>
                        <br>
                        <p class="text-start commitment-text">{{ paragraphCommitment1_2 }}</p>
                    </div>
                    <div class="col-md-4 px-md-5 mt-md-4 point-padding">
                        <img v-bind:src="img.machine" alt="" class="img-fuied" >
                    </div>
                </div>
            </div>
            <div class="container mt-md-5 pt-md-4">
                <div class="row mt-5">
                    <div class="col-md-7 px-5 mt-4 point-padding">
                        <p class="text-start mb-5 headline_text"><strong>{{ point2 }}<br><span>{{ headlineCommitment2
                                }}</span></strong></p>
                        <p class="text-start commitment-text">
                            {{ paragraphCommitment2_1 }}
                        </p>
                        <br>
                        <p class="text-start commitment-text">{{ paragraphCommitment2_2 }}</p>
                        <br>
                        <p class="text-start commitment-text">{{ paragraphCommitment2_3 }}</p>
                    </div>
                    <div class="col-md-5 px-5 mt-4 point-padding">
                        <img v-bind:src="img.doctor" alt="" class="img-fuied">
                    </div>
                </div>
            </div>
            <div class="container mt-md-5 pt-md-4">
                <div class="row mt-5">
                    <div class="col-md-7 px-5 mt-4 point-padding">
                        <p class="text-start mb-5 headline_text"><strong> {{ point3 }}<br><span>{{ headlineCommitment3
                                }}</span></strong></p>
                        <p class="text-start commitment-text">
                            {{ paragraphCommitment3_1 }}
                        </p>
                        <br>
                        <p class="text-start commitment-text">{{ paragraphCommitment3_2 }}</p>
                        <br>
                        <p class="text-start commitment-text">{{ paragraphCommitment3_3 }}</p>
                    </div>
                    <div class="col-md-4 px-5 mt-4 point-padding">
                        <img v-bind:src="img.skin" alt="" class="img-fuied">
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default({
        data() {
            return {
                titleCommitment: "医療機関としてのこだわり",
                point1: "point1",
                paragraphCommitment1_1: "エミナルクリニックでは、一度に強いパワーで照射する脱毛器ではなく、連続で照射することで痛みを軽減できるエミナルクリニックオリジナルのダイオードレーザー脱毛器を採用しています。",
                paragraphCommitment1_2: "幅広い毛質・肌質に対応していて、痛みや肌への負担も最小限に抑えることができ、より安全な脱毛方法をご提案致します。",
                headlineCommitment1: "痛みが少ない脱毛器を導入しております",
                point2: "point2",
                headlineCommitment2: "医師による安心のカウンセリング",
                paragraphCommitment2_1: "脱毛の専門知識を持った医師が患者様のムダ毛の悩みを解決するために、親身に対応いたします。",
                paragraphCommitment2_2: "初めての脱毛で、不安な気持ちでご来院される患者様も多くいらっしゃいますが、エミナルでは脱毛のメリットだけではなく脱毛による副作用もしっかり説明させていただき、納得して脱毛施術が受けられるよう努めてまいります。",
                paragraphCommitment2_3: "脱毛のメカニズムだけでなく、通院期間や解約方法、脱毛の痛みなど気になることはなんでもお気軽にご相談ください。",
                point3: "point3",
                headlineCommitment3: "肌トラブルの対応",
                paragraphCommitment3_1: "細心の注意を持って施術をおこないますが、脱毛には副作用や肌トラブルが起こるリスクがあります。",
                paragraphCommitment3_2: "一般的に知られている火傷リスクの他にも、レーザーを照射することで、硬い毛が生えてきてしまう硬毛化や、毛が増えてしまう増毛化などが起こる可能性があります。",
                paragraphCommitment3_3: "エミナルでは、責任をもって素早く対応させていただきますので、万が一、副作用や肌トラブルが起きた際にはご連絡ください。もちろんその場合の診療費やお薬代などは一切いただきませんのでご安心ください。",
                img: {
                    line: require("@/assets/images/pc/philosophy_line.png"),
                    machine: require("@/assets/images/pc/philosophy_machine.jpg"),
                    spMachine: require("@/assets/images/sp/philosophy_machine.jpg"),
                    doctor: require("@/assets/images/pc/philosophy_doctor.jpg"),
                    skin: require("@/assets/images/pc/philosophy_skin.jpg")
                }
            }
        }
    });
</script>

<style scoped>
.commitment-text {
    font-size: 15px;
}

.headline_text {
    font-size: 26px;
}

strong {
    font-family: 'Noto Sans Japanese', 'sans-serif';
}

.container:nth-child(2) .row {
    flex-direction: row-reverse;
}

    @media screen and (max-width: 767px) {
        .point-padding {
            padding: 0 !important;
        }

        .headline_text  span {
            font-size: 17px;
        }

        .headline_text,
        strong {
            font-family: 'Noto Sans Japanese', sans-serif;
        }

        .img-fuied {
            width: 350px;
            height: 300px;
        }
    }
</style>



