<template>
    <div class="header">
        <div class="openbtn1" @click="open = !open" v-bind:class="{ active : open }">
            <span></span><span></span><span></span>
        </div>
        <div class="header-logo">
            <img class="header_img01" v-bind:src="imageEminalLogo" alt="エミナルクリニック" />
            <img class="header_img01" v-bind:src="imageMensEminalLogo" alt="エミナルクリニックメンズ" />
        </div>
        <nav id="g-nav" v-bind:class="{ panelactive : open }">
            <div id="g-nav-list">
                <!--ナビの数が増えた場合縦スクロールするためのdiv※不要なら削除-->
                <ul>
                    <li>
                        <a v-bind:href="topURL"><span>{{ japaneseTop }}</span></a>
                    </li>
                    <li>
                        <router-link-component link="philosophy" v-bind:linkTxt="japanesePhilosophy"></router-link-component>
                    </li>
                    <li>
                        <router-link-component link="menu" v-bind:linkTxt="japaneseMenu"></router-link-component>
                    </li>
                    <li>
                        <router-link-component link="clinics" v-bind:linkTxt="japanesehClinic"></router-link-component>
                    </li>
                    <li>
                        <router-link-component link="faq" v-bind:linkTxt="japanesehFaq"></router-link-component>
                    </li>
                    <li>
                        <router-link-component link="info" v-bind:linkTxt="japaneseInfo"></router-link-component>
                    </li>
                    <li>
                        <router-link-component link="contact" v-bind:linkTxt="japaneseContact"></router-link-component>
                    </li>
                    <li>

                        <router-link-component class="privacy-hamburger-link" link="privacy" linkTxt="法人概要、プライバシーポリシー"></router-link-component>
                    </li>
                    <li>
                        <a v-bind:href="recruitURL"><span>{{ japaneseRecruit }}</span></a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Slide } from 'vue-burger-menu';
    // import RouterLink from '@/components/common/RouterLink';
    Vue.component('slide', Slide);
    // Vue.component('router-link-component', RouterLink)

    export default ({
        data() {
            return {
                imageEminalLogo: require('@/assets/images/pc/eminal_logo.svg'),
                imageEminalLogoSp: require('@/assets/images/sp/eminal_logo.png'),
                imageMensEminalLogo: require('@/assets/images/pc/mens_logo.svg'),
                imageMensEminalLogoSp: require('@/assets/images/sp/mens_logo.png'),
                open: false,
                japaneseTop: "トップ",
                japanesePhilosophy: "企業理念",
                japaneseMenu: "診療メニュー",
                japanesehClinic: "クリニック一覧",
                japanesehFaq: "よくある質問",
                japaneseInfo: "お知らせ",
                japaneseContact: "お問い合わせ",
                japaneseCompany: "法人概要、<br>",
                japanesePrivacy: "プライバシーポリシー",
                japaneseRecruit: "採用情報",
                urlMenuId: '/menu',
                recruitURL: this.$store.state.recruitURL,
                topURL: '/',
            }
        },
        created() {
            if (this.$route.query.refmedia != null) {
                this.recruitURL += "?refmedia=" + this.$route.query.refmedia
            }
        },
    })
</script>

<style>
    .privacy-hamburger-link {
        width: 130%;
        margin-left: -10%;
    }
</style>

<style scoped>
#g-nav {
    position: fixed;
    z-index: 999;
    top: 0;
    right: -120%;
    width: 100%;
    height: 100vh;
    background: #C3A983;
    transition: all 0.6s;
}
#g-nav.panelactive {
    right: 0;
}
#g-nav.panelactive #g-nav-list {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

#g-nav ul {
    position: absolute;
    z-index: 999;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#g-nav li {
    list-style: none;
    text-align: center;
}

#g-nav li a {
    color: #fff;
    text-decoration: none;
    padding: 10px;
    display: block;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: bold;
}
.openbtn1 {
    position: fixed;
    z-index: 9999;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 50px;
    height: 50px;
}

.openbtn1 span {
    display: inline-block;
    transition: all .4s;
    position: absolute;
    left: 14px;
    height: 3px;
    border-radius: 2px;
    background-color: #666;
    width: 45%;
}

.openbtn1 span:nth-of-type(1) {
    top: 15px;
}

.openbtn1 span:nth-of-type(2) {
    top: 23px;
}

.openbtn1 span:nth-of-type(3) {
    top: 31px;
}

.openbtn1.active span:nth-of-type(1) {
    top: 18px;
    left: 18px;
    transform: translateY(6px) rotate(-45deg);
    width: 30%;
}

.openbtn1.active span:nth-of-type(2) {
    opacity: 0;
}

.openbtn1.active span:nth-of-type(3) {
    top: 30px;
    left: 18px;
    transform: translateY(-6px) rotate(45deg);
    width: 30%;
}

@media screen and (max-width: 1050px) {
    .header {
        margin-left: min(1.3vw,10px);
    }

    .header-logo {
        width: 70%;
        display: none;
    }
    
    .header_img01 {
        width: max(110px,14.6vw);
        margin-right: min(3.3vw, 25px);
    }
}

@media screen and (max-width: 350px) {
    .header-logo {
        width: 60%;
    }
}
</style>
