<template>
    <nav class="gnavi" v-bind:class="{ gnaviPosition : isView }"><!-- クラス名gnaviPositionはスネークケースだとvueに引っかかるのでキャメルケースに -->
        <div class="gnavi-inner">
            <ul>
                <li>
                    <router-link-component link="philosophy" v-bind:linkTxt="philosophy"></router-link-component>
                </li>
                <li>
                    <router-link-component link="menu" v-bind:linkTxt="menu"></router-link-component>
                </li>
                <li>
                    <router-link-component link="clinics" v-bind:linkTxt="clinic"></router-link-component>
                </li>
                <li>
                    <router-link-component link="contact" v-bind:linkTxt="contact"></router-link-component>
                </li>
                <li><a v-bind:href="recruitURL" target="_blank"><span>{{ recruit }}</span></a></li>
            </ul>
        </div>
    </nav>
</template>

<script>
    import Vue from 'vue'
    import RouterLink from '@/components/common/RouterLink'
    Vue.component('router-link-component', RouterLink)
    export default {
        data() {
            return {
                isView: false, // 初期値をtrueにすると最上部に表示されてしまう
                philosophy: "企業理念",
                menu: "診療メニュー",
                clinic: "クリニック紹介",
                contact: "お問い合せ",
                recruit: "採用情報",
                urlMenuId: '/menu',
                recruitURL: this.$store.state.recruitURL,
                refmedia: this.$store.state.refmedia
            }
        },
        created() {
            const refmedia = "?refmedia=" +this.refmedia
            if (this.refmedia !== '') {
                this.recruitURL += refmedia
            }
        },
        mounted() {
            window.addEventListener("scroll", this.gnaviHeader)
        },
        destroyed() {
            window.removeEventListener('scroll', this.gnaviHeader)
        },
        methods: {
            gnaviHeader() {
                this.isView = window.scrollY > 600
            },
        },
    }
</script>

<!-- 共通コンポーネントで使用するためscopedを外す -->
<style>
    .gnavi span {
        display: flex;
        align-items: center;
        font-size: clamp(14px,1.08vw,28px);
        white-space: nowrap;
    }
</style>

<style scoped>
    .gnavi {
        margin: -2% auto 0 auto;
        width: 75%;
    }
    .gnavi ul {
        display: flex;
        background: #fff;
    }

    .gnavi li {
        flex: 1 1;
        position: relative;
        display: flex;
    }

    .gnavi li:after {
        content: "";
        display: block;
        width: 2px;
        height: 20px;
        background-color: #C3A983;
        position: absolute;
        right: -1px;
        top: calc((100% - 20px) / 2);
    }

    .gnavi li:last-child::after {
        content: none;
    }

    .gnavi a {
        display: flex;
        flex: 1 1;
        justify-content: center;
        padding: 20px;
    }

    .gnavi-inner a {
        text-decoration: none;
        font-family: 'Noto Sans Japanese', sans-serif;
        font-weight: 500;
        color: #707070;
        font-size: 1rem;
    }

    .gnavi-inner a:hover {
        color: #333333;
    }

    .gnavi-inner a::after {
        position: absolute;
        top: 40px;
        left: 35%;
        content: '';
        width: 30%;
        height: 2px;
        background: #C3A983;
        transform: scale(0, 1);
        transform-origin: left top;
        transition: transform .3s;
    }

    .gnaviPosition {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 50px;
        z-index: 999;
        background-color: white;
        margin-top: 0;
    }

    .gnaviPosition li::after {
        height: 35px;
        top: calc((100% - 27px) / 2);
    }

    a:hover::after {
        transform: scale(1, 1);
    }
    </style>
