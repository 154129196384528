<template>
    <div>
        <header-component v-if="width > 1050"></header-component>
        <hamburger-component v-else></hamburger-component>
        <main-component></main-component>
        <contact-component></contact-component>
        <footer-component></footer-component>
    </div>
</template>

<script>
import Vue from 'vue'
import HeaderComponent from './common/HeaderComponent'
import HamburgerComponent from './common/HamburgerComponent'
import MainComponent from './menu/MainComponent'

Vue.component('header-component', HeaderComponent)
Vue.component('hamburger-component', HamburgerComponent)
Vue.component('main-component', MainComponent)

export default ({
    data() {
        return {
            width: ''
        }
    },
    mounted() {
        window.addEventListener('resize', this.resizeWindow)
    },
    methods: {
        resizeWindow() {
            this.width = window.outerWidth
        }
    },
    head: {
        title: {
            inner: "エミナルグループ総合サイト",
            separator: '|',
            complement: 'メニュー'
        },
    },
    components: {
        HeaderComponent,
        HamburgerComponent,
        MainComponent
    },
    created() {
        this.width = this.$store.state.width
    }
})
</script>

<style>
    .menu-headline {
        top: 15%;
        left: 16%;
        color: #333333;
    }

    @media screen and (max-width: 767px) {
        .menu-headline {
            top: 7%;
            left: 0;
            right: 0;
        }

    }
</style>