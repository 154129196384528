<template>
    <tr v-if="width > 767">
        <th>
            <div class="d-flex justify-content-between">
                <span class="mt-1">お問合せ内容</span><span class="text-white required">必須</span>
            </div>
        </th>
        <td class="position-relative pb-1">
            <div id="select">
                <div class="selectBox">
                    <div class="selectBox__output" @click="selectorshow = !selectorshow"
                        v-bind:class="[selectorshow ? 'open' : '']">
                        <span class="pt-1">{{ selected }}</span>
                        <div class="position-absolute" style="right: 0;">
                            <img v-bind:src=arrowImg alt="">
                        </div>
                    </div>
                    <transition name="accodion">
                        <div class="selectBox__selector" v-show="selectorshow">
                            <div class="selectBox__selectorItem" v-for="selector in contactContents"
                                v-on:click="selected = selector.content, selectorshow = !selectorshow, updateContactContent(selected)"
                                v-bind:key="selector.content">{{ selector.content }}
                            </div>
                        </div>
                    </transition>
                </div>
                <clinics-component v-if="selected === '各医院に関するお問い合せ'"></clinics-component>
                <p class="text-start text-danger" v-if="!checkContent">{{ contactContentError }}</p>
            </div>
        </td>
    </tr>
    <div v-else>
        <div class="d-flex justify-content-between position-relative p-3 rounded" style="background: #FAF4EC; ">
            <span class="mt-1 sp-input-column">お問い合わせ内容</span><span class="text-white required required-column">必須</span>
        </div>
        <div class="selectBox">
            <div class="selectBox__output" @click="selectorshow = !selectorshow"
                v-bind:class="[selectorshow ? 'open' : '']">
                <span class="pt-1">{{ selected }}</span>
                <div class="position-absolute" style="right: 0;">
                    <img v-bind:src=arrowImg alt="">
                </div>
            </div>
            <transition name="accodion">
                <div class="selectBox__selector" v-show="selectorshow">
                    <div class="selectBox__selectorItem text-start" v-for="selector in contactContents"
                        v-on:click="selected = selector.content, selectorshow = !selectorshow, updateContactContent(selected)"
                        v-bind:key="selector.content">{{ selector.content }}
                    </div>
                </div>
            </transition>
        </div>
        <clinics-component v-if="selected === '各医院に関するお問い合せ'"></clinics-component>
        <p class="text-start text-danger" v-if="!checkContent">{{ contactContentError }}</p>
    </div>
</template>


<script>
import Vue from "vue"
import Clinics from './contentClinic/clinics'
Vue.component('clinics-component', Clinics)

export default ({
    data() {
        return {
            arrowImg: require("@/assets/images/pc/contact_select_arrow.png"),
            selectorshow: false,
            contactContents: [
                {
                    content: "治療内容に関するお問い合わせ"
                },
                {
                    content: "アプリに関するお問い合わせ"
                },
                {
                    content: "各医院に関するお問い合せ"
                },
                {
                    content: "企画提案・広告に関するお問い合せ"
                },
                {
                    content: "撮影や取材に関するお問い合わせ"
                },
                {
                    content: "医療法人に関するお問い合わせ"
                },
                {
                    content: "その他"
                }
            ],
            selected: "選択してください",
            contactContentError: '※ お問合せ内容は必須です',
            width: this.$store.state.width,
        }
    },
    computed: {
        contactContent: {
            get() {
                return this.$store.state.contactContent
            },
            set(v) {
                this.$store.commit('updateContactContent', v)
            }
        },
        checkContent() {
            let isContactContentPassCheck = true

            if (this.selected.length === 0) {
                isContactContentPassCheck = false
            }

            this.$emit('isContactContentPassCheck', isContactContentPassCheck)

            return isContactContentPassCheck
        },
    },
    mounted: function () {
        window.addEventListener('resize', this.headlineResize)
    },
    methods: {
        updateContactContent(e) {
            this.$store.commit('updateContactContent', e)
        },
        headlineResize() {
            this.width = window.outerWidth;
        },
    },
})
</script>

<style scoped>
select {
    word-wrap: normal;
    width: 50%;
    padding-left: 2%;
    border-radius: 5px;
    height: 40px;
    border-color: #ccc;
    color: #464646;
    background-image: url('../../../assets/images/pc/contact_select_arrow.png');
    background-position: right 1px center;
    background-repeat: no-repeat;
    background-size: 9%;
    appearance: none;/* デフォルトの矢印を無効 */
}

label {
    position: relative;
    cursor: pointer;
    padding-left: 40px;;
}

label::before,
label::after {
    content: "";
    display: block;
    border-radius: 50%;
    position: absolute;
    transform: translateY(-50%);
    top: 50%
}

label::before {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    left: 5px;
}

label::after {
    background-color: white;
    border-radius: 50%;
    opacity: 0%;
    width: 14px;
    height: 14px;
    left: 10.6px;
}

input:checked + label::after {
    background-color: white;
    opacity: 1;
}

input:checked+label::before {
    background-color: #c3a983;
}

.radio {
    position: absolute;
    white-space: nowrap;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
}

.selectBox {
    position: relative;
    width: 10em;
    height: 60px;
}

.selectBox select {
    position: absolute;
    left: 100%;
    top: 100%;
    width: 100%;
    height: 100%;
}

.selectBox__output {
    display: flex;
    align-items: center;
    position: relative;
    width: 300%;
    height: 70%;
    padding: 1em;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 5px;
    z-index: 2;
}

.selectBox__output::after {
    display: block;
    position: absolute;
    right: 3%;
    top: 50%;
}

.selectBox__output.open::after {
    transform: translateY(-50%) rotate(90deg);
}

.selectBox__selector {
    position: absolute;
    left: 0;
    /* top: calc(100% - 1px); */
    width: 300%;
    border: 1px solid #ccc;
    background-color: #fff;
    transform-origin: left top;
    z-index: 10;
}

.selectBox__selectorItem {
    width: 100%;
    padding: .75em;
    text-align: left;
}

@media screen and (max-width: 767px) {
    .selectBox {
        width: 33%;
    }
}
</style>
