<template>
    <div class="facemenu-box mt-5">
        <div class="facemenu-btn">
            <div class="facemenu-ladies">
                <div class="menu-headline">
                    <h4>{{ faceMenu }}</h4>
                </div>
                <div id="hifu" class="ladies-hifu">
                    <a v-bind:href="links.ladiesHifuLink" target="_blank" class="facemenu-detail">
                        <img v-bind:src="faceMenuList.ladiesHifuImg" alt="" v-if="width > 900" />
                        <img v-bind:src="faceMenuList.spLadiesHifuImg" class="facemenu-img-sp" alt="" v-else />
                        <div class="position-absolute text-white ladies-hifu-title">
                            <p>{{ faceMenuList.ladiesHifuMenu }}</p>
                        </div>
                    </a>

                    <div class="text-start top-100 mt-3">
                        <p class="description">{{ faceMenuList.ladiesHifuDescription }}</p>
                    </div>
                </div>
                <div id="dermapen" class="ladies-dermapen" style="">
                    <a v-bind:href="links.ladiesDermapenLink" target="_blank" class="facemenu-detail">
                        <img v-bind:src="faceMenuList.ladiesDermapenImg" alt="" v-if="width > 900" />
                        <img v-bind:src="faceMenuList.spLadiesDermapenImg" class="facemenu-img-sp" alt="" v-else />
                        <div class="position-absolute text-white ladies-dermapen-title">
                            <p>{{ faceMenuList.ladiesDermapenMenu }}</p>
                        </div>
                    </a>
                    <div class="text-start top-100 mt-3">
                        <p class="description">{{ faceMenuList.ladiesDermapenDescription }}</p>
                    </div>
                </div>
                <div id="photofacial" class="ladies-photofacial">
                    <a v-bind:href="links.ladiesPhotofacialLink" target="_blank" class="facemenu-detail">
                        <img v-bind:src="faceMenuList.ladiesPhotofacialImg" alt="" v-if="width > 900" />
                        <img v-bind:src="faceMenuList.spLadiesPhotofacialImg" class="facemenu-img-sp" alt="" v-else />
                        <div class="position-absolute text-white ladies-photofacial-title">
                            <p>{{ faceMenuList.ladiesPhotofacialMenu }}</p>
                        </div>
                    </a>

                    <div class="text-start top-100 mt-3">
                        <p class="description">{{ faceMenuList.ladiesPhotofacialDescription }}</p>
                    </div>
                </div>
            </div>
            <div class="facemenu-mens">
                <div class="mens-hifu">
                    <a v-bind:href="links.mensHifuLink" target="_blank" class="facemenu-detail">
                        <img v-bind:src="faceMenuList.mensHifuImg" alt="" v-if="width > 900" />
                        <img v-bind:src="faceMenuList.spMensHifuImg" class="facemenu-img-sp" alt="" v-else />
                        <div class="position-absolute text-white mens-hifu-title" style="">
                            <p>{{ faceMenuList.mensHifuMenu }}</p>
                        </div>
                    </a>
                    <div class="text-start top-100 mt-3">
                        <p class="description">{{ faceMenuList.mensHifuDescription }}</p>
                    </div>
                </div>

                <div class="mens-dermapen">
                    <a v-bind:href="links.mensDermapenLink" target="_blank" class="facemenu-detail">
                        <img v-bind:src="faceMenuList.mensDermapenImg" alt="" v-if="width > 900" />
                        <img v-bind:src="faceMenuList.spMensDermapenImg" class="facemenu-img-sp" alt="" v-else />

                        <div class="position-absolute text-white mens-dermapen-title" style="">
                            <p>{{ faceMenuList.mensDermapenMenu }}</p>
                        </div>
                    </a>
                    <div class="text-start top-100 mt-3">
                        <p class="description">{{ faceMenuList.mensDermapenDescription }}</p>
                    </div>
                </div>
                <div class="mens-photofacial">
                    <a v-bind:href="links.mensPhotofacialLink" target="_blank" class="facemenu-detail">
                        <img v-bind:src="faceMenuList.mensPhotofacialImg" alt="" v-if="width > 900" />
                        <img v-bind:src="faceMenuList.spMensPhotofacialImg" class="facemenu-img-sp" alt="" v-else />
                        <div class="position-absolute text-white mens-photofacial-title">
                            <p>{{ faceMenuList.mensPhotofacialMenu }}</p>
                        </div>
                    </a>

                    <div class="text-start top-100 mt-3">
                        <p class="description">{{ faceMenuList.mensPhotofacialDescription }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default({
    props: {
        'refmedia' : {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            faceMenu: "フェイスメニュー",
                faceMenuList: {
                    ladiesHifuMenu: "医療ハイフ (レディース)",
                    ladiesHifuDescription: "ショット数無制限！切らずにリフトアップが目指せるエミナルの医療ハイフ。",
                    ladiesHifuImg: require("@/assets/images/pc/menu_ladies_hifu.png"),
                    spLadiesHifuImg: require("@/assets/images/sp/menu_ladies_hifu.png"),
                    mensHifuMenu: "医療ハイフ (メンズ)",
                    mensHifuDescription: "ショット数無制限！切らずにリフトアップが目指せるメンズエミナルの医療ハイフ。",
                    mensHifuImg: require("@/assets/images/pc/menu_mens_hifu.png"), 
                    spMensHifuImg: require("@/assets/images/sp/menu_mens_hifu.png"),
                    ladiesDermapenMenu: "ダーマペン (レディース)",
                    ladiesDermapenDescription: "お肌のお悩みの改善が期待できるエミナルのエイジングケア治療",
                    ladiesDermapenImg: require("@/assets/images/pc/menu_ladies_dermapen.png"),
                    spLadiesDermapenImg: require("@/assets/images/sp/menu_ladies_dermapen.png"),
                    mensDermapenMenu: "ダーマペン (メンズ)",
                    mensDermapenDescription: "お肌のお悩みの改善が期待できるメンズエミナルのエイジングケア治療",
                    mensDermapenImg: require("@/assets/images/pc/menu_mens_dermapen.png"),
                    spMensDermapenImg: require("@/assets/images/sp/menu_mens_dermapen.png"),
                    ladiesPhotofacialMenu: "フォト美肌治療 (レディース)",
                    ladiesPhotofacialDescription: "しわ・たるみの改善が期待できるエミナルのフォト美肌治療。",
                    ladiesPhotofacialImg: require("@/assets/images/pc/menu_ladies_photofacial.png"),
                    spLadiesPhotofacialImg: require("@/assets/images/sp/menu_ladies_photofacial.png"),
                    mensPhotofacialMenu: "フォト美肌治療 (メンズ)",
                    mensPhotofacialDescription: "しわ・たるみの改善が期待できるメンズエミナルのフォト美肌治療。",
                    mensPhotofacialImg: require("@/assets/images/pc/menu_mens_photofacial.png"), 
                    spMensPhotofacialImg: require("@/assets/images/sp/menu_mens_photofacial.png"),
                },
                links: {
                    ladiesHifuLink: "https://eminal-clinic.jp/hifu/",
                    mensHifuLink: "https://mens-eminal.jp/lp/hifu.php",
                    ladiesDermapenLink: "https://eminal-group.jp/dermapen/",
                    mensDermapenLink: "https://mens-eminal.jp/dermapen_mens/",
                    ladiesPhotofacialLink: "https://eminal-clinic.jp/menu/#anc_facial",
                    mensPhotofacialLink: "https://mens-eminal.jp/menu/#anc_07/",
                },
            bigFrameImg: require("@/assets/images/pc/bigframe.jpg"),
            spBigFrameImg: require("@/assets/images/sp/bigframe.jpg"),
            width: this.$store.state.width
        }
    },
    created() {
        if (this.refmedia !== "") {
            this.links.ladiesHifuLink += "?refmedia=" + this.refmedia
            this.links.mensHifuLink += "?refmedia=" + this.refmedia
            this.links.ladiesDermapenLink += "?refmedia=" + this.refmedia
            this.links.mensDermapenLink += "?refmedia=" + this.refmedia
        }
    },
    mounted() {
        window.addEventListener('resize', this.resizeWindow)
    },
    methods: {
        resizeWindow() {
            this.width = window.outerWidth
        }
    },
})
</script>

<style scoped>

    .facemenu-ladies {
        width: 450px;
        display: block;
        border-top: 5px solid #E95A8E;
        padding: 3em 2em;
        background: linear-gradient(135deg, rgba(251,231,252,0.2) 0%, #FFF4FA 50%, rgba(255,229,244,0.1) 100%);
    }

    .facemenu-mens {
        width: 450px;
        border-top: 5px solid #707070;
        padding: 6.2em 2em 3em;
        background: linear-gradient(135deg, rgba(229,229,229,0.2) 0%, rgba(239,239,239,0.95) 50%, rgba(255,255,255,0.1) 100%);
    }

    .facemenu-btn {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .ladies-hifu,
    .mens-hifu,
    .ladies-dermapen,
    .mens-dermapen,
    .ladies-photofacial,
    .mens-photofacial {
        width:100%;
    }

    h4 {
        text-align: left;
        margin-bottom: 1em;
    }

    .facemenu-detail {
        position: relative;
    }

    .ladies-hifu-title,
    .mens-hifu-title,
    .ladies-dermapen-title,
    .mens-dermapen-title,
    .ladies-photofacial-title,
    .mens-photofacial-title {
        top: 0;
        left: 0;
        right: 45%;
        font-size: 14px;
    }

    .description {
        margin-inline: 3%;
    }

    @media screen and (max-width: 900px) {
        .facemenu-ladies,
        .facemenu-mens,
        .facemenu-box {
            width: 100%;
        }

        .facemenu-btn {
            display: block;
        }

        .ladies-hifu,
        .mens-hifu,
        .ladies-dermapen,
        .mens-dermapen {
            width:100%;
        }

        h4 {
            text-align: center;
        }

        .menu-headline {
            top: 3% !important;
        }

        .ladies-hifu-title,
        .ladies-dermapen-title,
        .ladies-photofacial-title {
            right: 48%;
        }

        .mens-hifu-title,
        .mens-dermapen-title,
        .mens-photofacial-title {
            right: 49%;
        }

        .ladies-hifu-title,
        .mens-hifu-title,
        .ladies-dermapen-title,
        .mens-dermapen-title,
        .ladies-photofacial-title,
        .mens-photofacial-title {
            font-size: clamp(12px,2.6vw,20px);
        }

        .facemenu-img-sp {
            width: 80vw;
            height: 23vw;
        }

        .description {
            margin: 2% auto 5%;
            width: 80%;
            font-size: clamp(12px,2.1vw,16px);
        }
    }
</style>
