<template>
    <div class="content-top">
        <div class="page-title">
            <h1>{{ japanesePrivacy }}</h1>
            <img class="" v-bind:src="this.$imgTitleBar"/>
            <h2 class="font-DIN">{{ englishPrivacy }}</h2>
        </div>
        <div class="sp-margin">
            <p class="text-start my-5">医療法人社団 エミナル（以下、当社と略します）では、ご利用者様から提供いただく個人情報について、その取扱いに関する重要性を認識した上で、以下の取組み及び保護活動を維持いたします。</p>
            <div class="contents" v-for="policy in privacyPolicyList" :key="policy.title">
                <h3 class="text-start">{{ policy.title }}</h3>
                <p class="text-start">{{ policy.context }}</p>
                <ul v-if="policy.purposeList">
                    <li class="text-start" v-for="purpose in policy.purposeList" :key="purpose.context">
                        {{ purpose.context }}
                    </li>
                </ul>
                <p class="text-start" v-if="policy.context2">{{ policy.context2 }}</p>
            </div>

            <div class="page-title">
                <h1>{{ japaneseCorporate }}</h1>
                <img class="" v-bind:src="this.$imgTitleBar"/>
                <h2 class="font-DIN">{{ englishCorporate }}</h2>
            </div>

            <div class="contents">
                <table class="tbl01 gold">
                    <tbody>
                        <tr>
                            <th>エミナルグループ 会長</th>
                            <td>久田拓儀</td>
                        </tr>
                    </tbody>
                </table>

                <!-- test -->
                <table class="tbl01 gold">
                    <tbody>
                        <tr>
                            <th>法人名</th>
                            <td>{{ companyName }}</td>
                        </tr>
                        <tr>
                            <th>所在地</th>
                            <td>{{ address }}</td>
                        </tr>
                        <tr>
                            <th>電話番号</th>
                            <td>{{ tel }}</td>
                        </tr>
                        <tr>
                            <th>設立年月日</th>
                            <td>{{ foundDate }}</td>
                        </tr>
                        <tr>
                            <th>事業内容</th>
                            <td>{{ buisiness }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default({
    data() {
        return {
            japanesePrivacy: "プライバシーポリシー",
            englishPrivacy: "PRIVACY POLICY",
            japaneseCorporate: "法人概要",
            englishCorporate: "CORPORATE PROFILE",
            companyName: "医療法人社団 エミナル",
            address: "〒220-0073 神奈川県横浜市西区岡野1-12-14 石川ビル4F",
            tel: "045-548-6373",
            foundDate: "平成19年3月1日",
            buisiness: "美容皮膚科の運営",
            privacyPolicyList: [
                {
                    title: "個人情報の収集について",
                    context: "当社では、ご利用者様が当社の資料を請求される場合や、商品購入などのサービスの利用を希望される際に、ご利用者様個人を特定しうる情報（以下「個人情報」といいます）のご提供を求めることがございます。その際に、ご利用者様が当社に対して個人情報を提供されるかどうかは、ご利用者様ご自身の意思にしたがってご判断いただくことになります。ご利用者様が個人情報をご提供されない場合には、当社はご利用者様に対して適切なサービスの提供が行えない場合がございます。あらかじめご了承ください。",
                },
                {
                    title: "個人情報の保持・管理について",
                    context: "当社は、ご利用者様にご提供いただいた個人情報および記録した個人履歴を、厳重な管理体制のもとで保持し、第三者がご利用者様の個人情報および個人履歴に不当に触れることがないように、合理的な範囲内でセキュリティの強化に努めます。"
                },
                {
                    title: "個人情報の利用について",
                    context: "当社は、ご利用者様にご提供いただいた個人情報を、ご利用者様からご依頼のあったサービスの提供を行うため、および以下の目的のためにご利用者様の個人情報を利用させていただく場合がございます。なお、下記の目的以外に、ご本人の同意なく個人情報および個人履歴を利用することはございません。",
                    purposeList : [
                        { 
                            context: "ご利用者様に対して、当社および提携企業等のサービスのご案内、および提供をするため。"
                        },
                        {
                            context: "ご利用者様に対して、当社に対するご意見やご感想のご提供をお願いするため。",
                        },
                        { 
                            context: "ご利用者様よりご提供いただいた個人情報の一部（年齢、住所など）を基にした統計的資料を作成するため。",
                        },
                        {
                            context: "ご利用者様またはご利用者様の家族に対して、当社における業務運営上必要な連絡を取らせていただくため。"
                        },
                    ]
                },
                {
                    title: "個人情報の開示について",
                    context: "当社では、先に述べた利用目的を遂行するため、個人情報および個人履歴の取り扱いを外部に委託することがあります。この場合は、個人情報および個人履歴を適正に取扱っていると認められる委託先を選定し、守秘義務契約を取り交わすとともに、適正な管理を実施させるようにいたします。また、以下の場合にも、個人情報および個人履歴を必要最小限の範囲で第三者に開示することがございます。あらかじめご了承ください。",
                    purposeList: [
                        {
                            context: "法令の規定に基づいて司法機関または行政機関から法的義務を伴う開示要請を受けた場合",
                        },
                        {
                            context: "当社、ご利用者様または第三者の生命、身体、自由、財産、権利および名誉を保護する必要があると当社が判断した場合"
                        },
                    ],
                    context2: "上記の場合を除いて、当社がご利用者様ご本人の同意なく個人情報および個人履歴を第三者に開示することはございません。"
                },
                {
                    title: "個人情報の訂正・削除について",
                    context: "ご利用者様がご自分の個人情報の訂正・削除を求める場合、および当社からのサービスや商品の購入、その他の問い合わせを希望されない場合には、メール等でご連絡ください。"
                },
                {
                    title: "法令の遵守について",
                    context: "当社は、ご利用者様にご提供いただいた個人情報に関して、その保護に関する法令及び，その他規範を遵守いたします。"
                }
            ]
        }
    },
    head: {
        title: {
            inner: 'エミナルグループ総合サイト',
            separator: '|',
            complement: 'プライバシーポリシー'
        },
    },
})
</script>

<style scoped>
p,
li {
    font-size: 16px;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-weight: normal;
    color: #5A5A5A;
    line-height: 2;
}

.contents { 
    margin: 5% 0;
}

h3 {
    font-size: 22px;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-weight: bold;
    color: #C3A983;
    padding: 0rem 1rem;
    border-left: 5px solid;
}

/* ▼TABLE▼ */
.tbl01 {
    width: 100%;
    margin: 0 auto 5%;
}

.tbl01 th, .tbl01 td {
	padding: 20px;
	vertical-align: middle;
	line-height: 1.65;
}

.tbl01 th {
	font-weight: bold;
}

.tbl01.gold th, .tbl01.gold td {
    text-align: start;
	border: solid 1px #CCCCCC;
    font-size: 16px;
}

.tbl01.gold th {
	color: #A27A40;
	background: #FAF4EC;
	width: 25%;
    text-align: start;
}

        @media screen and (max-width: 767px) {
            table {
                    border: 1px solid #aaa;
                    border-collapse: separate;
                    border-spacing: 0;
                    border-radius: 6px;
                    overflow: hidden;
                }
            
                table thead th,
                table tbody th,
                table tbody td {
                    padding: .6em 3em;
                    border-bottom: 1px solid #aaa;
                }
            
                table thead th {
                    background-color: #ddd;
                }
            
                table tbody th {
                    background-color: #eee;
                }
            
                table thead th+th,
                table tbody td {
                    border-left: 1px solid #aaa;
                }
            
                table tbody tr:last-child th,
                table tbody tr:last-child td {
                    border-bottom: none;
                }
            .sp-margin {
                margin: 0 5%;
            }
            .tbl01 {
                width: 100%;
            }

            .tbl01.gold th {
                width: 40%;
            }

        }
</style>