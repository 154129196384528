import Vue from "vue";
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        width: window.innerWidth,
        siteTitle: "エミナルグループ総合サイト",
        refmedia: "",
        infoData: "",
        ladiesFaqData: [
            {
                id: 1,
                type: '医療脱毛',
                question: "エステ脱毛と医療脱毛の違いは何ですか？",
                answer: "エステで行われている脱毛は高出力の脱毛器を使用する事が出来ません。ですから結果が出るまでに長い期間がかかってしまいます。 医療脱毛は高出力の脱毛器を使用することが可能なので、短い期間で結果が出せるわけです。",
                date: "2022.01.22"
            },
            {
                id: 2,
                type: '医療脱毛',
                question: "何回程度、照射を行えば毛がなくなりますか？",
                answer: "個人差や体質、それぞれの満足度も違いますので一概に言えませんが、当医院では<span style='color:red'>5回～12回程施術されることをおすすめしております。</span>",
                date: "2022.01.22"
            },
            {
                id: 3,
                type: '医療脱毛',
                question: "痛くないですか？",
                answer: "当医院では医療レーザーの中でも痛みの少ない機械を使用しております。<br>しかし、脇やエチケットなどの毛質のしっかりした部分はお痛みを感じる方もいらっしゃいます。お痛みがお辛い時はレベルの調整や麻酔（有料）の利用も可能ですのでお気軽にお申し付けください。<br>※麻酔対象部位は脇・エチケットのみです。",
                date: "2022.01.22"
            },
            {
                id: 4,
                type: '医療脱毛',
                question: "光アレルギーですが施術は受けられますか？",
                answer: "申し訳ありませんが施術はできません。また妊娠中や授乳中の方も、申し訳ありませんが施術できません。施術が受けられない病気もありますので気になる方は、医師の問診時にご相談ください。",
                date: "2022.01.22"
            },
            {
                id: 5,
                type: '医療脱毛',
                question: "産毛も対応可能ですか？",
                answer: "脱毛の効果は毛が濃い部分の方がわかりやすいですが、当医院に導入している脱毛器は、うぶ毛にも効果が高いと言われている種類のレーザー脱毛器ですのでご安心ください。",
                date: "2022.01.22"
            },
            {
                id: 6,
                type: '医療脱毛',
                question: "どのような支払方法がありますか？",
                answer: "当院では、現金、クレジットカード（VISA、マスターは分割も可能）、医療ローン、自社クレジットなど、患者様のご希望に合わせてお選びいただけます。医療ローン、自社クレジットをご利用の場合は、写真付きの身分証明書と引き落とし口座のキャッシュカードをお持ちいただくとカウンセリング当日にお手続きができます（まれに銀行印が必要な場合がありますのでお持ちいただけるとスムーズです）。",
                date: "2022.01.22"
            },
            {
                id: 7,
                type: '医療脱毛',
                question: "引っ越しなどで通えなくなった場合、解約可能ですか？",
                answer: "もちろん解約可能です。ご契約期限内の残った回数分を清算返金させていただきます（特定商取引法で定められた内容を遵守いたします）。",
                date: "2022.01.22"
            },
            {
                id: 8,
                type: '医療脱毛',
                question: "照射当日はどのような格好で行けばよいですか？",
                answer: "施術時のお着替えは当院でご用意しております。普段お出かけされる格好でご来院ください。",
                date: "2022.01.22"
            },
            {
                id: 9,
                type: '医療脱毛',
                question: "顔脱毛の場合、メイクは落としますか？",
                answer: "顔脱毛の場合は、メイクを落とした状態で施術いたします。メイクせずに来院されるか、その分早く来られて当医院のドレッサーでメイクを落としてください。施術後はドレッサーでメイクできますのでご安心ください。",
                date: "2022.01.22"
            },
            {
                id: 10,
                type: '医療脱毛',
                question: "脱毛箇所にシミや色素沈着、ほくろやニキビがある場合はどうなりますか？",
                answer: "色の濃い箇所は、光の熱を感じやすいため、シミや色素沈着、ほくろなどは避けて施術していきます。気になる箇所は医師の問診時にご相談ください。",
                date: "2022.01.22"
            },
            {
                id: 11,
                type: '医療脱毛',
                question: "照射後のアフターケアは何かありますか？",
                answer: "照射直後のお肌チェックを看護師が行います。気になる点がある場合は、お気軽にお申し出ください。また、万が一、お帰りになられた後に副反応（赤み、刺激感）が出た場合のために、お帰りの際に皆様に抗炎症剤を無料で処方いたします。不快感が続くときは、お早めにご連絡ください。",
                date: "2022.01.22"
            },
            {
                id: 11.5,
                type: '医療ハイフ',
                question: "効果はどのように出ますか？",
                answer: "施術効果は2週間～1ヶ月後がピークになります。リフトアップや毛穴の引き締まり、お肌のハリ、弾力をご確認ください。",
                date: "2022.01.22"
            },
            {
                id: 12,
                type: '医療ハイフ',
                question: "施術ペースはどのくらいですか？",
                answer: "当院では効果が低下して元に戻る前に照射を行いますので、初めの数回は1か月～2か月間隔での施術をおススメしております。5回ほど回数を重ねた後はメンテナンスとして半年に1回ほど通って頂くのがおススメです。",
                date: "2022.01.22"
            },
            {
                id: 13,
                type: '医療ハイフ',
                question: "痛みはありますか？",
                answer: "刺激の感じ方には個人差がありますが、部位によっては痛みを感じる場合がございます。痛いと感じられる場合は、出力の調整も可能ですので、医師や看護師にご相談ください。",
                date: "2022.01.22"
            },
            {
                id: 14,
                type: '医療ハイフ',
                question: "ダウンタイムはありますか？",
                answer: "ありません。施術後すぐにメイクも可能です。稀に赤み、腫れ、かゆみが伴うことがありますが、これらは一時的な反応です。",
                date: "2022.01.22"
            },
            {
                id: 15,
                type: '医療ハイフ',
                question: "日焼け肌でも施術は受けれますか？",
                answer: "2週間以内に日焼けされた場合、施術が受けられません。日焼けが落ち着いてから施術を受けましょう。",
                date: "2022.01.22"
            },
            {
                id: 16,
                type: '医療ハイフ',
                question: "施術時間はどのくらいですか？",
                answer: "約30～60分です。照射部位によって異なりますので、詳細はスタッフにお尋ねください。",
                date: "2022.01.22"
            },
            {
                id: 16.5,
                type: '医療ハイフ',
                question: "エステHIFUと医療HIFUはなにが違いますか？",
                answer: "大きな違いは出力です。医療HIFUはエステ用と違い、出力が高く効果も高くなっています。医療の管理のもと治療を行いますので、安全性も高くなります。",
                date: "2022.01.22"
            },
            {
                id: 17,
                type: '医療ハイフ',
                question: "効果はどれくらい持続しますか？",
                answer: "個人差はありますが、約2～6ヶ月くらいです。",
                date: "2022.01.22"
            },
            {
                id: 18,
                type: '医療ハイフ',
                question: "副作用はありますか？",
                answer: "照射後、赤みや腫れ、痛みが生じる場合がありますが、ほとんどの場合、数日、長くても数週間で消失します。",
                date: "2022.01.22"
            },
            {
                id: 18.5,
                type: '医療ハイフ',
                question: "肝斑があるのですが、施術できますか？",
                answer: "施術可能です。刺激によっては濃くなる可能性がございます。ご心配でしたら肝斑部位を避けることもできますのでお申し出ください。",
                date: "2022.01.22"
            },
            {
                id: 19,
                type: '医療ハイフ',
                question: "施術後に気をつけることはありますか？",
                answer: "施術当日はシャワーのみとし入浴は控え、施術後最低2週間は、照射部位を搔いたり擦ったりして刺激を与えないでください。また、施術後3ヶ月間は日焼け止めを必ず使用してください。色素沈着・色素脱失の原因となります。乾燥はお肌への負担になるため保湿を十分に行ってください。",
                date: "2022.01.22"
            },
            {
                id: 20,
                type: '医療ハイフ',
                question: "誰でも施術を受けることができますか？",
                answer: "妊娠および産後3ヶ月以内の方はお受けいただけません。またお顔に金の糸やプレートが挿入されていたり、ヒアルロン酸注入の効果の持続している場合もお受けいただけない場合がございます。ご状況にもよりますので問診時にご相談ください。",
                date: "2022.01.22"
            },
            {
                id: 21,
                type: '医療ハイフ',
                question: "ヤケドのリスクはありますか？",
                answer: "照射パワーを強くするとリスクが高まるため、可能性がないわけではありません。通常の照射設定で十分に効果を実感していただけますので安心して受けていただけます。",
                date: "2022.01.22"
            },
            {
                id: 22,
                type: 'ダーマペン',
                question: "痛みはありますか？",
                answer: "一定の痛みはありますので、麻酔（無料）の使用をいたします。",
                date: "2022.01.22"
            },
            {
                id: 23,
                type: 'ダーマペン',
                question: "ダウンタイムはありますか？",
                answer: "ダウンタイムは3〜7日となっております。施術後3日ほど赤みが残りますが、翌日からメイク可能です。",
                date: "2022.01.22"
            },
            {
                id: 23.5,
                type: 'ダーマペン',
                question: "傷痕は残りませんか？",
                answer: "極細針なので傷痕は残りません",
                date: "2022.01.22"
            },
            {
                id: 24,
                type: 'ダーマペン',
                question: "傷痕は残りませんか？",
                answer: "極細針なので傷痕は残りません",
                date: "2022.01.22"
            },
            {
                id: 25,
                type: 'ダーマペン',
                question: "メイクはいつからできますか？",
                answer: "施術後12時間はメイクや洗顔、スキンケアをせず感染防止のため可能な限り清潔に保ってください。",
                date: "2022.01.22"
            },
            {
                id: 26,
                type: 'ダーマペン',
                question: "どのくらいの頻度で治療すればいいですか？",
                answer: "症状と目的によりますが、月に1度のペースでまずは5回ほどの施術をオススメしております。",
                date: "2022.01.22"
            },
            {
                id: 27,
                type: 'ダーマペン',
                question: "まぶたなど目周りに使用できますか？",
                answer: "当院ではまぶた、目周りの施術は行っておりません。",
                date: "2022.01.22"
            },
            {
                id: 28,
                type: 'ダーマペン',
                question: "ニキビがひどいのですが、ダーマペン4を受けることは可能ですか？",
                answer: "炎症が強いニキビの場合オススメはできませんが、軽度の場合は可能です。",
                date: "2022.01.22"
            },
            {
                id: 29,
                type: 'ダーマペン',
                question: "治療効果はいつくらいから実感できますか？",
                answer: "ダーマペンによる針穴が治癒したのち、3〜7日程度から効果を実感できる方が多いです。",
                date: "2022.01.22"
            },
            {
                id: 30,
                type: 'ダーマペン',
                question: "ダーマペンは妊娠中でも受けれますか？",
                answer: "妊娠中・産後3ヶ月以内の方・授乳中の方は治療をお受けすることができません。",
                date: "2022.01.22"
            },
            {
                id: 30.5,
                type: 'ダーマペン',
                question: "フラクショナルレーザーとの違いはなんですか？",
                answer: "どちらも毛穴やニキビ跡・肌の凹凸改善に使用しますが、レーザーを使用した治療ではないことが大きな違いです。ダーマペンは特殊な電動ニードルを肌にあて高速振動させることにより微細な穴を開けます。ダウンタイムもフラックショナルレーザーと比較し短いことが特徴です。",
                date: "2022.01.22"
            },
            {
                id: 31,
                type: 'ダーマペン',
                question: "施術時間はどれくらいですか？",
                answer: "麻酔と施術合わせて60分前後です。",
                date: "2022.01.22"
            },
            {
                id: 32,
                type: 'ダーマペン',
                question: "どのくらいの施術回数で効果を実感することができますか？",
                answer: "1回目から効果は実感できますが、回数を重ねる毎に肌質の改善が期待できるため、十分な効果を得られるまで5回程度の施術をおすすめしています。肌質改善後は、数か月おきに1回程度のメンテナンスをしていただくと効果を持続させることができます。",
                date: "2022.01.22"
            },
            {
                id: 33,
                type: 'ダーマペン',
                question: "施術後に気をつけることはありますか？",
                answer: "施術当日は炎症が強くなるため、飲酒や運動、入浴は避け安静にお過ごしください。感染症予防のため施術後12時間は洗顔、スキンケアもお控えください。また、施術後2週間は日焼けをしないように紫外線ケアをお願いいたします。",
                date: "2022.01.22"
            },
            {
                id: 34,
                type: 'ダーマペン',
                question: "毛穴の開きに効果はありますか？",
                answer: "ダーマペンの治療による高い効果が期待できます。",
                date: "2022.01.22"
            },
            {
                id: 35,
                type: '美容点滴',
                question: "痛みが不安です。",
                answer: "通常の注射と同様で、点滴の針を刺すときにチクッとした痛みがあります。点滴による血管痛を感じた場合は、看護師が点滴を落とす速度を調整致しますのでどうぞお気軽にお申し付けください。",
                date: "2022.01.22"
            },
            {
                id: 36,
                type: '美容点滴',
                question: "白玉点滴はどのペースで行えばいいですか？",
                answer: "週に1～2回くらいのペースで約5～10回くらいの施術を推奨しております。",
                date: "2022.01.22"
            },
            {
                id: 37,
                type: '美容点滴',
                question: "白玉点滴で副作用はありますか？",
                answer: "主成分であるグルタチオンは元から体内に備わっている成分のため、基本的な副作用はありませんが、注射の刺入部が赤くなったり、内出血することがあります。また、注射の苦手な方が具合が悪くなる場合もあります。",
                date: "2022.01.22"
            },
            {
                id: 38,
                type: '美容点滴',
                question: "サプリでグルタチオンを摂るのと、白玉点滴で摂るのとでは効果は同じですか？",
                answer: "点滴の場合は栄養素が直接血管から全身に行き渡るため、サプリよりも吸収効果が高く、栄養をダイレクトに摂取できるため、より高い効果を期待できます。",
                date: "2022.01.22"
            },
            {
                id: 39,
                type: '美容点滴',
                question: "白玉点滴と注射の違いはありますか？",
                answer: "点滴・注射どちらも、グルタチオンを直接血管から入れる効果に変わりはありませんが、点滴の方が沢山の量を投与することができます。投与する量が多い分効果の持続力にも期待できます。",
                date: "2022.01.22"
            },
            {
                id: 40,
                type: '美容点滴',
                question: "美白効果はいつくらいから実感出来ますか？",
                answer: "個人差や量にもよりますが、5〜10回ぐらいで少しずつ美白効果を実感する方が多いです。",
                date: "2022.01.22"
            },
            {
                id: 41,
                type: '美容点滴',
                question: "白玉点滴で白髪が増えることはありますか？",
                answer: "可能性としては0ではありませんが、現段階でそういった事例がないため考えにくいと思われます。",
                date: "2022.01.22"
            },
            {
                id: 42,
                type: '美容点滴',
                question: "病気の治療中ですが、白玉点滴を受けることはできますか？",
                answer: "合併症などが起こる可能性が高くなる恐れもありますので、問診時にご相談ください。",
                date: "2022.01.22"
            },
        ],
        mensFaqData: [
            {
                id: 1,
                type: '医療脱毛',
                question: "エステでの脱毛とクリニックの脱毛の違いはなんですか？",
                answer: "脱毛効果が異なります。エステ脱毛では「光脱毛」「フラッシュ脱毛」「IPL脱毛」と呼ばれる、低出力の脱毛器が使用されています。クリニックのように高出力の脱毛器を使用する事ができないため、結果が出るまでに長い期間がかかってしまいます。",
                date: "2022.01.22"
            },
            {
                id: 2,
                type: '医療脱毛',
                question: "脱毛の痛みが不安です",
                answer: "メンズエミナルでは痛みを最小限に抑えた脱毛器を使用しておりますが、痛みの感じ方は患者様によって異なります。特にヒゲやVIOは毛質も太く濃いため痛みを感じる人が多くいるのも事実です。そのため、メンズエミナルでは全ての患者様に麻酔を無料で提供しておりますので、痛みが不安な方はお気軽にご相談ください。<br><span style='color: red'>※麻酔対象の部位は、ヒゲ・ワキ・VIOのみです。</span>",
                date: "2022.01.22"
            },
            {
                id: 3,
                type: '医療脱毛',
                question: "何回照射を行えばツルツルになりますか？",
                answer: "個人差や体質、施術部位、それぞれの満足度も違いますので一概に言えませんが、当医院では5回～15回程施術されることをおすすめしております。",
                date: "2022.01.22"
            },
            {
                id: 4,
                type: '医療脱毛',
                question: "剛毛なのですが、効果を感じられますか？",
                answer: "剛毛な方であっても効果は十分に感じていただけます。一方で、毛質が太く濃いほど痛みを感じやすくもあります。しかし、メンズエミナルでは全ての患者様に麻酔を無料で提供しておりますので、痛みが心配な方も安心して施術を受けていただけます。<br><span style='color: red'>※麻酔対象の部位は、ヒゲ・ワキ・VIOのみです。</span>",
                date: "2022.01.22"
            },
            {
                id: 2,
                type: '医療脱毛',
                question: "脱毛の痛みが不安です",
                answer: "メンズエミナルでは痛みを最小限に抑えた脱毛器を使用しておりますが、痛みの感じ方は患者様によって異なります。特にヒゲやVIOは毛質も太く濃いため痛みを感じる人が多くいるのも事実です。そのため、メンズエミナルでは全ての患者様に麻酔を無料で提供しておりますので、痛みが不安な方はお気軽にご相談ください。<br><span style='color: red'>※麻酔対象の部位は、ヒゲ・ワキ・VIOのみです。</span>",
                date: "2022.01.22"
            },
            {
                id: 5,
                type: '医療脱毛',
                question: "光アレルギーですが施術は受けられますか？",
                answer: "たいへん申し訳ございませんがメンズエミナルでは施術をお断りしております。テスト照射をして問題ない場合は施術が可能ですので、カウンセリング時にご相談ください。",
                date: "2022.01.22"
            },
            {
                id: 6,
                type: '医療脱毛',
                question: "どのような支払方法がありますか？",
                answer: "当院では、現金、クレジットカード（VISA、マスターは分割も可能）、医療ローン、自社クレジットなど、患者様のご希望に合わせてお選びいただけます。医療ローン、自社クレジットをご利用の場合は、写真付きの身分証明書と引き落とし口座のキャッシュカードをお持ちいただくとカウンセリング当日にお手続きができます（まれに銀行印が必要な場合がありますのでお持ちいただけるとスムーズです）。",
                date: "2022.01.22"
            },
            {
                id: 7,
                type: '医療脱毛',
                question: "未成年でも脱毛することは可能ですか？",
                answer: "はい、中学生から脱毛の施術を受けていただくことができます。中学生の方については、カウンセリング、ご契約手続きの際は、保護者様の同席が必要です。それ以外の未成年の方については、保護者様ご記入の「親権者同意書」をカウンセリングの際に提出いただければご契約可能です。＞<a href='https://mens-eminal.jp/docs/miseinen_douisyo_HMR.pdf' target='_blank'>親権者同意書ダウンロードPDF</a>",
                date: "2022.01.22"
            },
            {
                id: 8,
                type: '医療脱毛',
                question: "引越しなどで通えなくなった場合、解約は可能ですか？",
                answer: "解約可能です。ご契約期限内の残った回数分を精算し返金対応させていただきますのでお気軽にご相談ください。<br>（特定商取引法で定められた内容を遵守いたします）",
                date: "2022.01.22"
            },
            {
                id: 9,
                type: '医療脱毛',
                question: "脱毛箇所にシミや色素沈着、ほくろやニキビがある場合はどうなりますか？",
                answer: "色の濃い箇所は、光の熱を感じやすいため、シミや色素沈着、ほくろなどは避けて施術していきます。気になる箇所は医師の問診時にご相談ください。",
                date: "2022.01.22"
            },
            {
                id: 10,
                type: '医療脱毛',
                question: "どのような支払方法がありますか？",
                answer: "当院では、現金、クレジットカード（VISA、マスターは分割も可能）、医療ローン、自社クレジットなど、患者様のご希望に合わせてお選びいただけます。医療ローン、自社クレジットをご利用の場合は、写真付きの身分証明書と引き落とし口座のキャッシュカードをお持ちいただくとカウンセリング当日にお手続きができます（まれに銀行印が必要な場合がありますのでお持ちいただけるとスムーズです）。",
                date: "2022.01.22"
            },
            {
                id: 11,
                type: '医療脱毛',
                question: "照射後のアフターケアは何かありますか？",
                answer: "照射直後のお肌チェックを看護師が行います。気になる点がある場合は、お気軽にお申し出ください。また、万が一、お帰りになられた後に副反応（赤み、刺激感）が出た場合のために、お帰りの際に皆様に抗炎症剤を無料で処方いたします。不快感が続くときは、お早めにご連絡ください。",
                date: "2022.01.22"
            },
            {
                id: 12,
                type: '医療ハイフ',
                question: "痛みはありますか？痛みに弱いので心配です。",
                answer: "刺激の感じ方には個人差がありますが、部位によっては痛みを感じる場合がございます。痛いと感じられる場合は、出力の調整も可能ですので、医師や看護師にご相談ください。",
                date: "2022.01.22"
            },
            {
                id: 13,
                type: '医療ハイフ',
                question: "ダウンタイムはありますか？",
                answer: "ありません。稀に赤み、腫れ、かゆみが伴うことがありますが、これらは一時的な反応です。",
                date: "2022.01.22"
            },
            {
                id: 14,
                type: '医療ハイフ',
                question: "日焼けをしていても施術は受けれますか？",
                answer: "2週間以内に日焼けされた場合、施術が受けられません。日焼けが落ち着いてから施術を受けましょう。",
                date: "2022.01.22"
            },
            {
                id: 15,
                type: '医療ハイフ',
                question: "施術のペースはどのくらいですか？",
                answer: "当院では効果が低下して元に戻る前に照射を行いますので、初めの数回は1か月～2か月間隔での施術をおススメしております。5回ほど回数を重ねた後はメンテナンスとして半年に1回ほど通って頂くのがおススメです。",
                date: "2022.01.22"
            },
            {
                id: 16,
                type: '医療ハイフ',
                question: "効果はどれくらい持続しますか？",
                answer: "個人差はありますが、約2～6ヶ月くらいです。",
                date: "2022.01.22"
            },
            {
                id: 17,
                type: '医療ハイフ',
                question: "施術時間はどのくらいですか？",
                answer: "約30～60分です。照射部位によって異なりますので、詳細はスタッフにお尋ねください。",
                date: "2022.01.22"
            },
            {
                id: 18,
                type: '医療ハイフ',
                question: "施術後に気をつけることはありますか？",
                answer: "施術当日はシャワーのみとし入浴は控え、施術後最低2週間は、照射部位を搔いたり擦ったりして刺激を与えないでください。また、施術後3ヶ月間は日焼け止めを必ず使用してください。色素沈着・色素脱失の原因となります。乾燥はお肌への負担になるため保湿を十分に行ってください。",
                date: "2022.01.22"
            },
            {
                id: 17,
                type: '医療ハイフ',
                question: "エステHIFUと医療HIFUはなにが違いますか？",
                answer: "大きな違いは出力です。医療HIFUはエステ用と違い、出力が高く効果も高くなっています。医療の管理のもと治療を行いますので、安全性も高くなります。",
                date: "2022.01.22"
            },
            {
                id: 18,
                type: '医療ハイフ',
                question: "ヤケドのリスクはありますか？",
                answer: "照射パワーを強くするとリスクが高まるため、可能性がないわけではありません。通常の照射設定で十分に効果を実感していただけますので安心して受けていただけます。",
                date: "2022.01.22"
            },
            {
                id: 19,
                type: '医療ハイフ',
                question: "誰でも施術を受けることができますか？",
                answer: "お顔に金の糸やプレートが挿入されていたり、ヒアルロン酸注入の効果の持続している場合もお受けいただけない場合がございます。ご状況にもよりますので医師の問診時にご相談ください。",
                date: "2022.01.22"
            },
            {
                id: 19,
                type: '医療ハイフ',
                question: "施術後に気をつけることはありますか？",
                answer: "施術当日はシャワーのみとし入浴は控え、施術後最低2週間は、照射部位を搔いたり擦ったりして刺激を与えないでください。また、施術後3ヶ月間は日焼け止めを必ず使用してください。色素沈着・色素脱失の原因となります。乾燥はお肌への負担になるため保湿を十分に行ってください。",
                date: "2022.01.22"
            },
            {
                id: 20,
                type: '医療ハイフ',
                question: "誰でも施術を受けることができますか？",
                answer: "妊娠および産後3ヶ月以内の方はお受けいただけません。またお顔に金の糸やプレートが挿入されていたり、ヒアルロン酸注入の効果の持続している場合もお受けいただけない場合がございます。ご状況にもよりますので問診時にご相談ください。",
                date: "2022.01.22"
            },
            {
                id: 21,
                type: 'ダーマペン',
                question: "痛みはありますか？",
                answer: "一定の痛みはありますので、麻酔(無料) の使用をいたします。",
                date: "2022.01.22"
            },
            {
                id: 22,
                type: 'ダーマペン',
                question: "施術後に気をつけることはありますか？",
                answer: "施術当日は炎症が強くなるため、飲酒や運動、入浴は避け安静にお過ごしください。感染症予防のため施術後１２時間は洗顔、スキンケアもお控えください。また、施術後２週間は日焼けをしないように紫外線ケアをお願いいたします。",
                date: "2022.01.22"
            },
            {
                id: 23,
                type: 'ダーマペン',
                question: "オススメの治療間隔はありますか？",
                answer: "症状と目的によりますが、月に１度のペースでまずは５回ほどの施術をおすすめしております。",
                date: "2022.01.22"
            },
            {
                id: 24,
                type: 'ダーマペン',
                question: "ダウンタイムはありますか？",
                answer: "ダウンタイムは３～７日となっております。施術後３日ほどは赤みが残りますが、翌日からメイク可能です。",
                date: "2022.01.22"
            },
            {
                id: 25,
                type: 'ダーマペン',
                question: "メンズの施術は可能ですか？",
                answer: "可能です。皮膚の厚さに合わせた針の深さも0.1mmずつ変えることができます。皮膚の厚い男性の方にも対応可能です。",
                date: "2022.01.22"
            },
            {
                id: 26,
                type: 'ダーマペン',
                question: "施術時間はどれくらいですか？",
                answer: "麻酔と施術合わせて60分前後です。",
                date: "2022.01.22"
            },
            {
                id: 27,
                type: 'ダーマペン',
                question: "どのくらいの施術回数で効果を実感することができますか？",
                answer: "1回でも効果は実感できますが、回数を重ねることで更に効果を期待できます。3週間ほどの間隔を空けて、5〜10回程度行うことをおすすめしております。",
                date: "2022.01.22"
            },
            {
                id: 28,
                type: 'ダーマペン',
                question: "傷痕は残りませんか？",
                answer: "極細針なので傷痕は残りません",
                date: "2022.01.22"
            },
            {
                id: 29,
                type: 'ダーマペン',
                question: "施術前後のスキンケアで気をつけることはありますか？",
                answer: "高濃度ビタミン、ピーリング剤が入っているものは前後1週間は控えていただきます。カウンセリング時にご相談ください。",
                date: "2022.01.22"
            },
            {
                id: 30,
                type: 'ダーマペン',
                question: "毛穴の開きに効果はありますか？",
                answer: "ダーマペンの治療による高い効果が期待できます。",
                date: "2022.01.22"
            },
            {
                id: 31,
                type: 'ダーマペン',
                question: "ニキビがあっても施術可能ですか？",
                answer: "できたばかりのニキビや膿んでいる箇所は避けて施術をさせていただきます。",
                date: "2022.01.22"
            },
            {
                id: 32,
                type: '美容点滴',
                question: "白玉点滴で白髪が増えることはありますか？",
                answer: "可能性としては0ではありませんが、現段階でそういった事例がないため考えにくいと思われます。",
                date: "2022.01.22"
            },
            {
                id: 33,
                type: '美容点滴',
                question: "痛みが不安です。",
                answer: "通常の注射と同様で、点滴の針を刺すときにチクッとした痛みがあります。点滴による血管痛を感じた場合は、看護師が点滴を落とす速度を調整致しますのでどうぞおきがるにお申し付けください。",
                date: "2022.01.22"
            },
            {
                id: 34,
                type: '美容点滴',
                question: "白玉点滴で副作用はありますか？",
                answer: "主成分であるグルタチオンは元から体内に備わっている成分のため、基本的な副作用はありませんが、注射の刺入部が赤くなったり、内出血することがあります。また、注射の苦手な方が具合が悪くなる場合もあります。",
                date: "2022.01.22"
            },
            {
                id: 35,
                type: '美容点滴',
                question: "病気の治療中ですが、白玉点滴を受けることはできますか？",
                answer: "合併症などが起こる可能性が高くなる恐れもありますので、問診時にご相談ください。",
                date: "2022.01.22"
            },
            {
                id: 36,
                type: '美容点滴',
                question: "白玉点滴と注射の違いはありますか？",
                answer: "点滴・注射どちらも、グルタチオンを直接血管から入れる効果に変わりはありませんが、点滴の方が沢山の量を投与することができます。投与する量が多い分効果の持続力にも期待できます。",
                date: "2022.01.22"
            },
            {
                id: 37,
                type: '美容点滴',
                question: "美白効果はいつくらいから実感出来ますか？",
                answer: "個人差や量にもよりますが、5〜10回ぐらいで少しずつ美白効果を実感する方が多いです。",
                date: "2022.01.22"
            },
            {
                id: 38,
                type: '美容点滴',
                question: "白玉点滴はどのペースで行えばいいですか？",
                answer: "週に1～2回くらいのペースで約5～10回くらいの施術を推奨しております。",
                date: "2022.01.22"
            },
            {
                id: 39,
                type: '美容点滴',
                question: "サプリでグルタチオンを摂るのと、白玉点滴で摂るのとでは効果は同じですか？",
                answer: "点滴の場合は栄養素が直接血管から全身に行き渡るため、サプリよりも吸収効果が高く、栄養をダイレクトに摂取できるため、<span style='coloe:red;'>より高い効果を期待できます。</span>",
                date: "2022.01.22"
            },
        ],
        stateData: [
            { id: 1, name: '北海道・東北エリア', name_eng: 'tohoku&hokkaido', active: 1 },
            { id: 2, name: '東京エリア', name_eng: 'tokyo', active: 1 },
            { id: 3, name: '関東エリア', name_eng: 'kanto', active: 1 },
            { id: 4, name: '中部エリア', name_eng: 'chubu', active: 1 },
            { id: 5, name: '近畿エリア', name_eng: 'kinki', active: 1 },
            // {id: 6, name : '甲信越エリア', active : 1},
            { id: 7, name: '中国・四国エリア', name_eng: 'chugoku&shikoku', active: 1 },
            { id: 8, name: '九州・沖縄エリア', name_eng: 'kyusyu&okinawa', active: 1 },
        ],
        ladiesPrefData: [
            {
                name: '北海道',
                name_eng: 'hokkaido',
                area_name: '北海道エリア',
                active: 1,
                area_state: '北海道・東北エリア',
                state_eng: 'tohoku&hokkaido'
            },
            {
                name: '青森県',
                name_eng: 'aomori',
                area_name: '青森エリア',
                active: 1,
                area_state: '北海道・東北エリア',
                state_eng: 'tohoku&hokkaido'
            },
            {
                name: '岩手県',
                name_eng: 'iwate',
                area_name: '岩手エリア',
                active: 1,
                area_state: '北海道・東北エリア',
                state_eng: 'tohoku&hokkaido'
            },
            {
                name: '宮城県',
                name_eng: 'miyagi',
                area_name: '宮城エリア',
                active: 1,
                area_state: '北海道・東北エリア',
                state_eng: 'tohoku&hokkaido'
            },
            {
                name: '秋田県',
                name_eng: 'akita',
                area_name: '秋田エリア',
                active: 1,
                area_state: '北海道・東北エリア',
                state_eng: 'tohoku&hokkaido'
            },
            {
                name: '山形県',
                name_eng: 'yamagata',
                area_name: '山形エリア',
                active: 0,
                area_state: '北海道・東北エリア',
                state_eng: 'tohoku&hokkaido'
            },
            {
                name: '福島県',
                name_eng: 'fukushima',
                area_name: '福島エリア',
                active: 1,
                area_state: '北海道・東北エリア',
                state_eng: 'tohoku&hokkaido'
            },
            {
                name: '茨城県',
                area_name: '茨城エリア',
                name_eng: 'ibaraki',
                active: 1,
                area_state: '関東エリア',
                state_eng: 'kanto'
            },
            {
                name: '栃木県',
                area_name: '栃木エリア',
                name_eng: 'tochigi',
                active: 1,
                area_state: '関東エリア',
                state_eng: 'kanto'
            },
            {
                name: '群馬県',
                area_name: '群馬エリア',
                name_eng: 'gunma',
                active: 1,
                area_state: '関東エリア',
                state_eng: 'kanto'
            },
            {
                name: '埼玉県',
                area_name: '埼玉エリア',
                name_eng: 'saitama',
                active: 1,
                area_state: '関東エリア',
                state_eng: 'kanto'
            },
            {
                name: '千葉県',
                area_name: '千葉エリア',
                name_eng: 'chiba',
                active: 1,
                area_state: '関東エリア',
                state_eng: 'kanto'
            },
            {
                name: '東京都',
                name_eng: 'tokyo',
                area_name: '東京エリア',
                active: 1,
                area_state: '東京エリア',
                state_eng: 'tokyo'
            },
            {
                name: '神奈川県',
                name_eng: 'kanagawa',
                area_name: '神奈川エリア',
                active: 1,
                area_state: '関東エリア',
                state_eng: 'kanto'
            },
            {
                name: '新潟県',
                name_eng: 'niigata',
                area_name: '新潟エリア',
                active: 1,
                // area_state : '甲信越エリア'
                area_state: '中部エリア',
                state_eng: 'chubu'
            },
            {
                name: '富山県',
                name_eng: 'toyama',
                area_name: '富山エリア',
                active: 1,
                area_state: '中部エリア',
                state_eng: 'chubu'
            },
            {
                name: '石川県',
                name_eng: 'ishikawa',
                area_name: '石川エリア',
                active: 1,
                area_state: '中部エリア',
                state_eng: 'chubu'
            },
            {
                name: '福井県',
                name_eng: 'fukui',
                area_name: '福井エリア',
                active: 1,
                area_state: '中部エリア',
                state_eng: 'chubu'
            },
            {
                name: '山梨県',
                name_eng: 'yamanashi',
                area_name: '山梨エリア',
                active: 0,
                // area_state : '甲信越エリア'
                area_state: '近畿エリア',
                state_eng: 'kinki'
            },
            {
                name: '長野県',
                name_eng: 'nagano',
                area_name: '長野エリア',
                active: 1,
                // area_state : '甲信越エリア'
                area_state: '中部エリア',
                state_eng: 'chubu'
            },
            {
                name: '岐阜県',
                name_eng: 'gifu',
                area_name: '岐阜エリア',
                active: 1,
                area_state: '中部エリア',
                state_eng: 'chubu'
            },
            {
                name: '静岡県',
                name_eng: 'shizuoka',
                area_name: '静岡エリア',
                active: 1,
                area_state: '中部エリア',
                state_eng: 'chubu'
            },
            {
                name: '愛知県',
                name_eng: 'aichi',
                area_name: '愛知エリア',
                active: 1,
                area_state: '中部エリア',
                state_eng: 'chubu'
            },
            {
                name: '三重県',
                name_eng: 'mie',
                area_name: '三重エリア',
                active: 1,
                area_state: '近畿エリア',
                state_eng: 'kinki'
            },
            {
                name: '滋賀県',
                name_eng: 'shiga',
                area_name: '滋賀エリア',
                active: 1,
                area_state: '近畿エリア',
                state_eng: 'shiga'
            },
            {
                name: '京都府',
                name_eng: 'kyoto',
                area_name: '京都エリア',
                active: 1,
                area_state: '近畿エリア',
                state_eng: 'kinki'
            },
            {
                name: '大阪府',
                name_eng: 'osaka',
                area_name: '大阪エリア',
                active: 1,
                area_state: '近畿エリア',
                state_eng: 'kinki'
            },
            {
                name: '兵庫県',
                name_eng: 'hyogo',
                area_name: '兵庫エリア',
                active: 1,
                area_state: '近畿エリア',
                state_eng: 'kinki'
            },
            {
                name: '奈良県',
                name_eng: 'nara',
                area_name: '奈良エリア',
                active: 1,
                area_state: '近畿エリア',
                state_eng: 'kinki'
            },
            {
                name: '和歌山県',
                name_eng: 'wakayama',
                area_name: '和歌山エリア',
                active: 1,
                area_state: '近畿エリア',
                state_eng: 'kinki'
            },
            {
                name: '鳥取県',
                name_eng: 'tottori',
                area_name: '鳥取エリア',
                active: 0,
                area_state: '中国・四国エリア',
                state_eng: 'chugoku&shikoku'
            },
            {
                name: '島根県',
                name_eng: 'shimane',
                area_name: '島根エリア',
                active: 0,
                area_state: '中国・四国エリア',
                state_eng: 'chugoku&shikoku'
            },
            {
                name: '岡山県',
                name_eng: 'okayama',
                area_name: '岡山エリア',
                active: 1,
                area_state: '中国・四国エリア',
                state_eng: 'chugoku&shikoku'
            },
            {
                name: '広島県',
                name_eng: 'hiroshima',
                area_name: '広島エリア',
                active: 1,
                area_state: '中国・四国エリア',
                state_eng: 'chugoku&shikoku'
            },
            {
                name: '山口県',
                name_eng: 'yamaguchi',
                area_name: '山口エリア',
                active: 0,
                area_state: '中国・四国エリア',
                state_eng: 'chugoku&shikoku'
            },
            {
                name: '徳島県',
                name_eng: 'tokushima',
                area_name: '徳島エリア',
                active: 1,
                area_state: '中国・四国エリア',
                state_eng: 'chugoku&shikoku'
            },
            {
                name: '香川県',
                name_eng: 'kagawa',
                area_name: '香川エリア',
                active: 1,
                area_state: '中国・四国エリア',
                state_eng: 'chugoku&shikoku'
            },
            {
                name: '愛媛県',
                name_eng: 'ehime',
                area_name: '愛媛エリア',
                active: 1,
                area_state: '中国・四国エリア',
                state_eng: 'chugoku&shikoku'
            },
            {
                name: '高知県',
                name_eng: 'kochi',
                area_name: '高知エリア',
                active: 1,
                area_state: '中国・四国エリア',
                state_eng: 'chugoku&shikoku'
            },
            {
                name: '福岡県',
                name_eng: 'fukuoka',
                area_name: '福岡エリア',
                active: 1,
                area_state: '九州・沖縄エリア',
                state_eng: "kyusyu&okinawa"
            },
            {
                name: '佐賀県',
                name_eng: 'saga',
                area_name: '佐賀エリア',
                active: 0,
                area_state: '九州・沖縄エリア',
                state_eng: "kyusyu&okinawa"
            },
            {
                name: '長崎県',
                name_eng: 'nagasaki',
                area_name: '長崎エリア',
                active: 1,
                area_state: '九州・沖縄エリア',
                state_eng: "kyusyu&okinawa"
            },
            {
                name: '熊本県',
                name_eng: 'kumamoto',
                area_name: '熊本エリア',
                active: 1,
                area_state: '九州・沖縄エリア',
                state_eng: "kyusyu&okinawa"
            },
            {
                name: '大分県',
                // レディスはooita
                name_eng: 'ooita',
                area_name: '大分エリア',
                active: 1,
                area_state: '九州・沖縄エリア',
                state_eng: "kyusyu&okinawa"
            },
            {
                name: '宮崎県',
                name_eng: 'miyazaki',
                area_name: '宮崎エリア',
                active: 1,
                area_state: '九州・沖縄エリア',
                state_eng: "kyusyu&okinawa"
            },
            {
                name: '鹿児島県',
                name_eng: 'kagoshima',
                area_name: '鹿児島エリア',
                active: 1,
                area_state: '九州・沖縄エリア',
                state_eng: "kyusyu&okinawa"
            },
            {
                name: '沖縄県',
                name_eng: 'okinawa',
                area_name: '沖縄エリア',
                active: 1,
                area_state: '九州・沖縄エリア',
                state_eng: "kyusyu&okinawa"
            },
        ],
        mensPrefData: [
            {
                name: '北海道',
                name_eng: 'hokkaido',
                area_name: '北海道エリア',
                active: 1,
                area_state: '北海道・東北エリア',
                state_eng: 'tohoku&hokkaido'
            },
            {
                name: '青森県',
                name_eng: 'aomori',
                area_name: '青森エリア',
                active: 1,
                area_state: '北海道・東北エリア',
                state_eng: 'tohoku&hokkaido'
            },
            {
                name: '岩手県',
                name_eng: 'iwate',
                area_name: '岩手エリア',
                active: 1,
                area_state: '北海道・東北エリア',
                state_eng: 'tohoku&hokkaido'
            },
            {
                name: '宮城県',
                name_eng: 'miyagi',
                area_name: '宮城エリア',
                active: 1,
                area_state: '北海道・東北エリア',
                state_eng: 'tohoku&hokkaido'
            },
            {
                name: '秋田県',
                name_eng: 'akita',
                area_name: '秋田エリア',
                active: 1,
                area_state: '北海道・東北エリア',
                state_eng: 'tohoku&hokkaido'
            },
            {
                name: '山形県',
                name_eng: 'yamagata',
                area_name: '山形エリア',
                active: 0,
                area_state: '北海道・東北エリア',
                state_eng: 'tohoku&hokkaido'
            },
            {
                name: '福島県',
                name_eng: 'fukushima',
                area_name: '福島エリア',
                active: 1,
                area_state: '北海道・東北エリア',
                state_eng: 'tohoku&hokkaido'
            },
            {
                name: '茨城県',
                area_name: '茨城エリア',
                name_eng: 'ibaraki',
                active: 1,
                area_state: '関東エリア',
                state_eng: 'kanto'
            },
            {
                name: '栃木県',
                area_name: '栃木エリア',
                name_eng: 'tochigi',
                active: 1,
                area_state: '関東エリア',
                state_eng: 'kanto'
            },
            {
                name: '群馬県',
                area_name: '群馬エリア',
                name_eng: 'gunma',
                active: 1,
                area_state: '関東エリア',
                state_eng: 'kanto'
            },
            {
                name: '埼玉県',
                area_name: '埼玉エリア',
                name_eng: 'saitama',
                active: 1,
                area_state: '関東エリア',
                state_eng: 'kanto'
            },
            {
                name: '千葉県',
                area_name: '千葉エリア',
                name_eng: 'chiba',
                active: 1,
                area_state: '関東エリア',
                state_eng: 'kanto'
            },
            {
                name: '東京都',
                name_eng: 'tokyo',
                area_name: '東京エリア',
                active: 1,
                area_state: '東京エリア',
                state_eng: 'tokyo'
            },
            {
                name: '神奈川県',
                name_eng: 'kanagawa',
                area_name: '神奈川エリア',
                active: 1,
                area_state: '関東エリア',
                state_eng: 'kanto'
            },
            {
                name: '新潟県',
                name_eng: 'niigata',
                area_name: '新潟エリア',
                active: 1,
                area_state: '中部エリア',
                state_eng: 'chubu'
            },
            {
                name: '富山県',
                name_eng: 'toyama',
                area_name: '富山エリア',
                active: 1,
                area_state: '中部エリア',
                state_eng: 'chubu'
            },
            {
                name: '石川県',
                name_eng: 'ishikawa',
                area_name: '石川エリア',
                active: 1,
                area_state: '中部エリア',
                state_eng: 'chubu'
            },
            {
                name: '福井県',
                name_eng: 'fukui',
                area_name: '福井エリア',
                active: 1,
                area_state: '中部エリア',
                state_eng: 'chubu'
            },
            {
                name: '山梨県',
                area_name: '山梨エリア',
                active: 0,
                area_state: '近畿エリア',
                state_eng: 'kinki'
            },
            {
                name: '長野県',
                name_eng: 'nagano',
                area_name: '長野エリア',
                active: 1,
                area_state: '中部エリア',
                state_eng: 'chubu'
            },
            {
                name: '岐阜県',
                name_eng: 'gifu',
                area_name: '岐阜エリア',
                active: 1,
                area_state: '中部エリア',
                state_eng: 'chubu'
            },
            {
                name: '静岡県',
                name_eng: 'shizuoka',
                area_name: '静岡エリア',
                active: 1,
                area_state: '中部エリア',
                state_eng: 'chubu'
            },
            {
                name: '愛知県',
                name_eng: 'aichi',
                area_name: '愛知エリア',
                active: 1,
                area_state: '中部エリア',
                state_eng: 'chubu'
            },
            {
                name: '三重県',
                name_eng: 'mie',
                area_name: '三重エリア',
                active: 1,
                area_state: '近畿エリア',
                state_eng: 'kinki'
            },
            {
                name: '滋賀県',
                name_eng: 'shiga',
                area_name: '滋賀エリア',
                active: 0,
                area_state: '近畿エリア',
                state_eng: 'kinki'
            },
            {
                name: '京都府',
                name_eng: 'kyoto',
                area_name: '京都エリア',
                active: 1,
                area_state: '近畿エリア'
            },
            {
                name: '大阪府',
                name_eng: 'osaka',
                area_name: '大阪エリア',
                active: 1,
                area_state: '近畿エリア',
                state_eng: 'kinki'
            },
            {
                name: '兵庫県',
                name_eng: 'hyogo',
                area_name: '兵庫エリア',
                active: 1,
                area_state: '近畿エリア',
                state_eng: 'kinki'
            },
            {
                name: '奈良県',
                name_eng: 'nara',
                area_name: '奈良エリア',
                active: 1,
                area_state: '近畿エリア',
                state_eng: 'kinki'
            },
            {
                name: '和歌山県',
                name_eng: 'wakayama',
                area_name: '和歌山エリア',
                active: 1,
                area_state: '近畿エリア',
                state_eng: 'kinki'
            },
            {
                name: '滋賀県',
                name_eng: 'shiga',
                area_name: '滋賀エリア',
                active: 1,
                area_state: '近畿エリア',
                state: 'kinki'
            },
            {
                name: '島根県',
                name_eng: 'shimane',
                area_name: '島根エリア',
                active: 0,
                area_state: '中国・四国エリア',
                state: 'chugoku&shikoku'
            },
            {
                name: '岡山県',
                name_eng: 'okayama',
                area_name: '岡山エリア',
                active: 1,
                area_state: '中国・四国エリア',
                state: 'chugoku&shikoku'
            },
            {
                name: '広島県',
                name_eng: 'hiroshima',
                area_name: '広島エリア',
                active: 1,
                area_state: '中国・四国エリア',
                state: 'chugoku&shikoku'
            },
            {
                name: '山口県',
                name_eng: 'yamaguchi',
                area_name: '山口エリア',
                active: 0,
                area_state: '中国・四国エリア',
                state: 'chugoku&shikoku'
            },
            {
                name: '徳島県',
                name_eng: 'tokushima',
                area_name: '徳島エリア',
                active: 1,
                area_state: '中国・四国エリア',
                state: 'chugoku&shikoku'
            },
            {
                name: '香川県',
                name_eng: 'kagawa',
                area_name: '香川エリア',
                active: 1,
                area_state: '中国・四国エリア',
                state: 'chugoku&shikoku'
            },
            {
                name: '愛媛県',
                name_eng: 'ehime',
                area_name: '愛媛エリア',
                active: 1,
                area_state: '中国・四国エリア',
                state: 'chugoku&shikoku'
            },
            {
                name: '高知県',
                name_eng: 'kochi',
                area_name: '高知エリア',
                active: 1,
                area_state: '中国・四国エリア',
                state: 'chugoku&shikoku'
            },
            {
                name: '福岡県',
                name_eng: 'fukuoka',
                area_name: '福岡エリア',
                active: 1,
                area_state: '九州・沖縄エリア',
                state: 'kyusyu&okinawa'
            },
            {
                name: '佐賀県',
                name_eng: 'saga',
                area_name: '佐賀エリア',
                active: 0,
                area_state: '九州・沖縄エリア',
                state: 'kyusyu&okinawa'
            },
            {
                name: '長崎県',
                name_eng: 'nagasaki',
                area_name: '長崎エリア',
                active: 1,
                area_state: '九州・沖縄エリア',
                state: 'kyusyu&okinawa'
            },
            {
                name: '大分県',
                name_eng: 'oita',
                area_name: '大分エリア',
                active: 1,
                area_state: '九州・沖縄エリア',
                state: 'kyusyu&okinawa'
            },
            {
                name: '熊本県',
                name_eng: 'kumamoto',
                area_name: '熊本エリア',
                active: 1,
                area_state: '九州・沖縄エリア',
                state: 'kyusyu&okinawa'
            },
            {
                name: '宮崎県',
                name_eng: 'miyazaki',
                area_name: '宮崎エリア',
                active: 1,
                area_state: '九州・沖縄エリア',
                state: 'kyusyu&okinawa'
            },
            {
                name: '鹿児島県',
                name_eng: 'kagoshima',
                area_name: '鹿児島エリア',
                active: 1,
                area_state: '九州・沖縄エリア',
                state: 'kyusyu&okinawa'
            },
            {
                name: '沖縄県',
                name_eng: 'okinawa',
                area_name: '沖縄エリア',
                active: 1,
                area_state: '九州・沖縄エリア',
                state: 'kyusyu&okinawa'
            },
        ],
        apiBaseURL: 'https://orizo-test.heteml.net/eminal-group-backend/api/',
        ladiesURL: 'eminal',
        nameAndPrefURL: '/nameAndPref',
        mensURL: 'mensEminal',
        authParam: '?p=$2y$10$92IXUNpkjO0rOQ5byMi.Ye4oKoEa3Ro9llC/.og/at2.uheWG/igi',
        eminalURL: "https://eminal-clinic.jp/",
        mensEminalURL: "https://mens-eminal.jp/",
        eminalHifuURL: "https://eminal-clinic.jp/hifu/",
        mensHifuURL: "https://mens-eminal.jp/lp/hifu.php",
        eminalDermapenURL: "https://eminal-clinic.jp/dermapen/",
        mensDermapenURL: "https://mens-eminal.jp/dermapen_mens/",
        eminalTentekiURL: "https://eminal-group.jp/tenteki/",
        mensTentekiURL: "https://eminal-group.jp/tenteki_mens/",
        mensAgaURL: "https://aga-mens-eminal.jp/",
        recruitURL: "https://eminal-clinic.jp/recruit/",
        contactRegistURL: "registContact",
        contactColumnList: [
            '会員番号',
            'お名前',
            'フリガナ',
            '郵便番号',
            'ご住所',
            '電話番号',
            'Eメールアドレス',
            'お問い合わせ内容',
            'お問い合わせ詳細'
        ],
        memberNumber: "",
        name: "",
        nameFurigana: "",
        tel: "",
        mail: "",
        contactContent: "",
        contactGenderClinic: "",
        contactPref: "",
        contactClinic: "",
        contactDetail: "",
        postcode1: "",
        postcode2: "",
        address: "",
        resultAPI: "",
        postcodeAPIURL: "https://zip-cloud.appspot.com/api/search?zipcode=",
    },
    mutations: {
        updateMemberNumber(state, memberNumber) {
            state.memberNumber = memberNumber
        },
        updateName(state, name) {
            state.name = name
        },
        updateNameFurigana(state, nameFurigana) {
            state.nameFurigana = nameFurigana
        },
        updateCompany(state, company) {
            state.company = company
        },
        updateCompanyFurigana(state, companyFurigana) {
            state.companyFurigana = companyFurigana
        },
        updateMail(state, mail) {
            state.mail = mail
        },
        updateContactGenderClinic(state, contactGenderClinic) {
            state.contactGenderClinic = contactGenderClinic
        },
        updateContactPref(state, contactPref) {
            state.contactPref = contactPref
        },
        updateContactClinic(state, contactClinic) {
            state.contactClinic = contactClinic
        },
        updateContactContent(state, contactContent) {
            state.contactContent = contactContent
        },
        updateDetailContent(state, contactDetail) {
            state.contactDetail = contactDetail
        },
        updateRefmedia(state, refmedia) {
            state.refmedia = refmedia
        },
        updatePostcode1(state, postcode1) {
            state.postcode1 = postcode1
        },
        updatePostcode2(state, postcode2) {
            state.postcode2 = postcode2
        },
        updateAddress(state, address) {
            state.address = address
        },
        updateTel(state, tel) {
            state.tel = tel
        }
    },
    actions: {
        getAddress: function ({ commit }, address) {
            commit('updateAddress', address)
        }
    }
})
