var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-top"},[_c('div',{staticClass:"page-title"},[_c('h1',[_vm._v(_vm._s(_vm.japaneseFaq))]),_c('img',{attrs:{"src":this.$imgTitleBar}}),_c('h2',[_vm._v(_vm._s(_vm.englishFaq))])]),_c('div',{staticClass:"mx-auto",attrs:{"id":"imgs"}},[_c('img',{staticClass:"mx-md-5",class:{
                marginBottom: _vm.isMensOnClick === true,
                spImgSize: _vm.width < 767,
                spClickedImgSize: _vm.width < 767 && _vm.isLadiesOnClick,
            },attrs:{"src":_vm.img.ladies,"alt":""},on:{"click":function($event){_vm.changeImg('ladies', _vm.isLadiesOnClick),
                    _vm.moveClinicList(_vm.isLadiesOnClick)}}}),_c('img',{class:{
                marginBottom: _vm.isLadiesOnClick === true,
                spImgSize: _vm.width < 767,
                spClickedImgSize: _vm.width < 767 && _vm.isMensOnClick,
            },attrs:{"id":"mens-img","src":_vm.img.mens,"alt":""},on:{"click":function($event){_vm.changeImg('mens', _vm.isMensOnClick),
                    _vm.moveClinicList(_vm.isLadiesOnClick)}}})]),_c('article',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLadiesOnClick || _vm.isMensOnClick),expression:"isLadiesOnClick || isMensOnClick"}],staticClass:"accordion-faq",attrs:{"id":"faq"}},_vm._l((_vm.faqTypes),function(type,index){return _c('div',{key:type,staticClass:"accordion-header accordion-area w-100",class:{ open: _vm.show[index] }},[_c('div',{staticClass:"state-name w-100",on:{"click":function($event){return _vm.openArea(index)}}},[_c('span',[_vm._v(_vm._s(type))]),_c('div',{staticClass:"i-box"},[(!_vm.show[index] && _vm.width > 768)?_c('span',{staticClass:"one-i",domProps:{"innerHTML":_vm._s(_vm.svg.pcTitleClose)}}):(!_vm.show[index] && _vm.width < 768)?_c('span',{staticClass:"one-i",domProps:{"innerHTML":_vm._s(_vm.svg.spTitleClose)}}):_vm._e(),(_vm.show[index] && _vm.width > 768)?_c('span',{staticClass:"one-i2",domProps:{"innerHTML":_vm._s(_vm.svg.pcTitleOpen)}}):(_vm.show[index] && _vm.width < 768)?_c('span',{staticClass:"one-i2",domProps:{"innerHTML":_vm._s(_vm.svg.spTitleOpen)}}):_vm._e()])]),_vm._v(" "+_vm._s(_vm.setfaqList(_vm.isLadiesOnClick, _vm.isMensOnClick))+" "),_vm._l((_vm.faqData),function(faq,faqIndex){return _c('div',{key:`id_${faq.id}_${faqIndex}`},[(type === faq.type)?_c('div',[_c('div',{on:{"click":function($event){return _vm.openfaq(faqIndex)}}},[_c('slide-up-down',{staticClass:"faq-name accordion-header",class:_vm.changeBackGroundColor(
                                    _vm.isLadiesOnClick,
                                    _vm.isMensOnClick
                                ),attrs:{"active":_vm.show[index],"duration":100}},[_c('div',{staticClass:"d-block pt-2"},[_c('span',{staticClass:"position-absolute start-0 px-4 question",class:_vm.changeColor(
                                            _vm.isLadiesOnClick,
                                            _vm.isMensOnClick
                                        )},[_vm._v("Q")]),_c('span',{staticClass:"position-absolute start-0 px-5 mx-4 question-content",class:_vm.changeColor(
                                            _vm.isLadiesOnClick,
                                            _vm.isMensOnClick
                                        )},[_vm._v(_vm._s(faq.question))])]),_c('div',{staticClass:"i-box"},[(!_vm.faqShow[faqIndex] && _vm.width > 768)?_c('span',{staticClass:"one-i",domProps:{"innerHTML":_vm._s(_vm.svg.pcClose)}}):(
                                        !_vm.faqShow[faqIndex] && _vm.width < 768
                                    )?_c('span',{staticClass:"one-i",domProps:{"innerHTML":_vm._s(_vm.svg.spClose)}}):_vm._e(),(_vm.faqShow[faqIndex] && _vm.width > 768)?_c('span',{staticClass:"one-i2",domProps:{"innerHTML":_vm._s(_vm.svg.pcOpen)}}):(
                                        _vm.faqShow[faqIndex] && _vm.width < 768
                                    )?_c('span',{staticClass:"one-i2",domProps:{"innerHTML":_vm._s(_vm.svg.spOpen)}}):_vm._e()])])],1),(_vm.show[index] && _vm.faqShow[faqIndex])?_c('div',{staticClass:"faq-name accordion-header",staticStyle:{"padding":"0"}},[_c('transition',{attrs:{"name":"open"}},[_c('div',{staticClass:"accordion-content",staticStyle:{"padding":"0"}},[_c('div',{staticClass:"answer"},[_vm._v("A")]),_c('p',{staticClass:"mt-1 answer-content",staticStyle:{"font-family":"'Noto Sans JP',"},domProps:{"innerHTML":_vm._s(faq.answer)}})])])],1):_vm._e()]):_vm._e()])})],2)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }